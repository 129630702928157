import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/messaging`;

export const addSubscription = (objectId, objectType) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.post(`${URL}/subscriptions/subscribe`, {
    objectId,
    objectType,
  });
};

export const removeSubscription = (objectId, objectType) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.post(`${URL}/subscriptions/unsubscribe`, {
    objectId,
    objectType,
  });
};

export const fetchMessages = async ({ objectId, objectType, read }) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.get(`${URL}/messages`, {
    params: {
      ...objectId && { objectId },
      ...objectType && { objectType },
      read,
      offset: 0,
      limit: 1000,
    },
  });
};

export const addMessage = async ({
  objectId,
  objectType,
  content,
  attachments,
  mentions,
  meta,
}) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.post(`${URL}/messages`, {
    objectId,
    objectType,
    contentType: 'text/plain',
    content,
    meta,
    ...(mentions && mentions.length ? { mentions } : {}),
    ...(attachments && attachments.length ? { attachments } : {}),
  });
};

export const updateMessage = async ({
  id,
  content,
  mentions,
  meta,
}) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.patch(`${URL}/messages/${id}`, {
    contentType: 'text/plain',
    content,
    meta,
    ...(mentions && mentions.length ? { mentions } : {}),
  });
};

export const emitIsTyping = ({ objectId, objectType, typing }) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.post(`${URL}/subscriptions/emit`, {
    objectId,
    objectType,
    eventType: 'TYPING',
    data: {
      typing,
    },
  });
};

export const getAttachmentUrl = (key) => {
  setAuthToken(localStorage.getItem('token'));
  return axios.get(`${URL}/attachments/url?key=${key}`);
};

export const fetchUnreadMentions = () => {
  setAuthToken(localStorage.getItem('token'));

  return axios.get(`${URL}/messages/unread-mentions`);
};

export const markUnread = (objectId, objectType) => {
  setAuthToken(localStorage.getItem('token'));

  return axios.patch(`${URL}/messages/read`, {
    objectId,
    objectType,
  });
};
