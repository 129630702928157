export const ADD_ATTACHMENT_COMPARISON_QUEUE = 'ADD_ATTACHMENT_COMPARISON_QUEUE';
export const REMOVE_ATTACHMENT_COMPARISON_QUEUE = 'REMOVE_ATTACHMENT_COMPARISON_QUEUE';
export const TOGGLE_COMPARISON_MANAGER = 'TOGGLE_COMPARISON_MANAGER';
export const RESET_ATTACHMENT_COMPARISON_QUEUE = 'RESET_ATTACHMENT_COMPARISON_QUEUE';

export const addAttachmentToComparisonQueue = (attachment) => (dispatch) => dispatch({
  type: ADD_ATTACHMENT_COMPARISON_QUEUE,
  payload: attachment,
});

export const removeAttachmentFromComparisonQueue = (id) => (dispatch) => dispatch({
  type: REMOVE_ATTACHMENT_COMPARISON_QUEUE,
  payload: id,
});

export const toggleComparisonManager = (open) => (dispatch) => dispatch({
  type: TOGGLE_COMPARISON_MANAGER,
  payload: open,
});

export const resetAttachmentComparisonQueue = () => (dispatch) => dispatch({
  type: RESET_ATTACHMENT_COMPARISON_QUEUE,
});
