import Loader from '../general/Loader';

const FallbackLoader = () => (
  <div style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <Loader />
  </div>
);

export default FallbackLoader;
