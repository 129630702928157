import {
  GET_DRIVES,
  GET_DRIVES_SUCCESS,
  GET_DRIVES_ERROR,
  GET_DRIVES_TREE,
  GET_DRIVES_TREE_SUCCESS,
  GET_DRIVES_TREE_ERROR,
  GET_DRIVES_BREADCRUMB,
  GET_DRIVES_BREADCRUMB_SUCCESS,
  GET_DRIVES_BREADCRUMB_ERROR,
  DELETE_DRIVE,
  DELETE_DRIVE_FOLDER,
  DELETE_DRIVE_FILE,
  GET_DRIVE_SEARCH_RESULTS,
  GET_DRIVE_SEARCH_RESULTS_SUCCESS,
  GET_DRIVE_SEARCH_RESULTS_ERROR,
  GET_DRIVE_SEARCH_CONTENT_RESULTS,
  GET_DRIVE_SEARCH_CONTENT_RESULTS_SUCCESS,
  GET_DRIVE_SEARCH_CONTENT_RESULTS_ERROR,
  GET_DRIVE_TRASH,
  GET_DRIVE_TRASH_SUCCESS,
  GET_DRIVE_TRASH_ERROR,
  EMPTY_DRIVE_TRASH_SUCCESS,
  EMPTY_DRIVE_TRASH,
  EMPTY_DRIVE_TRASH_ERROR,
  SET_SELECTED_DRIVE,
} from '../actions/driveActions';

const initialState = {
  drives: [],
  driveError: null,
  selectedDrive: null,
  driveTree: {
    isFetching: true,
    data: [],
    total: 0,
    error: null,
  },
  wopiFileInfo: {
    isFetching: false,
    data: null,
    error: null,
  },
  search: {
    isFetching: false,
    results: [],
    total: 0,
    error: null,
  },
  searchContent: {
    isFetching: false,
    results: [],
    total: 0,
    error: null,
  },
  trash: {
    isFetching: false,
    results: [],
    total: 0,
    error: null,
  },
  wopiFileContent: {
    isFetching: false,
    data: null,
    error: null,
  },
  directoryTree: {
    isFetching: true,
    data: null,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DRIVES: {
      return {
        ...state,
        driveError: null,
      };
    }
    case GET_DRIVES_SUCCESS: {
      return {
        ...state,
        drives: action.payload,
        driveError: null,
        driveTree: {
          ...state.driveTree,
          data: action.payload.length ? state.driveTree.data : [],
          isFetching: false,
        },
      };
    }
    case GET_DRIVES_ERROR: {
      return {
        ...state,
        drives: [],
        driveError: action.payload,
      };
    }
    case SET_SELECTED_DRIVE: {
      return {
        ...state,
        selectedDrive: action.payload,
      };
    }
    case GET_DRIVES_BREADCRUMB: {
      return {
        ...state,
        directoryTree: {
          ...state.directoryTree,
          isFetching: true,
        },
      };
    }
    case GET_DRIVES_BREADCRUMB_SUCCESS: {
      return {
        ...state,
        directoryTree: {
          ...state.directoryTree,
          isFetching: false,
          data: action.payload,
        },
      };
    }
    case GET_DRIVES_BREADCRUMB_ERROR: {
      return {
        ...state,
        directoryTree: {
          ...state.directoryTree,
          isFetching: false,
          data: null,
          error: action.payload,
        },
      };
    }
    case GET_DRIVE_SEARCH_RESULTS: {
      return {
        ...state,
        search: {
          ...state.search,
          isFetching: true,
          error: null,
          total: 0,
        },
      };
    }
    case GET_DRIVE_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        search: {
          isFetching: false,
          error: null,
          results: action.payload.items,
          total: action.payload.cursor.total,
        },
      };
    }
    case GET_DRIVE_SEARCH_RESULTS_ERROR: {
      return {
        ...state,
        search: {
          results: [],
          isFetching: false,
          total: 0,
          error: action.payload,
        },
      };
    }
    case GET_DRIVE_SEARCH_CONTENT_RESULTS: {
      return {
        ...state,
        searchContent: {
          ...state.searchContent,
          isFetching: true,
          error: null,
          total: 0,
        },
      };
    }
    case GET_DRIVE_SEARCH_CONTENT_RESULTS_SUCCESS: {
      return {
        ...state,
        searchContent: {
          ...state.searchContent,
          isFetching: false,
          error: null,
          results: action.payload.items,
          total: action.payload.count,
        },
      };
    }
    case GET_DRIVE_SEARCH_CONTENT_RESULTS_ERROR: {
      return {
        ...state,
        searchContent: {
          ...state.searchContent,
          isFetching: false,
          error: action.payload,
          results: [],
          total: 0,
        },
      };
    }
    case GET_DRIVE_TRASH:
    case EMPTY_DRIVE_TRASH: {
      return {
        ...state,
        trash: {
          ...state.trash,
          isFetching: true,
          error: null,
          total: 0,
        },
      };
    }
    case EMPTY_DRIVE_TRASH_SUCCESS: {
      return {
        ...state,
        trash: {
          results: [],
          isFetching: false,
          error: null,
          total: 0,
        },
      };
    }
    case GET_DRIVE_TRASH_SUCCESS: {
      return {
        ...state,
        trash: {
          isFetching: false,
          error: null,
          results: action.payload.items,
          total: action.payload.cursor.total,
        },
      };
    }
    case EMPTY_DRIVE_TRASH_ERROR:
    case GET_DRIVE_TRASH_ERROR: {
      return {
        ...state,
        trash: {
          results: [],
          isFetching: false,
          total: 0,
          error: action.payload,
        },
      };
    }
    case GET_DRIVES_TREE: {
      return {
        ...state,
        driveTree: {
          ...state.driveTree,
          isFetching: true,
          data: null,
          total: 0,
          error: null,
        },
      };
    }
    case GET_DRIVES_TREE_SUCCESS: {
      return {
        ...state,
        driveTree: {
          ...state.driveTree,
          isFetching: false,
          data: action.payload.items,
          total: action.payload.cursor.total,
          error: null,
        },
      };
    }
    case GET_DRIVES_TREE_ERROR: {
      return {
        ...state,
        driveTree: {
          ...state.driveTree,
          isFetching: false,
          data: null,
          total: 0,
          error: action.payload,
        },
      };
    }
    case DELETE_DRIVE: {
      return {
        ...state,
        drives: state.drives.filter((drive) => drive._id !== action.payload),
      };
    }
    case DELETE_DRIVE_FILE:
    case DELETE_DRIVE_FOLDER: {
      return {
        ...state,
        driveTree: {
          ...state.driveTree,
          data: state.driveTree.data.filter(
            (obj) => obj._id !== action.payload,
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};
