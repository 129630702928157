import {
  GET_RATES_LIBRARY,
  GET_RATES_LIBRARY_ERROR,
  GET_RATES_LIBRARY_SUCCESS,
  GET_LIBRARY_RATES,
  GET_LIBRARY_RATES_SUCCESS,
  GET_LIBRARY_RATES_ERROR,
  HIDE_RATE,
  UNHIDE_RATE,
} from '../actions/ratesLibraryActions';
import {
  ADD_LIBRARY_RATE,
  EDIT_LIBRARY_RATE,
  ADD_LIBRARY_RATE_ATTACHMENT,
  DELETE_LIBRARY_RATE_ATTACHMENT,
  ADD_LIBRARY_RATE_CUSTOM_FIELD,
  EDIT_LIBRARY_RATE_CUSTOM_FIELD,
  DELETE_LIBRARY_RATE_CUSTOM_FIELD,
  EDIT_LIBRARY_RATE_STATUS,
  ADD_LIBRARY_RATE_STATUS,
  CLONE_LIBRARY_RATE_STATUS,
} from '../actions/libraryRateActions';

const initialValue = {
  items: [],
  count: 0,
  isFetching: false,
  error: null,
};
export default function (state = initialValue, action) {
  switch (action.type) {
    case GET_LIBRARY_RATES: {
      return {
        count: 0,
        items: [],
        isFetching: true,
        error: null,
      };
    }
    case GET_LIBRARY_RATES_SUCCESS: {
      const { items, count } = action.payload;
      return {
        ...state,
        items,
        count,
        isFetching: false,
        error: null,
      };
    }
    case GET_LIBRARY_RATES_ERROR: {
      return {
        ...state,
        count: 0,
        items: [],
        isFetching: false,
        error: action.payload,
      };
    }
    case GET_RATES_LIBRARY: {
      return {
        items: [],
        count: 0,
        isFetching: true,
        error: null,
      };
    }
    case ADD_LIBRARY_RATE: {
      return {
        items: [action.payload, ...state.items],
        count: state.count + 1,
        isFetching: false,
        error: null,
      };
    }
    case EDIT_LIBRARY_RATE: {
      const rate = action.payload;
      return {
        items: state.items.map(item => {
          if (item._id === rate._id) {
            return rate;
          }
          return item;
        }),
        count: state.count + 1,
        isFetching: false,
        error: null,
      };
    }
    case HIDE_RATE: {
      const items = state.items.map((rate) => {
        if (rate.ruleNumber === action.payload) {
          return {
            ...rate,
            hidden: true,
          };
        }
        return rate;
      });
      return {
        ...state,
        items,
      };
    }
    case UNHIDE_RATE: {
      const items = state.items.map((rate) => {
        if (rate.ruleNumber === action.payload) {
          return {
            ...rate,
            hidden: false,
          };
        }
        return rate;
      });
      return {
        ...state,
        items,
      };
    }
    case GET_RATES_LIBRARY_SUCCESS: {
      const { items, count } = action.payload;
      return {
        items,
        count,
        isFetching: false,
        error: null,
      };
    }
    case GET_RATES_LIBRARY_ERROR: {
      return {
        items: [],
        count: 0,
        isFetching: false,
        error: action.payload,
      };
    }
    case ADD_LIBRARY_RATE_ATTACHMENT: {
      if (!state.items.length) {
        return state;
      }

      const { rateId, attachment } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              attachments: [...item.attachments, attachment],
            };
          }
          return item;
        }),
      };
    }
    case DELETE_LIBRARY_RATE_ATTACHMENT: {
      if (!state.items.length) {
        return state;
      }

      const { rateId, attachmentId } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              attachments: item.attachments
                .filter(attachment => attachment._id !== attachmentId),
            };
          }
          return item;
        }),
      };
    }
    case ADD_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        rateId,
        rateStatusId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              statuses: item.statuses.map(rateStatus => {
                if (rateStatus._id === rateStatusId) {
                  return {
                    ...rateStatus,
                    customFields: [...rateStatus.customFields, data],
                  };
                }
                return rateStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case EDIT_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        rateId,
        rateStatusId,
        customFieldRecordId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              statuses: item.statuses.map(rateStatus => {
                if (rateStatus._id === rateStatusId) {
                  return {
                    ...rateStatus,
                    customFields: rateStatus
                      .customFields
                      .map(customField => {
                        if (customField._id === customFieldRecordId) {
                          return data;
                        }
                        return customField;
                      }),
                  };
                }
                return rateStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case DELETE_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        rateId,
        rateStatusId,
        customFieldRecordId,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              statuses: item.statuses.map(rateStatus => {
                if (rateStatus._id === rateStatusId) {
                  return {
                    ...rateStatus,
                    customFields: rateStatus
                      .customFields
                      .filter(customField => customField._id !== customFieldRecordId),
                  };
                }
                return rateStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case ADD_LIBRARY_RATE_STATUS: {
      if (!state.items.length) {
        return state;
      }

      const { rateId, data } = action.payload;
      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              statuses: [...item.statuses, data],
            };
          }
          return item;
        }),
      };
    }
    case CLONE_LIBRARY_RATE_STATUS: {
      if (!state.items.length) {
        return state;
      }

      const { rateId, data } = action.payload;
      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              statuses: [...item.statuses, data],
            };
          }
          return item;
        }),
      };
    }
    case EDIT_LIBRARY_RATE_STATUS: {
      if (!state.items.length) {
        return state;
      }

      const {
        rateId,
        rateStatusId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === rateId) {
            return {
              ...item,
              statuses: item.statuses.map(rateStatus => {
                if (rateStatus._id === rateStatusId) {
                  return data;
                }
                return rateStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
}
