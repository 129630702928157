import {
  LOAD_OPEN_OBJECTIONS,
  LOAD_OPEN_OBJECTIONS_SUCCESS,
  LOAD_OPEN_OBJECTIONS_ERROR,
  LOAD_CLOSED_OBJECTIONS,
  LOAD_CLOSED_OBJECTIONS_SUCCESS,
  LOAD_CLOSED_OBJECTIONS_ERROR,
} from '../actions/workflowActions';
import {
  processClosedObjections,
  processOpenObjections,
} from '../pages/Objections/ObjectionSerivce';

const initialState = {
  isLoadingOpenObjections: false,
  isLoadingClosedObjections: false,
  openObjectionsFilter: false,
  openObjections: {
    count: 0,
    items: [],
  },
  closedObjections: {
    count: 0,
    items: [],
  },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_OPEN_OBJECTIONS:
      return {
        ...state,
        openObjections: {
          ...state.openObjections,
          items: [],
        },
        isLoadingOpenObjections: true,
      };
    case LOAD_OPEN_OBJECTIONS_ERROR:
      return {
        ...state,
        isLoadingOpenObjections: false,
        openObjections: {
          count: 0,
          items: [],
        },
      };
    case LOAD_OPEN_OBJECTIONS_SUCCESS: {
      const { items, count } = action.payload;
      const openObjections = processOpenObjections(items);
      return {
        ...state,
        isLoadingOpenObjections: false,
        openObjections: {
          items: openObjections,
          count,
        },
      };
    }
    case LOAD_CLOSED_OBJECTIONS:
      return {
        ...state,
        closedObjections: {
          ...state.closedObjections,
          items: [],
        },
        isLoadingClosedObjections: true,
      };
    case LOAD_CLOSED_OBJECTIONS_ERROR:
      return {
        ...state,
        isLoadingClosedObjections: false,
        closedObjections: {
          count: 0,
          items: [],
        },
      };
    case LOAD_CLOSED_OBJECTIONS_SUCCESS: {
      const { items, count } = action.payload;
      const closedObjections = processClosedObjections(items);
      return {
        ...state,
        isLoadingClosedObjections: false,
        closedObjections: {
          items: closedObjections,
          count,
        },
      };
    }
    default:
      return { ...state };
  }
}
