import { uniqBy } from 'lodash';
import {
  GET_STATE_SPECIFICS_RULES,
  GET_STATE_SPECIFICS_RULES_SUCCESS,
  GET_STATE_SPECIFICS_RULES_ERROR,
  GET_SUPPORTINGS_RULES,
  GET_SUPPORTINGS_RULES_SUCCESS,
  GET_SUPPORTINGS_RULES_ERROR,
  GET_STATES_RULES,
  GET_STATES_RULES_SUCCESS,
  GET_STATES_RULES_ERROR,
  GET_TYPE_OF_INSURANCES_RULES,
  GET_TYPE_OF_INSURANCES_RULES_SUCCESS,
  GET_TYPE_OF_INSURANCES_RULES_ERROR,
  GET_SUB_TYPE_OF_INSURANCES_RULES,
  GET_SUB_TYPE_OF_INSURANCES_RULES_SUCCESS,
  GET_SUB_TYPE_OF_INSURANCES_RULES_ERROR,
  GET_FILING_TYPES_RULES,
  GET_FILING_TYPES_RULES_SUCCESS,
  GET_FILING_TYPES_RULES_ERROR,
  INVALIDATE_RULES,
  GET_TYPE_OF_INSURANCES_RULES_DISTINCT,
  GET_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS,
  GET_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR,
  GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT,
  GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS,
  GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR,
  GET_FILING_TYPES_RULES_DISTINCT,
  GET_FILING_TYPES_RULES_DISTINCT_SUCCESS,
  GET_FILING_TYPES_RULES_DISTINCT_ERROR,
} from '../actions/filingDraftRulesActions';

const initialState = {
  stateSpecifics: {
    isFetching: false,
    data: [],
    error: null,
  },
  supportings: {
    isFetching: false,
    data: [],
    error: null,
  },
  states: {
    isFetching: false,
    data: [],
    error: null,
  },
  typeOfInsurances: {
    isFetching: false,
    data: {},
    error: null,
  },
  subTypeOfInsurances: {
    isFetching: false,
    data: {},
    error: null,
  },
  filingTypes: {
    isFetching: false,
    data: {},
    error: null,
  },
  typeOfInsurancesDistinct: {
    isFetching: false,
    data: [],
    error: null,
  },
  subTypeOfInsurancesDistinct: {
    isFetching: false,
    data: [],
    error: null,
  },
  filingTypesDistinct: {
    isFetching: false,
    data: [],
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPORTINGS_RULES: {
      return {
        ...state,
        supportings: {
          isFetching: true,
          data: [...state.supportings.data],
          error: null,
        },
      };
    }
    case GET_SUPPORTINGS_RULES_SUCCESS: {
      const data = uniqBy([
        ...state.supportings.data,
        ...action.payload || [],
      ], (e) => e.filingTypeId + e.subTypeOfInsuranceId);

      return {
        ...state,
        supportings: {
          isFetching: false,
          data,
          error: null,
        },
      };
    }
    case GET_SUPPORTINGS_RULES_ERROR: {
      return {
        ...state,
        supportings: {
          isFetching: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_STATE_SPECIFICS_RULES: {
      return {
        ...state,
        stateSpecifics: {
          isFetching: true,
          data: [...state.stateSpecifics.data],
          error: null,
        },
      };
    }
    case GET_STATE_SPECIFICS_RULES_SUCCESS: {
      const data = uniqBy([
        ...state.stateSpecifics.data,
        ...action.payload || [],
      ], (e) => e.filingTypeId + e.subTypeOfInsuranceId);

      return {
        ...state,
        stateSpecifics: {
          isFetching: false,
          data,
          error: null,
        },
      };
    }
    case GET_STATE_SPECIFICS_RULES_ERROR: {
      return {
        ...state,
        stateSpecifics: {
          isFetching: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_STATES_RULES: {
      return {
        ...state,
        states: {
          isFetching: true,
          data: [],
          error: null,
        },
      };
    }
    case GET_STATES_RULES_SUCCESS: {
      return {
        ...state,
        states: {
          isFetching: false,
          data: action.payload,
          error: null,
        },
      };
    }
    case GET_STATES_RULES_ERROR: {
      return {
        ...state,
        states: {
          isFetching: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_TYPE_OF_INSURANCES_RULES: {
      return {
        ...state,
        typeOfInsurances: {
          isFetching: true,
          error: null,
          data: {
            ...state.typeOfInsurances.data,
          },
        },
      };
    }
    case GET_TYPE_OF_INSURANCES_RULES_SUCCESS: {
      const { state: code, data } = action.payload;
      return {
        ...state,
        typeOfInsurances: {
          isFetching: false,
          data: {
            ...state.typeOfInsurances.data,
            [code]: data,
          },
          error: null,
        },
      };
    }
    case GET_TYPE_OF_INSURANCES_RULES_ERROR: {
      return {
        ...state,
        typeOfInsurances: {
          isFetching: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_SUB_TYPE_OF_INSURANCES_RULES: {
      return {
        ...state,
        subTypeOfInsurances: {
          isFetching: true,
          error: null,
          data: {
            ...state.subTypeOfInsurances.data,
          },
        },
      };
    }
    case GET_SUB_TYPE_OF_INSURANCES_RULES_SUCCESS: {
      const { typeOfInsurance, data } = action.payload;
      return {
        ...state,
        subTypeOfInsurances: {
          isFetching: false,
          data: {
            ...state.subTypeOfInsurances.data,
            [typeOfInsurance]: data,
          },
          error: null,
        },
      };
    }
    case GET_SUB_TYPE_OF_INSURANCES_RULES_ERROR: {
      return {
        ...state,
        subTypeOfInsurances: {
          isFetching: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_FILING_TYPES_RULES: {
      return {
        ...state,
        filingTypes: {
          isFetching: true,
          error: null,
          data: {
            ...state.filingTypes.data,
          },
        },
      };
    }
    case GET_FILING_TYPES_RULES_SUCCESS: {
      const { subTypeOfInsurance, data } = action.payload;
      return {
        ...state,
        filingTypes: {
          isFetching: false,
          data: {
            ...state.filingTypes.data,
            [subTypeOfInsurance]: data,
          },
          error: null,
        },
      };
    }
    case GET_FILING_TYPES_RULES_ERROR: {
      return {
        ...state,
        filingTypes: {
          isFetching: false,
          data: {},
          error: action.payload,
        },
      };
    }
    case GET_TYPE_OF_INSURANCES_RULES_DISTINCT: {
      return {
        ...state,
        typeOfInsurancesDistinct: {
          isFetching: true,
          data: [],
          error: null,
        },
      };
    }
    case GET_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS: {
      return {
        ...state,
        typeOfInsurancesDistinct: {
          isFetching: false,
          data: action.payload,
          error: null,
        },
      };
    }
    case GET_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR: {
      return {
        ...state,
        typeOfInsurancesDistinct: {
          isFetching: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT: {
      return {
        ...state,
        subTypeOfInsurancesDistinct: {
          isFetching: true,
          data: [],
          error: null,
        },
      };
    }
    case GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS: {
      return {
        ...state,
        subTypeOfInsurancesDistinct: {
          isFetching: false,
          data: action.payload,
          error: null,
        },
      };
    }
    case GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR: {
      return {
        ...state,
        subTypeOfInsurancesDistinct: {
          isFetching: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case GET_FILING_TYPES_RULES_DISTINCT: {
      return {
        ...state,
        filingTypesDistinct: {
          isFetching: true,
          data: [],
          error: null,
        },
      };
    }
    case GET_FILING_TYPES_RULES_DISTINCT_SUCCESS: {
      return {
        ...state,
        filingTypesDistinct: {
          isFetching: false,
          data: action.payload,
          error: null,
        },
      };
    }
    case GET_FILING_TYPES_RULES_DISTINCT_ERROR: {
      return {
        ...state,
        filingTypesDistinct: {
          isFetching: false,
          data: [],
          error: action.payload,
        },
      };
    }
    case INVALIDATE_RULES: {
      return initialState;
    }
    default:
      return { ...state };
  }
};
