import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/ai/chats`;

export const TOGGLE_AI_ASSISTANT = 'TOGGLE_AI_ASSISTANT';
export const GET_OBJECTION_AI_CHAT = 'GET_OBJECTION_AI_CHAT';
export const GET_OBJECTION_AI_CHAT_SUCCESS = 'GET_OBJECTION_AI_CHAT_SUCCESS';
export const GET_OBJECTION_AI_CHAT_ERROR = 'GET_OBJECTION_AI_CHAT_ERROR';

export const ADD_OBJECTION_AI_CHAT = 'ADD_OBJECTION_AI_CHAT';
export const ADD_OBJECTION_AI_CHAT_SUCCESS = 'ADD_OBJECTION_AI_CHAT_SUCCESS';
export const ADD_OBJECTION_AI_CHAT_ERROR = 'ADD_OBJECTION_AI_CHAT_ERROR';

export const POST_OBJECTION_AI_CHAT_MESSAGE = 'POST_OBJECTION_AI_CHAT_MESSAGE';
export const POST_OBJECTION_AI_CHAT_MESSAGE_SUCCESS = 'POST_OBJECTION_AI_CHAT_MESSAGE_SUCCESS';
export const POST_OBJECTION_AI_CHAT_MESSAGE_ERROR = 'POST_OBJECTION_AI_CHAT_MESSAGE_ERROR';

export const toggleAiAssistant = (payload) => (dispatch) => {
  dispatch({
    type: TOGGLE_AI_ASSISTANT,
    payload,
  });
};

export const getObjectionAiChat = ({
  objectionLetterId,
  objectionId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_OBJECTION_AI_CHAT,
      });
      const res = await axios.get(`${URL}/response-drafts/${objectionLetterId}/objections/${objectionId}`);
      dispatch({
        type: GET_OBJECTION_AI_CHAT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_OBJECTION_AI_CHAT_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
}

export const createObjectionAiChat = ({
  objectionLetterId,
  objectionId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: ADD_OBJECTION_AI_CHAT,
      });
      const res = await axios.post(`${URL}/response-drafts/${objectionLetterId}/objections/${objectionId}`, data);
      dispatch({
        type: ADD_OBJECTION_AI_CHAT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: ADD_OBJECTION_AI_CHAT_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
}

export const deleteObjectionAiChat = ({
  objectionLetterId,
  objectionId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${URL}/response-drafts/${objectionLetterId}/objections/${objectionId}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const postObjectionAiChatMessage = ({
  objectionLetterId,
  objectionId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: POST_OBJECTION_AI_CHAT_MESSAGE,
      });
      const res = await axios.post(`${URL}/response-drafts/${objectionLetterId}/objections/${objectionId}/message`, data);
      dispatch({
        type: POST_OBJECTION_AI_CHAT_MESSAGE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: POST_OBJECTION_AI_CHAT_MESSAGE_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
}
