import moment from 'moment';
import { renderStateName } from '../../services/StateService';

const processOpenObjections = (
  objections,
) => (objections.reduce((acc, num) => ([
  ...acc,
  {
    filingId: num.id,
    deadline: num.objection && num.objection.respondByDate,
    letterDate: num.objection && num.objection.letterDate,
    filingType: num.filingType,
    objectionId: num.objection && num.objection.id,
    objectionsCount: (num.objection && num.objection.objectionsCount) || 1,
    details: {
      stateCode: num.state,
      state: renderStateName(num.state),
      trackingNumber: num.customTrackingNumber || num.trackingNumber,
      project: num.project,
      product: num.product,
    },
    isHidden: (num.meta && num.meta.objections
      && num.objection && num.objection.id && num.meta.objections[num.objection.id]
      && num.meta.objections[num.objection.id].isHidden) || false,
    assignedResponders: (num.meta && num.meta.objections
      && num.meta.objections[num.objection.id]
      && num.meta.objections[num.objection.id].responders) || {},
  },
]), []));

const processClosedObjections = (objections) => (objections.reduce((acc, num) => ([
  ...acc,
  {
    filingId: num.id,
    deadline: num.objection && num.objection.respondByDate,
    letterDate: num.objection && moment.utc(num.objection.letterDate).format('LL'),
    filingType: num.filingType,
    objectionId: num.objection && num.objection.id,
    objectionsCount: (num.objection && num.objection.objectionsCount) || 1,
    details: {
      stateCode: num.state,
      state: renderStateName(num.state),
      trackingNumber: num.customTrackingNumber || num.trackingNumber,
      project: num.project,
      product: num.product,
    },
  },
]), []));

export {
  processOpenObjections,
  processClosedObjections,
};
