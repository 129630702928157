import {
  GET_STATE_DETAILS,
  GET_STATE_DETAILS_SUCCESS,
  GET_STATE_DETAILS_ERROR,
  EDIT_FILING,
  DELETE_FILING,
  DELETE_FORM_SCHEDULE_ITEM,
  TOGGLE_ARCHIVE_FILING,
  DELETE_FILING_ATTACHMENT,
  EDIT_FILING_EFFECTIVE_DATE_OVERRIDE,
  EDIT_FILING_ATTACHMENT_OVERRIDE,
  DELETE_RATE_SCHEDULE_ITEM,
  ADD_FORM_SCHEDULE_ITEM,
  EDIT_FORM_SCHEDULE_ITEM,
  ADD_RATE_SCHEDULE_ITEM,
  EDIT_RATE_SCHEDULE_ITEM,
  ADD_FORM_SCHEDULE_ITEM_ATTACHMENT,
  ADD_RATE_SCHEDULE_ITEM_ATTACHMENT,
  EDIT_STATE_GENERAL_INFORMATION,
  EDIT_STATE_FEES,
  EDIT_STATE_RATE_DATA,
  INVALIDATE_STATE_DETAILS,
  EDIT_FILING_RESPOND_BY_DATE_OVERRIDE,
  UPSERT_STATE_CUSTOM_FIELD,
  DELETE_STATE_CUSTOM_FIELD,
} from '../actions/stateDetailsActions';

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_STATE_DETAILS: {
      return {
        isFetching: true,
        data: null,
        error: null,
      };
    }
    case UPSERT_STATE_CUSTOM_FIELD: {
      const customField = action.payload;
      const customFields = (state.data && state.data.stateCustomFields) || [];

      const list = customFields.find(field => field._id === customField._id)
        ? customFields.map(field => (field._id === customField._id ? customField : field))
        : [...customFields, action.payload];

      return {
        ...state,
        data: {
          ...state.data,
          stateCustomFields: list,
        },
      };
    }
    case DELETE_STATE_CUSTOM_FIELD: {
      const customField = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          stateCustomFields: state.data.stateCustomFields
            .filter(field => customField.customFieldValueId !== field._id),
        },
      };
    }
    case GET_STATE_DETAILS_SUCCESS: {
      const { stateDetails } = action.payload;

      // let product = null;
      // const projectId = details.id;

      // products.forEach(p => {
      //   if (p.projects.find(project => project.id === projectId)) {
      //     product = p;
      //   }
      // });

      const data = {
        ...stateDetails,
        // ...(product && {
        //   productInitials: product.initials,
        //   productColor: product.color,
        // }),
      };

      return {
        isFetching: false,
        data,
        error: null,
      };
    }
    case GET_STATE_DETAILS_ERROR: {
      return {
        isFetching: false,
        data: null,
        error: action.payload,
      };
    }
    case EDIT_FILING: {
      if (!state.data) {
        return state;
      }

      const editedFiling = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === editedFiling.id) {
              return editedFiling;
            }
            return filing;
          }),
        },
      };
    }
    case DELETE_FILING: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings
            .filter(filing => filing.id !== action.payload),
        },
      };
    }
    case ADD_RATE_SCHEDULE_ITEM_ATTACHMENT: {
      const { filingId, rateId, attachment } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                rates: filing.rates.map(rate => {
                  if (rate.id === rateId) {
                    return {
                      ...rate,
                      attachments: [...rate.attachments, attachment],
                    };
                  }
                  return rate;
                }),
              };
            }
            return filing;
          }),
        },
      };
    }
    case ADD_FORM_SCHEDULE_ITEM_ATTACHMENT: {
      const { filingId, formId, attachment } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                forms: filing.forms.map(form => {
                  if (form.id === formId) {
                    return {
                      ...form,
                      attachments: [...form.attachments, attachment],
                    };
                  }
                  return form;
                }),
              };
            }
            return filing;
          }),
        },
      };
    }
    case DELETE_RATE_SCHEDULE_ITEM: {
      const { filingId, rateId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                rates: filing.rates.filter(rate => rate.id !== rateId),
              };
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_FORM_SCHEDULE_ITEM: {
      const { filingId, form } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                forms: filing.forms.map(f => {
                  if (form && form.id === f.id) {
                    return form;
                  }
                  return f;
                }),
              };
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_RATE_SCHEDULE_ITEM: {
      const { filingId, rate } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                rates: filing.rates.map(f => {
                  if (rate && rate.id === f.id) {
                    return rate;
                  }
                  return f;
                }),
              };
            }
            return filing;
          }),
        },
      };
    }
    case ADD_FORM_SCHEDULE_ITEM: {
      const { filingId, form } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                forms: [...filing.forms, form],
              };
            }
            return filing;
          }),
        },
      };
    }
    case ADD_RATE_SCHEDULE_ITEM: {
      const { filingId, rate } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                rates: [...filing.rates, rate],
              };
            }
            return filing;
          }),
        },
      };
    }
    case DELETE_FORM_SCHEDULE_ITEM: {
      const { filingId, formId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                forms: filing.forms.filter(form => form.id !== formId),
              };
            }
            return filing;
          }),
        },
      };
    }
    case DELETE_FILING_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === action.payload) {
              if (filing.overrides) {
                delete filing.overrides.attachment;
                return { ...filing };
              }
              return filing;
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_FILING_ATTACHMENT_OVERRIDE: {
      const { attachment, filingId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId && filing.overrides) {
              filing.overrides.attachment = attachment;
              return filing;
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_FILING_EFFECTIVE_DATE_OVERRIDE: {
      const { effectiveDate, filingId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId && filing.overrides) {
              filing.overrides.effectiveDate = effectiveDate;
              return filing;
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_FILING_RESPOND_BY_DATE_OVERRIDE: {
      const { filingId, overrides } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                overrides,
              };
            }
            return filing;
          }),
        },
      };
    }
    case TOGGLE_ARCHIVE_FILING: {
      const { filingId, archived } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              if (filing.overrides) {
                filing.overrides.hidden = archived;
              }
              return filing;
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_STATE_GENERAL_INFORMATION: {
      const { filingId, data } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                generalInformation: data,
              };
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_STATE_FEES: {
      const { filingId, data } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                fee: data,
              };
            }
            return filing;
          }),
        },
      };
    }
    case EDIT_STATE_RATE_DATA: {
      const { filingId, data } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          filings: state.data.filings.map(filing => {
            if (filing.id === filingId) {
              return {
                ...filing,
                rateData: data,
              };
            }
            return filing;
          }),
        },
      };
    }
    case INVALIDATE_STATE_DETAILS: {
      return initialState;
    }
    default:
      return { ...state };
  }
}
