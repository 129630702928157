import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';

export const getAccounts = ({ archived = false }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_ACCOUNTS });
    try {
      const res = await axios.get(`${URL}/accounts`, {
        params: {
          limit: 1000,
          archived,
          sortDirection: 1,
          sortKey: 'name',
        }
      });
      dispatch({
        type: GET_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_ACCOUNTS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const archiveAccount = (id, archived = true) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/accounts/${id}`, { archived });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const createAccount = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/accounts`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
