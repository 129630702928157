import axios from 'axios';
import { setAuthToken, URL } from './index';

//Number of filings submitted
export const GET_NUMBER_OF_FILINGS_SUBMITTED = 'GET_NUMBER_OF_FILINGS_SUBMITTED';
export const GET_NUMBER_OF_FILINGS_SUCCESS = 'GET_NUMBER_OF_FILINGS_SUCCESS';
export const GET_NUMBER_OF_FILINGS_ERROR = 'GET_NUMBER_OF_FILINGS_ERROR';
//Forms
export const GET_FORMS = 'GET_FORMS';
export const GET_FORMS_SUCCESS = 'GET_FORMS_SUCCESS';
export const GET_FORMS_ERROR = 'GET_FORMS_ERROR';

//Rates
export const GET_RATES = 'GET_RATES';
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS';
export const GET_RATES_ERROR = 'GET_RATES_ERROR';

//Number of Approvals
export const GET_NUMBER_OF_APPROVALS = 'GET_NUMBER_OF_APPROVALS';
export const GET_NUMBER_OF_APPROVALS_SUCCESS = 'GET_NUMBER_OF_APPROVALS_SUCCESS';
export const GET_NUMBER_OF_APPROVALS_ERROR = 'GET_NUMBER_OF_APPROVALS_ERROR';

//Number of Rejections
export const GET_NUMBER_OF_REJECTIONS = 'GET_NUMBER_OF_REJECTIONS';
export const GET_NUMBER_OF_REJECTIONS_SUCCESS = 'GET_NUMBER_OF_REJECTIONS_SUCCESS';
export const GET_NUMBER_OF_REJECTIONS_ERROR = 'GET_NUMBER_OF_REJECTIONS_ERROR';

//Number of Withdrawals
export const GET_NUMBER_OF_WITHDRAWALS = 'GET_NUMBER_OF_WITHDRAWALS';
export const GET_NUMBER_OF_WITHDRAWALS_SUCCESS = 'GET_NUMBER_OF_WITHDRAWALS_SUCCESS';
export const GET_NUMBER_OF_WITHDRAWALS_ERROR = 'GET_NUMBER_OF_WITHDRAWALS_ERROR';

//Fees
export const GET_FEES = 'GET_FEES';
export const GET_FEES_SUCCESS = 'GET_FEES_SUCCESS';
export const GET_FEES_ERROR = 'GET_FEES_ERROR';

//Average response times
export const GET_AVERAGE_RESPONSE_TIMES = 'GET_AVERAGE_RESPONSE_TIMES';
export const GET_AVERAGE_RESPONSE_TIMES_SUCCESS = 'GET_AVERAGE_RESPONSE_TIMES_SUCCESS';
export const GET_AVERAGE_RESPONSE_TIMES_ERROR = 'GET_AVERAGE_RESPONSE_TIMES_ERROR';

//Number of Letters and number of Objections
export const GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS = 'GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS';
export const GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_SUCCESS = 'GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_SUCCESS';
export const GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_ERROR = 'GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_ERROR';

//Get monthly average submission to approval time
export const GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME = 'GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME';
export const GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_SUCCESS = 'GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_SUCCESS';
export const GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_ERROR = 'GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_ERROR';

///monthly-average-objection-to-response
export const GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE = 'GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE';
export const GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_SUCCESS = 'GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_SUCCESS';
export const GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_ERROR = 'GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_ERROR';

//monthly-average-open-time
export const GET_MONTHLY_AVERAGE_OPEN_TIME = 'GET_MONTHLY_AVERAGE_OPEN_TIME';
export const GET_MONTHLY_AVERAGE_OPEN_TIME_SUCCESS = 'GET_MONTHLY_AVERAGE_OPEN_TIME_SUCCESS';
export const GET_MONTHLY_AVERAGE_OPEN_TIME_ERROR = 'GET_MONTHLY_AVERAGE_OPEN_TIME_ERROR';

//clear cache chart
export const CLEAR_CACHE_CHART = 'CLEAR_CACHE_CHART';
export const CLEAR_CACHE_CHART_SUCCESS = 'CLEAR_CACHE_CHART_SUCCESS';
export const CLEAR_CACHE_CHART_ERROR = 'CLEAR_CACHE_CHART_ERROR';

//clear all chart cache
export const CLEAR_ALL_CHART_CACHE = 'CLEAR_ALL_CHART_CACHE';
export const CLEAR_ALL_CHART_CACHE_SUCCESS = 'CLEAR_ALL_CHART_CACHE_SUCCESS';
export const CLEAR_ALL_CHART_CACHE_ERROR = 'CLEAR_ALL_CHART_CACHE_ERROR';

export const getNumberOfFilingsSubmitted = (body) => {
  setAuthToken(localStorage.getItem('token'));
  return async (dispatch) => {
    dispatch({ type: GET_NUMBER_OF_FILINGS_SUBMITTED });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-submissions`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_NUMBER_OF_FILINGS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_NUMBER_OF_FILINGS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getForms = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_FORMS });
    try {
      const res = await axios.post(`${URL}/analytics/filings/total-forms-per-action`, body.length > 0 ? { filters: body } : {});
      dispatch({
        type: GET_FORMS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_FORMS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getRates = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_RATES });
    try {
      const res = await axios.post(`${URL}/analytics/filings/total-rates-per-action`, body.length > 0 ? { filters: body } : {});
      dispatch({
        type: GET_RATES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_RATES_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getNumberOfApprovals = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_NUMBER_OF_APPROVALS });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-approvals`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_NUMBER_OF_APPROVALS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_NUMBER_OF_APPROVALS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getNumberOfRejections = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_NUMBER_OF_REJECTIONS });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-rejections`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_NUMBER_OF_REJECTIONS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_NUMBER_OF_REJECTIONS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getNumberOfWithdrawals = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_NUMBER_OF_WITHDRAWALS });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-withdrawals`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_NUMBER_OF_WITHDRAWALS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_NUMBER_OF_WITHDRAWALS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getFees = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_FEES });
    try {
      const res = await axios.post(`${URL}/analytics/filings/monthly-fees`, body.length > 0 ? { filters: body } : {});
      dispatch({
        type: GET_FEES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_FEES_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getAverageResponseTimes = (body) => {
  setAuthToken(localStorage.getItem('token'));
  return async (dispatch) => {
    dispatch({ type: GET_AVERAGE_RESPONSE_TIMES });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-average-submission-to-objection`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_AVERAGE_RESPONSE_TIMES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_AVERAGE_RESPONSE_TIMES_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getMonthlyAverageObjectionToResponse = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-average-objection-to-response`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getNumberOfLettersAndNumberOfObjections = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS });
    try {
      const res = await axios.post(
        `${URL}/analytics/filings/monthly-objections`,
        body.length > 0 ? { filters: body } : {},
      );
      dispatch({
        type: GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const clearCacheChart = (chart) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: CLEAR_CACHE_CHART });

    try {
      const res = await axios.delete(`${URL}/analytics/cache/${chart}`);
      dispatch({
        type: CLEAR_CACHE_CHART_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: CLEAR_CACHE_CHART_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const clearAllCacheCharts = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: CLEAR_ALL_CHART_CACHE });

    try {
      const res = await axios.delete(`${URL}/analytics/cache`);
      dispatch({
        type: CLEAR_ALL_CHART_CACHE_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: CLEAR_ALL_CHART_CACHE_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getMonthlyAverageSubmissionToApprovalTime = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME });

    try {
      const res = await axios.post(`${URL}/analytics/filings/monthly-average-submission-to-approval`, body.length > 0 ? { filters: body } : {});
      dispatch({
        type: GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};

export const getMonthlyAverageOpenTime = (body) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_MONTHLY_AVERAGE_OPEN_TIME });

    try {
      const res = await axios.post(`${URL}/analytics/filings/monthly-average-open-time`, body.length > 0 ? { filters: body } : {});
      dispatch({
        type: GET_MONTHLY_AVERAGE_OPEN_TIME_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_MONTHLY_AVERAGE_OPEN_TIME_ERROR,
        payload: response.data,
      });
      throw response && response.data;
    }
  };
};
