import {
  TOGGLE_AI_ASSISTANT,
  GET_OBJECTION_AI_CHAT,
  GET_OBJECTION_AI_CHAT_SUCCESS,
  GET_OBJECTION_AI_CHAT_ERROR,
  ADD_OBJECTION_AI_CHAT,
  ADD_OBJECTION_AI_CHAT_SUCCESS,
  ADD_OBJECTION_AI_CHAT_ERROR,
  POST_OBJECTION_AI_CHAT_MESSAGE,
  POST_OBJECTION_AI_CHAT_MESSAGE_SUCCESS,
  POST_OBJECTION_AI_CHAT_MESSAGE_ERROR,
} from '../actions/aiAssistantActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  activeAssistant: null,
  data: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AI_ASSISTANT: {
      const {
        objectionLetterId,
        objectionId,
        metadata,
      } = action.payload;

      const assistantId = objectionLetterId && objectionId
        ? `${objectionLetterId} ↔ ${objectionId}`
        : null;

      return {
        ...state,
        data: null,
        isFetching: false,
        isFetched: false,
        activeAssistant: assistantId && state.activeAssistant?.id !== assistantId
          ? {
            id: assistantId,
            metadata,
          } : null
      };
    }
    case GET_OBJECTION_AI_CHAT: {
      return {
        ...state,
        isFetched: false,
        isFetching: true,
        data: null,
      }
    }
    case GET_OBJECTION_AI_CHAT_SUCCESS: {
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        data: action.payload,
      }
    }
    case GET_OBJECTION_AI_CHAT_ERROR: {
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        data: {},
      }
    }
    case ADD_OBJECTION_AI_CHAT: {
      return {
        ...state,
        isFetched: false,
        isFetching: true,
        data: null,
      }
    }
    case ADD_OBJECTION_AI_CHAT_SUCCESS: {
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        data: action.payload,
      }
    }
    case ADD_OBJECTION_AI_CHAT_ERROR: {
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        data: {},
      }
    }
    case POST_OBJECTION_AI_CHAT_MESSAGE: {
      return {
        ...state,
      }
    }
    case POST_OBJECTION_AI_CHAT_MESSAGE_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case POST_OBJECTION_AI_CHAT_MESSAGE_ERROR: {
      return {
        ...state,
      }
    }
    default:
      return state;
  }
}
