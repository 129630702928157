import {
  GET_INSTANCES,
  GET_INSTANCES_SUCCESS,
  GET_INSTANCES_ERROR,
  CREATE_INSTANCE,
  EDIT_INSTANCE,
} from '../actions/serffInstanceActions';

const initialState = {
  isFetching: false,
  serffInstances: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INSTANCES: {
      return {
        isFetching: true,
        serffInstances: [],
      };
    }
    case GET_INSTANCES_SUCCESS: {
      return {
        isFetching: false,
        serffInstances: action.payload,
      };
    }
    case GET_INSTANCES_ERROR: {
      return {
        isFetching: false,
        serffInstances: [],
      };
    }
    case CREATE_INSTANCE: {
      return {
        ...state,
        serffInstances: [...state.serffInstances, action.payload],
      };
    }
    case EDIT_INSTANCE: {
      const { instanceId, data } = action.payload;

      return {
        ...state,
        serffInstances: state.serffInstances.map(instance => {
          if (instance._id === instanceId) {
            return data;
          }

          return instance;
        })
      }
    }
    default:
      return state;
  }
}
