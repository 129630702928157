import {
  CREATE_PRODUCT_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  REORDER_PRODUCTS,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
} from '../actions/productActions';

const initialState = {
  isLoading: false,
  error: '',
  products: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS: {
      return {
        isLoading: true,
        error: '',
        products: [],
      };
    }
    case GET_PRODUCTS_SUCCESS: {
      return {
        isLoading: false,
        error: '',
        products: action.payload,
      };
    }
    case REORDER_PRODUCTS: {
      return {
        isLoading: false,
        error: '',
        products: action.payload,
      };
    }
    case GET_PRODUCTS_ERROR: {
      const error = action.payload;
      return {
        isLoading: false,
        error: error && error.message,
        products: [],
      };
    }
    case CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        products: [
          ...state.products,
          action.payload,
        ],
      };
    }
    case EDIT_PRODUCT_SUCCESS: {
      const product = action.payload;
      return {
        ...state,
        products: state.products
          .map((p) => (p._id === product._id ? product : p)),
      };
    }
    case DELETE_PRODUCT_SUCCESS: {
      const { productId } = action.payload;
      return {
        ...state,
        products: state.products.filter(product => product._id !== productId),
      };
    }
    default:
      return state;
  }
}
