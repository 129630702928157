import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_SERFF_COMPANIES = 'GET_SERFF_COMPANIES';
export const GET_SERFF_COMPANIES_SUCCESS = 'GET_SERFF_COMPANIES_SUCCESS';
export const GET_SERFF_COMPANIES_ERROR = 'GET_SERFF_COMPANIES_ERROR';

export const SYNC_SERFF_COMPANIES = 'SYNC_SERFF_COMPANIES';

export const syncSerffCompanies = (serffInstanceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/serff-companies/sync`, { serffInstanceId });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getSerffCompanies = (serffInstanceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_SERFF_COMPANIES });
      const res = await axios.get(`${URL}/serff-companies`, {
        params: {
          limit: 1000,
          serffInstanceId,
        },
      });
      dispatch({
        type: GET_SERFF_COMPANIES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_SERFF_COMPANIES_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};
