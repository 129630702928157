import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_API_CLIENTS = 'GET_API_CLIENTS';
export const GET_API_CLIENTS_SUCCESS = 'GET_API_CLIENTS_SUCCESS';
export const GET_API_CLIENTS_ERROR = 'GET_API_CLIENTS_ERROR';

export const ADD_API_CLIENT_SUCCESS = 'ADD_API_CLIENT_SUCCESS';
export const EDIT_API_CLIENT_SUCCESS = 'EDIT_API_CLIENT_SUCCESS';
export const DELETE_API_CLIENT_SUCCESS = 'DELETE_API_CLIENT_SUCCESS';

export const getApiClients = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_API_CLIENTS });
    try {
      const res = await axios.get(`${URL}/api-clients`);
      dispatch({
        type: GET_API_CLIENTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_API_CLIENTS_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const addApiClient = (label) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/api-clients`, { label });
      dispatch({
        type: ADD_API_CLIENT_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editApiClient = (clientId, label) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/api-clients/${clientId}`, { label });
      dispatch({
        type: EDIT_API_CLIENT_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteApiClient = (clientId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/api-clients/${clientId}`);
      dispatch({
        type: DELETE_API_CLIENT_SUCCESS,
        payload: clientId,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
