import {
  GET_API_CLIENTS,
  GET_API_CLIENTS_SUCCESS,
  GET_API_CLIENTS_ERROR,
  ADD_API_CLIENT_SUCCESS,
  EDIT_API_CLIENT_SUCCESS,
  DELETE_API_CLIENT_SUCCESS,
} from '../actions/apiClientsActions';

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_API_CLIENTS: {
      return {
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_API_CLIENTS_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_API_CLIENTS_ERROR: {
      return {
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    case ADD_API_CLIENT_SUCCESS: {
      const apiClient = {
        ...action.payload,
        validClientSecret: action.payload.clientSecret,
      };
      return {
        isFetching: false,
        data: [...state.data, apiClient],
        error: null,
      };
    }
    case EDIT_API_CLIENT_SUCCESS: {
      const editedApiClient = action.payload;
      return {
        isFetching: false,
        data: state.data.map(apiClient => {
          if (editedApiClient && apiClient._id === editedApiClient._id) {
            return editedApiClient;
          }
          return apiClient;
        }),
        error: null,
      };
    }
    case DELETE_API_CLIENT_SUCCESS: {
      const clientId = action.payload;

      return {
        isFetching: false,
        data: state.data.filter(apiClient => apiClient._id !== clientId),
        error: null,
      };
    }
    default:
      return state;
  }
}
