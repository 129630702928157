import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { usePermissions } from '../services/permissionsService';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useProjectPermissions = (status, hasProjectStatusFeature) => {
  const { hasWritePermission } = usePermissions();

  const permissionByStatus = {
    DRAFT: 'FILINGS_PROJECT_DRAFT_STATUS',
    ACTIVE: 'FILINGS_PROJECT_ACTIVE_STATUS',
    CLOSED: 'FILINGS_PROJECT_CLOSED_STATUS',
  }

  if (hasProjectStatusFeature && status) {
    return hasWritePermission('FILINGS_PROJECTS') && hasWritePermission(permissionByStatus[status])
  }

  return hasWritePermission('FILINGS_PROJECTS')
}

const useNetwork = (callback) => {
  const [isOnline, setNetwork] = useState(null);

  const updateNetwork = () => {
    setNetwork(
      window.navigator.onLine
        ? 'ONLINE'
        : 'OFFLINE',
    );
  };

  useEffect(() => {
    callback(isOnline);
  }, [isOnline]);

  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return isOnline;
};

const useWindowSize = () => {
  const isWindowClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState(
    isWindowClient ? window.innerWidth : undefined,
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(window.innerWidth);
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize);
      return () => window.removeEventListener('resize', setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
};

const useBottomScroll = (listen, callback) => {
  const scroll = () => {
    //var browserZoomLevel = window.devicePixelRatio;
    const { innerHeight } = window;
    const { documentElement, scrollingElement } = document;
    const diff = innerHeight + documentElement.scrollTop - scrollingElement.scrollHeight;
    // if diff range is [-10, 10], apply callback
    if (diff < 10 && diff > -10) {
      callback();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scroll);

    return () => {
      window.removeEventListener('scroll', scroll);
    };
  }, [listen]);
};

const isBrowser = typeof window !== 'undefined';

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

const useScrollPosition = (callback, debounceTime = 0, deps) => {
  const position = useRef(getScrollPosition({ useWindow: true }));
  let throttleTimeout = null;

  const callBack = () => {
    const { x, y } = getScrollPosition({ window, useWindow: true });
    callback({ x, y });
    position.current = { x, y };
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(callBack, debounceTime);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
};

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    const valueToStore = value instanceof Function
      ? value(storedValue)
      : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };
  return [storedValue, setValue];
};

const useEventListener = (eventName, handler, element = document) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      const isSupported = element && element.addEventListener;

      if (!isSupported) {
        return;
      }

      const eventListener = (event) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener, { passive: false });

      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element],
  );
};

const useDrag = () => {
  const [clicked, setClicked] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);
  const position = React.useRef(0);

  const dragStart = React.useCallback((ev) => {
    position.current = ev.clientX;
    setClicked(true);
  }, []);

  const dragStop = React.useCallback(
    () =>
      window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
      }),
    [],
  );

  const dragMove = (ev, cb) => {
    const newDiff = position.current - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (clicked && movedEnough) {
      setDragging(true);
    }

    if (dragging && movedEnough) {
      position.current = ev.clientX;
      cb(newDiff);
    }
  };

  return {
    dragStart,
    dragStop,
    dragMove,
    dragging,
    position,
    setDragging,
  };
};

export {
  useNetwork,
  useInterval,
  useWindowSize,
  useLocalStorage,
  useBottomScroll,
  useScrollPosition,
  useEventListener,
  useDrag,
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterProp;
}
