import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from './hooks';
import { currentUser, hasAnalyticsFeature } from '../selectors/selectors';
import { usePermissions } from '../services/permissionsService';

export default function (Component) {
  const RequireAnalyticsFeature = ({ hasAnalytics, user, navigate }) => {
    const { firstAccessibleUrl } = usePermissions();

    useEffect(() => {
      if (user !== undefined && !hasAnalytics) {
        navigate(firstAccessibleUrl);
      }
    }, [user, hasAnalytics, firstAccessibleUrl]);

    return (
      <Component />
    );
  };

  const mapStateToProps = (state) => ({
    hasAnalytics: hasAnalyticsFeature(state),
    user: currentUser(state),
  });

  return connect(mapStateToProps)(withRouter(RequireAnalyticsFeature));
}
