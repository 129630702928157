import {
  GET_NUMBER_OF_FILINGS_SUBMITTED,
  GET_NUMBER_OF_FILINGS_SUCCESS,
  GET_NUMBER_OF_FILINGS_ERROR,
  GET_FORMS,
  GET_FORMS_SUCCESS,
  GET_FORMS_ERROR,
  GET_RATES,
  GET_RATES_SUCCESS,
  GET_RATES_ERROR,
  GET_NUMBER_OF_APPROVALS,
  GET_NUMBER_OF_APPROVALS_SUCCESS,
  GET_NUMBER_OF_APPROVALS_ERROR,
  GET_NUMBER_OF_REJECTIONS,
  GET_NUMBER_OF_REJECTIONS_SUCCESS,
  GET_NUMBER_OF_REJECTIONS_ERROR,
  GET_NUMBER_OF_WITHDRAWALS,
  GET_NUMBER_OF_WITHDRAWALS_SUCCESS,
  GET_NUMBER_OF_WITHDRAWALS_ERROR,
  GET_FEES,
  GET_FEES_SUCCESS,
  GET_FEES_ERROR,
  GET_AVERAGE_RESPONSE_TIMES,
  GET_AVERAGE_RESPONSE_TIMES_SUCCESS,
  GET_AVERAGE_RESPONSE_TIMES_ERROR,
  GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS,
  GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_SUCCESS,
  GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_ERROR,
  GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE,
  GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_SUCCESS,
  GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_ERROR,
  CLEAR_CACHE_CHART,
  CLEAR_CACHE_CHART_SUCCESS,
  CLEAR_CACHE_CHART_ERROR,
  CLEAR_ALL_CHART_CACHE,
  CLEAR_ALL_CHART_CACHE_SUCCESS,
  CLEAR_ALL_CHART_CACHE_ERROR,
  GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME,
  GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_SUCCESS,
  GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_ERROR,
  GET_MONTHLY_AVERAGE_OPEN_TIME,
  GET_MONTHLY_AVERAGE_OPEN_TIME_SUCCESS,
  GET_MONTHLY_AVERAGE_OPEN_TIME_ERROR,
} from '../actions/analyticsActions';

const initialState = {
  numberOfFilingsSubmitted: {
    isLoading: false,
    error: null,
    data: [],
  },
  forms: {
    isLoading: false,
    error: null,
    data: [],
  },
  rates: {
    isLoading: false,
    error: null,
    data: [],
  },
  numberOfApprovals: {
    isLoading: false,
    error: null,
    data: [],
  },
  numberOfRejections: {
    isLoading: false,
    error: null,
    data: [],
  },
  numberOfWithdrawals: {
    isLoading: false,
    error: null,
    data: [],
  },
  fees: {
    isLoading: false,
    error: null,
    data: [],
  },
  averageResponseTimes: {
    isLoading: false,
    error: null,
    data: [],
  },
  numberOfLettersAndNumberOfObjections: {
    isLoading: false,
    error: null,
    data: [],
  },
  monthlyAverageObjectionToResponse: {
    isLoading: false,
    error: null,
    data: [],
  },
  clearCacheChart: {
    isLoading: false,
    error: null,
    data: [],
  },
  clearAllChartCache: {
    isLoading: false,
    error: null,
    data: [],
  },
  monthlyAverageSubmissionToApprovalTime: {
    isLoading: false,
    error: null,
    data: [],
  },
  monthlyAverageOpenTime: {
    isLoading: false,
    error: null,
    data: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    //Analytics
    case GET_NUMBER_OF_FILINGS_SUBMITTED: {
      return {
        ...state,
        numberOfFilingsSubmitted: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    //CHARTS
    case GET_NUMBER_OF_FILINGS_SUCCESS: {
      return {
        ...state,
        numberOfFilingsSubmitted: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_NUMBER_OF_FILINGS_ERROR: {
      return {
        ...state,
        numberOfFilingsSubmitted: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_FORMS: {
      return {
        ...state,
        forms: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_FORMS_SUCCESS: {
      return {
        ...state,
        forms: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_FORMS_ERROR: {
      return {
        ...state,
        forms: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_RATES: {
      return {
        ...state,
        rates: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_RATES_SUCCESS: {
      return {
        ...state,
        rates: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_RATES_ERROR: {
      return {
        ...state,
        rates: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_APPROVALS: {
      return {
        ...state,
        numberOfApprovals: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_APPROVALS_SUCCESS: {
      return {
        ...state,
        numberOfApprovals: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_NUMBER_OF_APPROVALS_ERROR: {
      return {
        ...state,
        numberOfApprovals: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_REJECTIONS: {
      return {
        ...state,
        numberOfRejections: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_REJECTIONS_SUCCESS: {
      return {
        ...state,
        numberOfRejections: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_NUMBER_OF_REJECTIONS_ERROR: {
      return {
        ...state,
        numberOfRejections: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_WITHDRAWALS: {
      return {
        ...state,
        numberOfWithdrawals: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_WITHDRAWALS_SUCCESS: {
      return {
        ...state,
        numberOfWithdrawals: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_NUMBER_OF_WITHDRAWALS_ERROR: {
      return {
        ...state,
        numberOfWithdrawals: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_FEES: {
      return {
        ...state,
        fees: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_FEES_SUCCESS: {
      return {
        ...state,
        fees: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_FEES_ERROR: {
      return {
        ...state,
        fees: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_AVERAGE_RESPONSE_TIMES: {
      return {
        ...state,
        averageResponseTimes: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_AVERAGE_RESPONSE_TIMES_SUCCESS: {
      return {
        ...state,
        averageResponseTimes: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_AVERAGE_RESPONSE_TIMES_ERROR: {
      return {
        ...state,
        averageResponseTimes: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS: {
      return {
        ...state,
        numberOfLettersAndNumberOfObjections: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_SUCCESS: {
      return {
        ...state,
        numberOfLettersAndNumberOfObjections: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_NUMBER_OF_LETTERS_AND_NUMBER_OF_OBJECTIONS_ERROR: {
      return {
        ...state,
        numberOfLettersAndNumberOfObjections: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    //Monthly Average Objection To Response
    case GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE: {
      return {
        ...state,
        monthlyAverageObjectionToResponse: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_SUCCESS: {
      return {
        ...state,
        monthlyAverageObjectionToResponse: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_MONTHLY_AVERAGE_OBJECTION_TO_RESPONSE_ERROR: {
      return {
        ...state,
        monthlyAverageObjectionToResponse: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    //Clear Cache Chart
    case CLEAR_CACHE_CHART: {
      return {
        ...state,
        clearCacheChart: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case CLEAR_CACHE_CHART_SUCCESS: {
      return {
        ...state,
        clearCacheChart: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case CLEAR_CACHE_CHART_ERROR: {
      return {
        ...state,
        clearCacheChart: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    //Clear All Chart Cache
    case CLEAR_ALL_CHART_CACHE: {
      return {
        ...state,
        clearAllChartCache: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case CLEAR_ALL_CHART_CACHE_SUCCESS: {
      return {
        ...state,
        clearAllChartCache: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case CLEAR_ALL_CHART_CACHE_ERROR: {
      return {
        ...state,
        clearAllChartCache: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    //Monthly Average Submission To Approval Time
    case GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME: {
      return {
        ...state,
        monthlyAverageSubmissionToApprovalTime: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_SUCCESS: {
      return {
        ...state,
        monthlyAverageSubmissionToApprovalTime: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_MONTHLY_AVERAGE_SUBMISSION_TO_APPROVAL_TIME_ERROR: {
      return {
        ...state,
        monthlyAverageSubmissionToApprovalTime: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    //Monthly Average Open Time
    case GET_MONTHLY_AVERAGE_OPEN_TIME: {
      return {
        ...state,
        monthlyAverageOpenTime: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_MONTHLY_AVERAGE_OPEN_TIME_SUCCESS: {
      return {
        ...state,
        monthlyAverageOpenTime: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_MONTHLY_AVERAGE_OPEN_TIME_ERROR: {
      return {
        ...state,
        monthlyAverageOpenTime: {
          isLoading: false,
          error: action.payload,
          data: [],
        },
      };
    }
    default:
      return state;
  }
}
