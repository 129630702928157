import { useEffect } from 'react';
import {
  m,
  LazyMotion,
  domAnimation,
  AnimatePresence,
} from 'framer-motion';
import { useMatch } from 'react-router-dom';
import { Layout } from 'antd';
import NavBar from '../NavBar';
import noLayoutRoutes from './noLayoutRoutes';
import './Layout.scss';

const duration = 0.3;
const delay = 0.1;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      delayChildren: 0.15,
      staggerChildren: 0.15,
      easeInOut: [0.43, 0.13, 0.23, 0.86],
      duration,
      delay,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      duration,
      easeInOut: [0.43, 0.13, 0.23, 0.86],
    },
  },
};

const MainLayout = ({ children, location }) => {
  const noLayout = noLayoutRoutes
    .filter((route) => useMatch(route)).length > 0;

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 't8nplu9n',
        hide_default_launcher:
          window.innerWidth < 768 || location.pathname !== '/dashboard',
      });
    }
  }, [location.pathname]);

  return (
    <AnimatePresence initial key={location.pathname + 'animate'} mode="wait">
      <LazyMotion features={domAnimation}>
        {
          noLayout ? (
            <m.div
              key={location.pathname}
              variants={variants}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              { children }
            </m.div>
          ) : (
            <div className="layout-wrapper">
              <Layout className="main-layout">
                <NavBar />
                <Layout>
                  <Layout.Content className="main-page">
                    <m.div
                      className="main-content"
                      key={location.pathname}
                      variants={variants}
                      initial="initial"
                      animate="enter"
                      exit="exit"
                    >
                      {children}
                    </m.div>
                  </Layout.Content>
                </Layout>
              </Layout>
            </div>
          )
        }
      </LazyMotion>
    </AnimatePresence>
  );
};

export default MainLayout;
