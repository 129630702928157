import {
  GET_FORMS_LIBRARY,
  GET_FORMS_LIBRARY_ERROR,
  GET_FORMS_LIBRARY_SUCCESS,
  GET_LIBRARY_FORMS,
  GET_LIBRARY_FORMS_SUCCESS,
  GET_LIBRARY_FORMS_ERROR,
  HIDE_FORM,
  UNHIDE_FORM,
} from '../actions/formsLibraryActions';
import {
  ADD_LIBRARY_FORM,
  EDIT_LIBRARY_FORM,
  ADD_LIBRARY_FORM_ATTACHMENT,
  DELETE_LIBRARY_FORM_ATTACHMENT,
  ADD_LIBRARY_FORM_CUSTOM_FIELD,
  EDIT_LIBRARY_FORM_CUSTOM_FIELD,
  DELETE_LIBRARY_FORM_CUSTOM_FIELD,
  EDIT_LIBRARY_FORM_STATUS,
  ADD_LIBRARY_FORM_STATUS,
  CLONE_LIBRARY_FORM_STATUS,
} from '../actions/libraryFormActions';

const initialValue = {
  items: [],
  count: 0,
  isFetching: false,
  error: null,
};
export default function (state = initialValue, action) {
  switch (action.type) {
    case GET_LIBRARY_FORMS: {
      return {
        count: 0,
        items: [],
        isFetching: true,
        error: null,
      };
    }
    case GET_LIBRARY_FORMS_SUCCESS: {
      const { items, count } = action.payload;
      return {
        ...state,
        items,
        count,
        isFetching: false,
        error: null,
      };
    }
    case GET_LIBRARY_FORMS_ERROR: {
      return {
        ...state,
        count: 0,
        items: [],
        isFetching: false,
        error: action.payload,
      };
    }
    case GET_FORMS_LIBRARY: {
      return {
        items: [],
        count: 0,
        isFetching: true,
        error: null,
      };
    }
    case ADD_LIBRARY_FORM: {
      return {
        items: [action.payload, ...state.items],
        count: state.count + 1,
        isFetching: false,
        error: null,
      };
    }
    case EDIT_LIBRARY_FORM: {
      const form = action.payload;
      return {
        items: state.items.map(item => {
          if (item._id === form._id) {
            return form;
          }
          return item;
        }),
        count: state.count + 1,
        isFetching: false,
        error: null,
      };
    }
    case HIDE_FORM: {
      const items = state.items.map((form) => {
        if (form.formNumber === action.payload) {
          return {
            ...form,
            hidden: true,
          };
        }
        return form;
      });
      return {
        ...state,
        items,
      };
    }
    case UNHIDE_FORM: {
      const items = state.items.map((form) => {
        if (form.formNumber === action.payload) {
          return {
            ...form,
            hidden: false,
          };
        }
        return form;
      });
      return {
        ...state,
        items,
      };
    }
    case GET_FORMS_LIBRARY_SUCCESS: {
      const { items, count } = action.payload;
      return {
        items,
        count,
        isFetching: false,
        error: null,
      };
    }
    case GET_FORMS_LIBRARY_ERROR: {
      return {
        items: [],
        count: 0,
        isFetching: false,
        error: action.payload,
      };
    }
    case ADD_LIBRARY_FORM_ATTACHMENT: {
      if (!state.items.length) {
        return state;
      }

      const { formId, attachment } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              attachments: [...item.attachments, attachment],
            };
          }
          return item;
        }),
      };
    }
    case DELETE_LIBRARY_FORM_ATTACHMENT: {
      if (!state.items.length) {
        return state;
      }

      const { formId, attachmentId } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              attachments: item.attachments
                .filter(attachment => attachment._id !== attachmentId),
            };
          }
          return item;
        }),
      };
    }
    case ADD_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return {
                    ...formStatus,
                    customFields: [...formStatus.customFields, data],
                  };
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case EDIT_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        customFieldRecordId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return {
                    ...formStatus,
                    customFields: formStatus
                      .customFields
                      .map(customField => {
                        if (customField._id === customFieldRecordId) {
                          return data;
                        }
                        return customField;
                      }),
                  };
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case DELETE_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        customFieldRecordId,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return {
                    ...formStatus,
                    customFields: formStatus
                      .customFields
                      .filter(customField => customField._id !== customFieldRecordId),
                  };
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    case ADD_LIBRARY_FORM_STATUS: {
      if (!state.items.length) {
        return state;
      }

      const { formId, data } = action.payload;
      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: [...item.statuses, data],
            };
          }
          return item;
        }),
      };
    }
    case CLONE_LIBRARY_FORM_STATUS: {
      if (!state.items.length) {
        return state;
      }

      const { formId, data } = action.payload;
      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: [...item.statuses, data],
            };
          }
          return item;
        }),
      };
    }
    case EDIT_LIBRARY_FORM_STATUS: {
      if (!state.items.length) {
        return state;
      }

      const {
        formId,
        formStatusId,
        data,
      } = action.payload;

      return {
        ...state,
        items: state.items.map(item => {
          if (item._id === formId) {
            return {
              ...item,
              statuses: item.statuses.map(formStatus => {
                if (formStatus._id === formStatusId) {
                  return data;
                }
                return formStatus;
              }),
            };
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
}
