import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_STATE_DETAILS = 'GET_STATE_DETAILS';
export const GET_STATE_DETAILS_SUCCESS = 'GET_STATE_DETAILS_SUCCESS';
export const GET_STATE_DETAILS_ERROR = 'GET_STATE_DETAILS_ERROR';

export const EDIT_FILING = 'EDIT_FILING';
export const DELETE_FILING = 'DELETE_FILING';
export const TOGGLE_ARCHIVE_FILING = 'TOGGLE_ARCHIVE_FILING';
export const EDIT_FILING_EFFECTIVE_DATE_OVERRIDE = 'EDIT_FILING_EFFECTIVE_DATE_OVERRIDE';
export const EDIT_FILING_EFFECTIVE_DATE_RENEWAL_OVERRIDE = 'EDIT_FILING_EFFECTIVE_DATE_RENEWAL_OVERRIDE';
export const EDIT_FILING_RESPOND_BY_DATE_OVERRIDE = 'EDIT_FILING_RESPOND_BY_DATE_OVERRIDE';
export const EDIT_FILING_ATTACHMENT_OVERRIDE = 'EDIT_FILING_ATTACHMENT_OVERRIDE';
export const DELETE_FILING_ATTACHMENT = 'DELETE_FILING_ATTACHMENT';

export const EDIT_FILING_PROJECT = 'EDIT_FILING_PROJECT';
export const EDIT_FILING_PRODUCT = 'EDIT_FILING_PRODUCT';

export const ADD_FORM_SCHEDULE_ITEM = 'ADD_FORM_SCHEDULE_ITEM';
export const EDIT_FORM_SCHEDULE_ITEM = 'EDIT_FORM_SCHEDULE_ITEM';
export const DELETE_FORM_SCHEDULE_ITEM = 'DELETE_FORM_SCHEDULE_ITEM';
export const ADD_FORM_SCHEDULE_ITEM_ATTACHMENT = 'ADD_FORM_SCHEDULE_ITEM_ATTACHMENT';
export const DELETE_FORM_SCHEDULE_ITEM_ATTACHMENT = 'DELETE_FORM_SCHEDULE_ITEM_ATTACHMENT';

export const ADD_RATE_SCHEDULE_ITEM = 'ADD_RATE_SCHEDULE_ITEM';
export const EDIT_RATE_SCHEDULE_ITEM = 'EDIT_RATE_SCHEDULE_ITEM';
export const DELETE_RATE_SCHEDULE_ITEM = 'DELETE_RATE_SCHEDULE_ITEM';
export const ADD_RATE_SCHEDULE_ITEM_ATTACHMENT = 'ADD_RATE_SCHEDULE_ITEM_ATTACHMENT';
export const DELETE_RATE_SCHEDULE_ITEM_ATTACHMENT = 'DELETE_RATE_SCHEDULE_ITEM_ATTACHMENT';

export const ADD_SUPPORTING_DOCUMENTATION_ITEM = 'ADD_SUPPORTING_DOCUMENTATION_ITEM';
export const EDIT_SUPPORTING_DOCUMENTATION_ITEM = 'EDIT_SUPPORTING_DOCUMENTATION_ITEM';
export const DELETE_SUPPORTING_DOCUMENTATION_ITEM = 'DELETE_SUPPORTING_DOCUMENTATION_ITEM';
export const ADD_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT = 'ADD_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT';
export const DELETE_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT = 'DELETE_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT';

export const EDIT_STATE_GENERAL_INFORMATION = 'EDIT_STATE_GENERAL_INFORMATION';
export const EDIT_STATE_FEES = 'EDIT_STATE_FEES';
export const EDIT_STATE_RATE_DATA = 'EDIT_STATE_RATE_DATA';
export const INVALIDATE_STATE_DETAILS = 'INVALIDATE_STATE_DETAILS';

export const UPSERT_STATE_CUSTOM_FIELD = 'UPSERT_STATE_CUSTOM_FIELD';
export const DELETE_STATE_CUSTOM_FIELD = 'DELETE_STATE_CUSTOM_FIELD';

// const getProducts = () => axios.get(`${URL}/products`);
const getState = (projectId, state) => axios.get(`${URL}/projects/${projectId}/states/${state}`);

export const bulkUpsertStateCustomField = ({
  projectId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.put(`${URL}/projects/${projectId}/states/custom-fields/bulk`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const upsertStateCustomField = ({
  projectId,
  stateId,
  customField,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/projects/${projectId}/states/${stateId}/custom-fields`, customField);
      dispatch({
        type: UPSERT_STATE_CUSTOM_FIELD,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteStateCustomField = ({
  projectId,
  stateId,
  customFieldValueId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/projects/${projectId}/states/${stateId}/custom-fields/${customFieldValueId}`);
      dispatch({
        type: DELETE_STATE_CUSTOM_FIELD,
        payload: {
          projectId,
          customFieldValueId,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getStateDetails = (projectId, state) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_STATE_DETAILS });
      const [stateDetails] = await Promise
        .all([
          getState(projectId, state),
          // getProducts(),
        ]);
      dispatch({
        type: GET_STATE_DETAILS_SUCCESS,
        payload: {
          stateDetails: stateDetails.data,
        },
      });
    } catch ({ response }) {
      dispatch({
        type: GET_STATE_DETAILS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addFilingAttachment = (filingId, attachment) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/filings/${filingId}/overrides/attachment`, attachment);
      if (res.data) {
        dispatch({
          type: EDIT_FILING_ATTACHMENT_OVERRIDE,
          payload: {
            attachment: res.data,
            filingId,
          },
        });
      }
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFormAttachment = ({ filingId, formId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/forms/${formId}/attachments`, attachment);
      dispatch({
        type: ADD_FORM_SCHEDULE_ITEM_ATTACHMENT,
        payload: { filingId, formId, attachment: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFormAttachment = ({ filingId, formId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}/forms/${formId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_FORM_SCHEDULE_ITEM_ATTACHMENT,
        payload: { formId, attachmentId },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFormSchedule = ({ filingId, formId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/forms/${formId}`, data);
      dispatch({
        type: EDIT_FORM_SCHEDULE_ITEM,
        payload: {
          filingId,
          form: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addRateRuleAttachment = ({ filingId, rateId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/rates/${rateId}/attachments`, attachment);
      dispatch({
        type: ADD_RATE_SCHEDULE_ITEM_ATTACHMENT,
        payload: { filingId, rateId, attachment: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteRateRuleAttachment = ({ filingId, rateId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}/rates/${rateId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_RATE_SCHEDULE_ITEM_ATTACHMENT,
        payload: { rateId, attachmentId },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editRateRuleScheduleItem = ({ filingId, rateId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/rates/${rateId}`, data);
      dispatch({
        type: EDIT_RATE_SCHEDULE_ITEM,
        payload: {
          filingId,
          rate: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addRateRuleScheduleItem = ({ filingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/rates`, data);
      dispatch({
        type: ADD_RATE_SCHEDULE_ITEM,
        payload: {
          filingId,
          rate: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFormScheduleItem = ({ filingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/forms`, data);
      dispatch({
        type: ADD_FORM_SCHEDULE_ITEM,
        payload: {
          filingId,
          form: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFormSchedule = ({ filingId, formId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/filings/${filingId}/forms/${formId}`);
      dispatch({
        type: DELETE_FORM_SCHEDULE_ITEM,
        payload: { filingId, formId },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export function deleteRateRuleSchedule({ filingId, rateId }) {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/filings/${filingId}/rates/${rateId}`);
      dispatch({
        type: DELETE_RATE_SCHEDULE_ITEM,
        payload: { filingId, rateId },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const addSupportingDocumentation = ({ filingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/supportings`, data);
      dispatch({
        type: ADD_SUPPORTING_DOCUMENTATION_ITEM,
        payload: {
          filingId,
          supporting: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editSupportingDocumentation = ({ filingId, supportingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/supportings/${supportingId}`, data);
      dispatch({
        type: EDIT_SUPPORTING_DOCUMENTATION_ITEM,
        payload: {
          filingId,
          supporting: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteSupportingDocumentation = ({ filingId, supportingId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/filings/${filingId}/supportings/${supportingId}`);
      dispatch({
        type: DELETE_SUPPORTING_DOCUMENTATION_ITEM,
        payload: { filingId, supportingId },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addSupportingAttachment = ({ filingId, supportingId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/supportings/${supportingId}/attachments`, attachment);
      dispatch({
        type: ADD_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT,
        payload: { filingId, supportingId, attachment: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteSupportingAttachment = ({ filingId, supportingId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}/supportings/${supportingId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT,
        payload: { supportingId, attachmentId },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const createFiling = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingProduct = (filingId, name) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/product`, {
        name,
      });
      dispatch({
        type: EDIT_FILING_PRODUCT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingProject = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/project`, data);
      dispatch({
        type: EDIT_FILING_PROJECT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFiling = ({
  businessType,
  filingId,
  companies,
  lineOfInsurance,
  customTrackingNumber,
  dateSubmitted,
  typeOfInsurance,
  subTypeOfInsurance,
  filingType,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}`, {
        companies,
        lineOfInsurance,
        customTrackingNumber,
        dateSubmitted,
        typeOfInsurance,
        subTypeOfInsurance,
        businessType,
        filingType,
      });
      dispatch({
        type: EDIT_FILING,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingRespondByDate = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/overrides`, data);
      dispatch({
        type: EDIT_FILING_RESPOND_BY_DATE_OVERRIDE,
        payload: { filingId, overrides: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingEffectiveDate = (filingId, effectiveDate) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/overrides`, {
        effectiveDate,
      });
      if (res.data && res.data.effectiveDate) {
        dispatch({
          type: EDIT_FILING_EFFECTIVE_DATE_OVERRIDE,
          payload: {
            effectiveDate: res.data.effectiveDate,
            filingId,
          },
        });
      }
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editFilingEffectiveDateRenewal = (filingId, effectiveDateRenewal) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/overrides`, {
        effectiveDateRenewal,
      });
      if (res.data && res.data.effectiveDateRenewal) {
        dispatch({
          type: EDIT_FILING_EFFECTIVE_DATE_RENEWAL_OVERRIDE,
          payload: {
            effectiveDateRenewal: res.data.effectiveDateRenewal,
            filingId,
          },
        });
      }
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const toggleArchiveFiling = (filingId, hidden) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/overrides`, { hidden });
      dispatch({ type: TOGGLE_ARCHIVE_FILING, payload: { filingId, archived: hidden } });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFiling = (filingId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}`);
      dispatch({ type: DELETE_FILING, payload: filingId });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteFilingAttachment = (filingId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}/overrides/attachment`);
      dispatch({ type: DELETE_FILING_ATTACHMENT, payload: filingId });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFilingObjection = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/objections`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFilingResponse = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/responses`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFilingAmendment = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/amendments`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addFilingDisposition = (filingId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/dispositions`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteEvent = ({ filingId, eventId, eventType }) => {
  setAuthToken(localStorage.getItem('token'));

  let event;
  switch (eventType) {
    case 'objection':
      event = 'objections';
      break;
    case 'response':
      event = 'responses';
      break;
    case 'amendment':
      event = 'amendments';
      break;
    case 'disposition':
    default:
      event = 'dispositions';
      break;
  }

  return async () => {
    try {
      const res = await axios.delete(`${URL}/filings/${filingId}/${event}/${eventId}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editObjection = ({ filingId, objectionId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/objections/${objectionId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editDisposition = ({ filingId, dispositionId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/dispositions/${dispositionId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editAmendment = ({ filingId, amendmentId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/amendments/${amendmentId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editResponse = ({ filingId, responseId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/responses/${responseId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editGeneralInformation = ({ filingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/general-information`, data);
      dispatch({
        type: EDIT_STATE_GENERAL_INFORMATION,
        payload: {
          filingId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editStateFees = ({ filingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/fee`, data);
      dispatch({
        type: EDIT_STATE_FEES,
        payload: {
          filingId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addObjectionAttachment = ({ filingId, objectionId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/objections/${objectionId}/attachments`, attachment);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addResponseAttachment = ({ filingId, responseId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/responses/${responseId}/attachments`, attachment);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addDispositionAttachment = ({ filingId, dispositionId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/filings/${filingId}/dispositions/${dispositionId}/attachments`, attachment);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editStateRateData = ({ filingId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/filings/${filingId}/rate-data`, data);
      dispatch({
        type: EDIT_STATE_RATE_DATA,
        payload: {
          filingId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const invalidateStateDetails = () => async (dispatch) => {
  dispatch({
    type: INVALIDATE_STATE_DETAILS,
  });
};
