import {
  GET_FILING_DRAFT,
  GET_FILING_DRAFT_SUCCESS,
  GET_FILING_DRAFT_ERROR,
  SELECT_BATCH,
  SELECT_FILING_DRAFT,
  EDIT_FILING_DRAFT_SUCCESS,
  EDIT_FILING_DRAFT_ITEM_SUCCESS,
  INVALIDATE_FILING_DRAFT,
  ADD_FORM_SCHEDULE,
  EDIT_FORM_SCHEDULE,
  REMOVE_FORM_SCHEDULE,
  ADD_RATE_SCHEDULE,
  EDIT_RATE_SCHEDULE,
  REMOVE_RATE_SCHEDULE,
  APPLY_CHANGES,
  APPLY_CHANGES_SUCCESS,
  EDIT_GENERAL_INFORMATION,
  EDIT_RATE_DATA,
  EDIT_FILING_FEE,
  EDIT_STATE_SPECIFICS,
  BYPASS_DOCUMENT,
  RESET_DOCUMENT,
  EDIT_DOCUMENT,
  ADD_DOCUMENT_ATTACHMENT,
  REMOVE_DOCUMENT_ATTACHMENT,
  CREATE_DOCUMENT_TEMPLATE,
  DELETE_DOCUMENT_TEMPLATE,
  EDIT_DOCUMENT_TEMPLATE,
  ATTACH_FORM_SCHEDULE_ATTACHMENT,
  ATTACH_RATE_SCHEDULE_ATTACHMENT,
  EDIT_SINGLE_GENERAL_INFORMATION,
  ADD_SINGLE_FORM_SCHEDULE,
  EDIT_SINGLE_FORM_SCHEDULE,
  REMOVE_SINGLE_FORM_SCHEDULE,
  ATTACH_SINGLE_FORM_SCHEDULE_ATTACHMENT,
  ADD_SINGLE_RATE_SCHEDULE,
  ATTACH_SINGLE_RATE_SCHEDULE_ATTACHMENT,
  REMOVE_FORM_SCHEDULE_ATTACHMENT,
  REMOVE_SINGLE_FORM_SCHEDULE_ATTACHMENT,
  REMOVE_SINGLE_RATE_SCHEDULE,
  EDIT_SINGLE_RATE_SCHEDULE,
  REMOVE_RATE_SCHEDULE_ATTACHMENT,
  REMOVE_SINGLE_RATE_SCHEDULE_ATTACHMENT,
  REMOVE_SUPPORTING_TEMPLATE_ATTACHMENT,
  ATTACH_SUPPORTING_TEMPLATE_ATTACHMENT,
  ATTACH_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT,
  REMOVE_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT,
  ATTACH_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT,
  REMOVE_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT,
  EXCLUDE_SINGLE_FORM_TEMPLATE,
  EXCLUDE_SINGLE_RATE_TEMPLATE,
  REMOVE_BATCH_ITEM,
  GET_BATCH_ITEM,
  ADD_FORM_OVERRIDES,
  EDIT_FORM_OVERRIDES,
  ADD_RATE_OVERRIDES,
  EDIT_RATE_OVERRIDES,
  INCLUDE_DEFAULT_FORM_ITEMS,
  INCLUDE_DEFAULT_RATE_ITEMS,
  RESET_FORM_SCHEDULE_ITEM_OVERRIDE,
  RESET_RATE_SCHEDULE_ITEM_OVERRIDE,
  GET_FILING_DRAFT_SNAPSHOT,
  GET_FILING_DRAFT_SNAPSHOT_SUCCESS,
  GET_FILING_DRAFT_SNAPSHOT_ERROR,
} from '../actions/filingDraftsActions';

const initialState = {
  isFetching: false,
  isSaving: false,
  data: {},
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILING_DRAFT: {
      return {
        isFetching: true,
        isSaving: false,
        data: {},
        error: null,
      };
    }
    case ADD_FORM_SCHEDULE: {
      const { form, batchId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.forms) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    forms: [...batch.template.forms, form],
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_FORM_SCHEDULE: {
      const { form, batchId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.forms) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    forms: batch.template.forms.map(f => {
                      if (f._id === form._id) {
                        return form;
                      }
                      return f;
                    }),
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_RATE_SCHEDULE_ATTACHMENT: {
      const { rateId, batchId, attachment } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.rates) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    rates: batch.template.rates.map(f => {
                      if (f._id === rateId) {
                        return {
                          ...f,
                          attachments: attachment
                            ? [...f.attachments, attachment]
                            : f.attachments,
                        };
                      }
                      return f;
                    }),
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_RATE_SCHEDULE_ATTACHMENT: {
      const { rateId, batchId, attachmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.forms) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    rates: batch.template.rates.map(f => {
                      if (f._id === rateId) {
                        return {
                          ...f,
                          attachments: f.attachments
                            .filter(attachment => attachment._id !== attachmentId),
                        };
                      }
                      return f;
                    }),
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_FORM_SCHEDULE_ATTACHMENT: {
      const { formId, batchId, attachmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.forms) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    forms: batch.template.forms.map(f => {
                      if (f._id === formId) {
                        return {
                          ...f,
                          attachments: f.attachments
                            .filter(attachment => attachment._id !== attachmentId),
                        };
                      }
                      return f;
                    }),
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_FORM_SCHEDULE_ATTACHMENT: {
      const { formId, batchId, attachment } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.forms) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    forms: batch.template.forms.map(f => {
                      if (f._id === formId) {
                        return {
                          ...f,
                          attachments: attachment
                            ? [...f.attachments, attachment]
                            : f.attachments,
                        };
                      }
                      return f;
                    }),
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_FORM_SCHEDULE: {
      const { batchId, formId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.forms) {
                batch.template.forms = batch.template.forms.filter(form => form._id !== formId);
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_RATE_SCHEDULE: {
      const { batchId, rateId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.rates) {
                batch.template.rates = batch.template.rates.filter(rate => rate._id !== rateId);
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_RATE_SCHEDULE: {
      const { rate, batchId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.rates) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    rates: batch.template.rates.map(f => {
                      if (f._id === rate._id) {
                        return rate;
                      }
                      return f;
                    }),
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case ADD_RATE_SCHEDULE: {
      const { rate, batchId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.rates) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    rates: [...batch.template.rates, rate],
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case CREATE_DOCUMENT_TEMPLATE: {
      const { batchId, data } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.blueprint && batch.blueprint.supportings) {
                batch.blueprint.supportings = [...batch.blueprint.supportings, data];
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case DELETE_DOCUMENT_TEMPLATE: {
      const { batchId, templateId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.blueprint && batch.blueprint.supportings) {
                batch.blueprint.supportings = batch.blueprint.supportings
                  .filter(template => template._id !== templateId);
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_DOCUMENT_TEMPLATE: {
      const { batchId, data } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.blueprint && batch.blueprint.supportings) {
                batch.blueprint.supportings = batch.blueprint.supportings.map(template => {
                  if (template._id === data._id) {
                    return data;
                  }
                  return template;
                });
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_SUPPORTING_TEMPLATE_ATTACHMENT: {
      const {
        batchId,
        supportingId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.blueprint && batch.blueprint.supportings) {
                batch.blueprint.supportings = batch.blueprint.supportings.map(template => {
                  if (template._id === supportingId) {
                    return {
                      ...template,
                      attachments: [...template.attachments, data],
                    };
                  }
                  return template;
                });
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SUPPORTING_TEMPLATE_ATTACHMENT: {
      const {
        batchId,
        supportingId,
        attachmentId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.blueprint && batch.blueprint.supportings) {
                batch.blueprint.supportings = batch.blueprint.supportings.map(template => {
                  if (template._id === supportingId) {
                    return {
                      ...template,
                      attachments: template.attachments
                        .filter(attachment => attachment._id !== attachmentId),
                    };
                  }
                  return template;
                });
                return batch;
              }
            }
            return batch;
          }),
        },
      };
    }
    case APPLY_CHANGES: {
      return {
        ...state,
        isSaving: true,
      };
    }
    case APPLY_CHANGES_SUCCESS: {
      return {
        ...state,
        isSaving: false,
      };
    }
    case EDIT_DOCUMENT: {
      const {
        batchId,
        itemId,
        supportingId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              batch.items.map(item => {
                if (item._id === itemId) {
                  if (item.supportings) {
                    item.supportings = item.supportings.map(supporting => {
                      if (supporting._id === supportingId) {
                        return data;
                      }
                      return supporting;
                    });
                    return item;
                  }
                }
                return item;
              });
              return batch;
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_DOCUMENT_ATTACHMENT: {
      const {
        batchId,
        itemId,
        supportingId,
        attachmentId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              batch.items.map(item => {
                if (item._id === itemId) {
                  if (item.supportings) {
                    item.supportings = item.supportings.map(supporting => {
                      if (supporting._id === supportingId) {
                        return {
                          ...supporting,
                          attachments: supporting.attachments.filter(a => a._id !== attachmentId),
                        };
                      }
                      return supporting;
                    });
                    return item;
                  }
                }
                return item;
              });
              return batch;
            }
            return batch;
          }),
        },
      };
    }
    case ADD_DOCUMENT_ATTACHMENT: {
      const {
        batchId,
        itemId,
        supportingId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              batch.items.map(item => {
                if (item._id === itemId) {
                  if (item.supportings) {
                    item.supportings = item.supportings.map(supporting => {
                      if (supporting._id === supportingId) {
                        return {
                          ...supporting,
                          attachments: data
                            ? [...supporting.attachments, data]
                            : supporting.attachments,
                        };
                      }
                      return supporting;
                    });
                    return item;
                  }
                }
                return item;
              });
              return batch;
            }
            return batch;
          }),
        },
      };
    }
    case RESET_DOCUMENT: {
      const {
        batchId,
        itemId,
        supportingId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              batch.items.map(item => {
                if (item._id === itemId) {
                  if (item.supportings) {
                    item.supportings = item.supportings
                      .filter(supporting => supporting._id !== supportingId);
                    return item;
                  }
                }
                return item;
              });
              return batch;
            }
            return batch;
          }),
        },
      };
    }
    case BYPASS_DOCUMENT: {
      const {
        batchId,
        itemId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              batch.items.map(item => {
                if (item._id === itemId) {
                  if (item.supportings) {
                    item.supportings = [...item.supportings, data];
                    return item;
                  }
                }
                return item;
              });
              return batch;
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_RATE_DATA: {
      const { rateData, batchId, itemId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rateData,
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_FILING_FEE: {
      const { fee, itemId, batchId } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      fee,
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_STATE_SPECIFICS: {
      const { stateSpecifics, itemId, batchId } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      stateSpecifics,
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_GENERAL_INFORMATION: {
      const { generalInformation, batchId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              if (batch.template && batch.template.rates) {
                return {
                  ...batch,
                  template: {
                    ...batch.template,
                    generalInformation,
                  },
                };
              }
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_FILING_DRAFT_ITEM_SUCCESS: {
      const { batchId, itemId, data } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      ...data,
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_SINGLE_GENERAL_INFORMATION: {
      const {
        batchId,
        itemId,
        generalInformation,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      generalInformation,
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ADD_SINGLE_FORM_SCHEDULE: {
      const { batchId, itemId, form } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        entries: [...item.form.entries, form],
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_SINGLE_RATE_SCHEDULE: {
      const { batchId, itemId, rate } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        entries: item.rate.entries.map(f => (f._id === rate._id ? rate : f)),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_SINGLE_FORM_SCHEDULE: {
      const { batchId, itemId, form } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        entries: item.form.entries.map(f => (f._id === form._id ? form : f)),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SINGLE_RATE_SCHEDULE: {
      const {
        batchId,
        itemId,
        rateId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        entries: item.rate.entries.filter(f => f._id !== rateId),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SINGLE_FORM_SCHEDULE: {
      const {
        batchId,
        itemId,
        formId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        entries: item.form.entries.filter(f => f._id !== formId),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SINGLE_RATE_SCHEDULE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        rateId,
        attachmentId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        entries: item.rate.entries.map(f => {
                          if (f._id === rateId) {
                            return {
                              ...f,
                              attachments: f.attachments
                                .filter(attachment => attachment._id !== attachmentId),
                            };
                          }
                          return f;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SINGLE_FORM_SCHEDULE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        formId,
        attachmentId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        entries: item.form.entries.map(f => {
                          if (f._id === formId) {
                            return {
                              ...f,
                              attachments: f.attachments
                                .filter(attachment => attachment._id !== attachmentId),
                            };
                          }
                          return f;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_SINGLE_FORM_SCHEDULE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        formId,
        attachment,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        entries: item.form.entries.map(f => {
                          if (f._id === formId) {
                            return {
                              ...f,
                              attachments: attachment
                                ? [...f.attachments, attachment]
                                : f.attachments,
                            };
                          }
                          return f;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ADD_SINGLE_RATE_SCHEDULE: {
      const { batchId, itemId, rate } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        entries: [...item.rate.entries, rate],
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_SINGLE_RATE_SCHEDULE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        rateId,
        attachment,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        entries: item.rate.entries.map(f => {
                          if (f._id === rateId) {
                            return {
                              ...f,
                              attachments: attachment
                                ? [...f.attachments, attachment]
                                : f.attachments,
                            };
                          }
                          return f;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case GET_FILING_DRAFT_SUCCESS: {
      const { batches, ...filing } = action.payload;
      let selectedBatch = null;

      if (batches && batches.length && batches[0]._id) {
        selectedBatch = batches[0]._id;
      }

      return {
        isFetching: false,
        isSaving: false,
        error: null,
        data: {
          ...filing,
          selectedBatch,
          selectedFiling: null,
          batches,
        },
      };
    }
    case EXCLUDE_SINGLE_FORM_TEMPLATE: {
      const { batchId, itemId, excludes } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        excludes,
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_BATCH_ITEM: {
      const { batchId, itemId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.filter(item => item._id !== itemId),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EXCLUDE_SINGLE_RATE_TEMPLATE: {
      const { batchId, itemId, excludes } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        excludes,
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case GET_BATCH_ITEM: {
      const { batchId, itemId, data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...data,
                      serffCompanies: data.serffCompanies.map(serffCompany => serffCompany._id),
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ADD_FORM_OVERRIDES: {
      const {
        batchId,
        itemId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        overrides: [...item.form.overrides, data],
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_FORM_OVERRIDES: {
      const {
        batchId,
        itemId,
        overrideId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        overrides: item.form.overrides.map(override => {
                          if (override._id === overrideId) {
                            return data;
                          }
                          return override;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ADD_RATE_OVERRIDES: {
      const {
        batchId,
        itemId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        overrides: [...item.rate.overrides, data],
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case EDIT_RATE_OVERRIDES: {
      const {
        batchId,
        itemId,
        overrideId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        overrides: item.rate.overrides.map(override => {
                          if (override._id === overrideId) {
                            return data;
                          }
                          return override;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        overrideId,
        attachment,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        overrides: item.form.overrides.map(override => {
                          if (override._id === overrideId) {
                            return {
                              ...override,
                              attachments: {
                                ...override.attachments,
                                value: [...override.attachments.value, attachment],
                              },
                            };
                          }
                          return override;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case ATTACH_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        overrideId,
        attachment,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        overrides: item.rate.overrides.map(override => {
                          if (override._id === overrideId) {
                            return {
                              ...override,
                              attachments: {
                                ...override.attachments,
                                value: [...override.attachments.value, attachment],
                              },
                            };
                          }
                          return override;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SINGLE_FORM_SCHEDULE_OVERRIDE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        overrideId,
        attachmentId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        overrides: item.form.overrides.map(override => {
                          if (override._id === overrideId) {
                            return {
                              ...override,
                              attachments: {
                                ...override.attachments,
                                value: override.attachments.value
                                  .filter(attachment => attachment._id !== attachmentId),
                              },
                            };
                          }
                          return override;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case REMOVE_SINGLE_RATE_SCHEDULE_OVERRIDE_ATTACHMENT: {
      const {
        batchId,
        itemId,
        overrideId,
        attachmentId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        overrides: item.rate.overrides.map(override => {
                          if (override._id === overrideId) {
                            return {
                              ...override,
                              attachments: {
                                ...override.attachments,
                                value: override.attachments.value
                                  .filter(attachment => attachment._id !== attachmentId),
                              },
                            };
                          }
                          return override;
                        }),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case INCLUDE_DEFAULT_FORM_ITEMS: {
      const {
        batchId,
        itemId,
        includeTemplate,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        includeTemplate,
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case INCLUDE_DEFAULT_RATE_ITEMS: {
      const {
        batchId,
        itemId,
        includeTemplate,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        includeTemplate,
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case RESET_FORM_SCHEDULE_ITEM_OVERRIDE: {
      const {
        batchId,
        itemId,
        overrideId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      form: {
                        ...item.form,
                        overrides: item.form.overrides
                          .filter(override => override._id !== overrideId),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case RESET_RATE_SCHEDULE_ITEM_OVERRIDE: {
      const {
        batchId,
        itemId,
        overrideId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          batches: state.data.batches.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  if (item._id === itemId) {
                    return {
                      ...item,
                      rate: {
                        ...item.rate,
                        overrides: item.rate.overrides
                          .filter(override => override._id !== overrideId),
                      },
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case GET_FILING_DRAFT_ERROR: {
      return {
        isFetching: false,
        isSaving: false,
        data: {},
        error: action.payload,
      };
    }
    case SELECT_BATCH: {
      return {
        ...state,
        data: {
          ...state.data,
          selectedBatch: action.payload,
        },
      };
    }
    case SELECT_FILING_DRAFT: {
      return {
        ...state,
        data: {
          ...state.data,
          selectedFiling: action.payload,
        },
      };
    }
    case EDIT_FILING_DRAFT_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case GET_FILING_DRAFT_SNAPSHOT: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_FILING_DRAFT_SNAPSHOT_SUCCESS: {
      const { batchId, data } = action.payload;

      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          batches: state?.data?.batches?.map(batch => {
            if (batch._id === batchId) {
              return {
                ...batch,
                items: batch.items.map(item => {
                  const itemData = data.find(d => d.filingDraftItemId === item._id);
                  if (itemData) {
                    return {
                      ...item,
                      snapshot: itemData.snapshot,
                    };
                  }
                  return item;
                }),
              };
            }
            return batch;
          }),
        },
      };
    }
    case GET_FILING_DRAFT_SNAPSHOT_ERROR: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case INVALIDATE_FILING_DRAFT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
