import { notification } from 'antd';
import { ReactComponent as ReminderIcon } from '../../assets/reminderController.svg';
import ReminderAudio from '../../assets/mention.mp3';
import './ReminderNotification.scss';

const ReminderNotification = ({
  data,
  key,
  onClick,
}) => {
  if (!data) {
    return null;
  }

  const {
    subject,
    comment,
    objectId,
    objectType,
    meta,
  } = data;

  const audio = new Audio(ReminderAudio);
  audio.play();
  return notification.open({
    className: 'reminder-notification',
    description: (
      <div className="reminder-notification-content">
        <p> { subject } </p>
        <span>{ comment }</span>
      </div>
    ),
    icon: <ReminderIcon className="reminder-notification-icon" />,
    duration: 0,
    key,
    onClick: () => {
      if (objectType === 'FILING') {
        onClick(`/filing/${objectId}`);
      }

      if (objectType === 'OBJECTION' && meta.filingId) {
        onClick(`/objections/dashboard/${meta.filingId}/${objectId}`);
      }

      notification.close(key);
    },
  });
};

export default ReminderNotification;
