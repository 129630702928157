import {
  GET_SERFF_CONTACTS,
  GET_SERFF_CONTACTS_SUCCESS,
  GET_SERFF_CONTACTS_ERROR,
} from '../actions/serffContactsActions';

const initialValue = {
  items: {},
  isFetching: false,
  error: null,
};
export default function (state = initialValue, action) {
  switch (action.type) {
    case GET_SERFF_CONTACTS: {
      return {
        items: {},
        isFetching: true,
        error: null,
      };
    }
    case GET_SERFF_CONTACTS_SUCCESS: {
      const contacts = action.payload;

      if (!contacts) {
        return state;
      }

      const serffContacts = contacts.reduce((acc, num) => {
        acc[num._id] = num;
        return acc;
      }, {});

      return {
        items: serffContacts,
        isFetching: false,
        error: null,
      };
    }
    case GET_SERFF_CONTACTS_ERROR: {
      return {
        items: {},
        isFetching: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
