import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT_SUCCESS,
  EDIT_PROJECT_SUCCESS,
  REORDER_PROJECTS,
} from '../actions/projectsActions';

const initialState = {
  isLoading: false,
  error: '',
  projects: [],
  count: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS: {
      return {
        isLoading: true,
        error: '',
        projects: [],
        count: 0,
      };
    }
    case GET_PROJECTS_SUCCESS: {
      const { items, count } = action.payload;
      return {
        isLoading: false,
        error: '',
        projects: items,
        count,
      };
    }
    case GET_PROJECTS_ERROR: {
      const error = action.payload;
      return {
        isLoading: false,
        error: error && error.message,
        projects: [],
        count: 0,
      };
    }
    case CREATE_PROJECT_SUCCESS: {
      const project = action.payload;
      return {
        ...state,
        isLoading: false,
        error: '',
        projects: [...state.projects, project],
      };
    }
    case DELETE_PROJECT_SUCCESS: {
      const projectId = action.payload;
      return {
        ...state,
        isLoading: false,
        error: '',
        projects: state.projects.filter(project => project._id !== projectId),
      };
    }
    case REORDER_PROJECTS: {
      const { projectId, order } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: '',
        projects: state.projects.map(project => {
          if (project._id === projectId) {
            return {
              ...project,
              order,
            };
          }
          return project;
        }),
      };
    }
    case EDIT_PROJECT_SUCCESS: {
      const project = action.payload;
      return {
        ...state,
        isLoading: false,
        error: '',
        projects: state.projects.map(p => {
          if (p._id === project._id) {
            return project;
          }
          return p;
        }),
      };
    }
    default:
      return state;
  }
}
