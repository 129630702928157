import {
  CREATE_CUSTOM_FIELD,
  GET_CUSTOM_FIELDS,
  GET_CUSTOM_FIELDS_SUCCESS,
  GET_CUSTOM_FIELDS_ERROR,
  DELETE_CUSTOM_FIELD,
  EDIT_CUSTOM_FIELD,
} from '../actions/customFieldsActions';

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_CUSTOM_FIELD: {
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    }
    case DELETE_CUSTOM_FIELD: {
      return {
        ...state,
        data: state.data.filter(customField => customField._id !== action.payload),
      };
    }
    case EDIT_CUSTOM_FIELD: {
      const updatedField = action.payload;
      return {
        ...state,
        data: state.data.map(field => {
          if (field._id === updatedField._id) {
            return updatedField;
          }
          return field;
        }),
      };
    }
    case GET_CUSTOM_FIELDS: {
      return {
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_CUSTOM_FIELDS_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_CUSTOM_FIELDS_ERROR: {
      const { message } = action.payload;
      return {
        isFetching: false,
        data: [],
        error: message,
      };
    }
    default:
      return { ...state };
  }
}
