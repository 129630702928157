import { useState } from 'react';
import cs from 'classnames';
import { Tooltip } from 'antd';
import { ReactComponent as ChevronDown } from '../../../assets/chevron-down.svg';
import './NavItem.scss';

const NavItem = ({
  item,
  onClick,
  location,
  collapsed,
}) => {
  const {
    name,
    color,
    icon,
    url,
    sub,
    activeStates,
    unreadMentions,
  } = item;

  const isSubCollapsed = sub?.find(s => s?.activeStates.some(activeState => location.startsWith(activeState)))

  const [toggleSubNav, setToggleSubNav] = useState(isSubCollapsed);

  const navItemClasses = cs('nav-item-wrapper', {
    'nav-item-wrapper__collapsed': collapsed,
    'nav-item-wrapper__sub': sub && sub.length,
    'nav-item-wrapper__active': !activeStates
      ? location === url
      : activeStates && activeStates.some(activeState => location.startsWith(activeState)),
  });

  const subNavItemClasses = (subItem) => cs('sub-nav-item', {
    'sub-nav-item__active': !subItem.activeStates
      ? location === subItem.url
      : subItem.activeStates
      && subItem.activeStates.some(activeState => location.startsWith(activeState)),
  });

  const onNavigate = (e) => {
    if (sub && sub.length) {
      e.stopPropagation();
      setToggleSubNav(!toggleSubNav);
      return;
    }

    onClick(url);
  };

  const NavItemContent = (
    <div className="nav-item-content">
      <div
        className="nav-item-logo"
        style={{ color }}
      >
        { icon }
      </div>
      <span>{ name }</span>
      {
        unreadMentions ? (
          <Tooltip placement="top" title={`${unreadMentions} unread mention${unreadMentions > 1 ? 's' : ''}`}>
            <div className="sub-nav-item-unread-message" />
          </Tooltip>
        ) : null
      }
      {
        sub && sub.length ? (
          <ChevronDown
            className={
              toggleSubNav
                ? 'nav-item-chevron nav-item-chevron__opened'
                : 'nav-item-chevron'
            }
          />
        ) : null
      }
    </div>
  );

  return (
    <div className={navItemClasses} onClick={onNavigate}>
      {
        collapsed ? (
          <Tooltip
            title={name}
            placement="right"
            overlayClassName="sidebar-nav-item-tooltip"
          >
            { NavItemContent }
          </Tooltip>
        ) : NavItemContent
      }
      {
        sub && sub.length && toggleSubNav ? (
          <div className="sub-nav-item-wrapper">
            {
              sub.map(subItem => (
                <div
                  key={subItem.name}
                  className={subNavItemClasses(subItem)}
                  onClick={() => onClick(subItem.url)}
                >
                  { subItem.name }
                </div>
              ))
            }
          </div>
        ) : null
      }
    </div>
  );
};

export default NavItem;
