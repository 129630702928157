import {
  LOAD_SEARCH,
  LOAD_SEARCH_SUCCESS,
  LOAD_SEARCH_ERROR,
} from '../actions/searchActions';

const initialState = {
  isFetching: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_SEARCH:
      return {
        isFetching: true,
      };
    case LOAD_SEARCH_SUCCESS: {
      const { type, data } = action.payload;
      const { items, count } = data;
      return {
        [type]: {
          items,
          count,
        },
        isFetching: false,
      };
    }
    case LOAD_SEARCH_ERROR: {
      return {
        isFetching: false,
      };
    }
    default:
      return { ...state };
  }
}
