import axios from 'axios';
import {
  setAuthToken,
  URL,
} from './index';

export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';

export const getRoles = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_ROLES });
      const res = await axios.get(`${URL}/roles`, {
        params: {
          fields: ['_id', 'features', 'name', 'archived'],
        },
      });

      dispatch({
        type: GET_ROLES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_ROLES_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addRole = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/roles`, data);

      dispatch({
        type: ADD_ROLE_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editRole = (roleId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.patch(`${URL}/roles/${roleId}`, data);
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editRoleFeatures = (roleId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.patch(`${URL}/roles/${roleId}/features`, data);
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
