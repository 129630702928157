import React from 'react';
import { WarningOutlined, HomeOutlined } from '@ant-design/icons';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      return (
        <div className="error-boundary-container">
          <div className="error-boundary-wrapper">
            <div className="error-boundary-details-container">
              <div className="error-boundary-details-heading">
                <WarningOutlined />
                <div>
                  <h2>Oops, Something went wrong.</h2>
                  <p>
                    { error && error.toString() }
                  </p>
                </div>
              </div>
              <div className="error-boundary-details-body">
                <p>
                  Don't worry, we already know about
                  this issue and we are working to resolve it
                </p>
                <a href="/">
                  <HomeOutlined />
                  Go Back Home
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
