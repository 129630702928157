import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/event`;

export const GET_INTEGRATIONS = 'GET_INTEGRATIONS';
export const GET_INTEGRATIONS_SUCCESS = 'GET_INTEGRATIONS_SUCCESS';
export const GET_INTEGRATIONS_ERROR = 'GET_INTEGRATIONS_ERROR';

export const ADD_INTEGRATION_SUCCESS = 'ADD_INTEGRATION_SUCCESS';
export const EDIT_INTEGRATION_SUCCESS = 'EDIT_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';

export const EDIT_TEAMS_CHANNEL = 'EDIT_TEAMS_CHANNEL';

export const getIntegrations = (type) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_INTEGRATIONS });
    try {
      const res = await axios.get(`${URL}/integrations-settings`, { params: { type } });
      dispatch({
        type: GET_INTEGRATIONS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_INTEGRATIONS_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const addIntegration = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/integrations-settings`, data);
      dispatch({
        type: ADD_INTEGRATION_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editIntegration = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/integrations-settings/${id}`, data);
      dispatch({
        type: EDIT_INTEGRATION_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editTeamsChannel = ({
  integrationId,
  channelId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/integrations-settings/${integrationId}/teams/channels/${channelId}`, data);
      dispatch({
        type: EDIT_TEAMS_CHANNEL,
        payload: {
          integrationId,
          channelId,
          data: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteIntegration = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/integrations-settings/${id}`);
      dispatch({
        type: DELETE_INTEGRATION_SUCCESS,
        payload: id,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getSlackUrl = (callbackUrl) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${URL}/integrations/slack/installation-url`, { params: { callbackUrl } });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getTeamsUrl = (callbackUrl) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${URL}/integrations/teams/installation-url`, { params: { callbackUrl } });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editTeamsChannelSettings = ({
  integrationId,
  channelId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/integrations-settings/${integrationId}/teams/channels/${channelId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const authenticateMozartIntegration = (integrationId) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/integrations-settings/${integrationId}/mozart/test`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
