import {
  GET_AMENDMENT_DRAFTS,
  GET_AMENDMENT_DRAFTS_SUCCESS,
  GET_AMENDMENT_DRAFTS_ERROR,
  ADD_AMENDMENT_DRAFT,
  EDIT_AMENDMENT_DRAFT,
  DELETE_AMENDMENT_DRAFT,
  ADD_AMENDMENT_DRAFT_FORM,
  EDIT_AMENDMENT_DRAFT_FORM,
  DELETE_AMENDMENT_DRAFT_FORM,
  ADD_AMENDMENT_DRAFT_FORM_ATTACHMENT,
  DELETE_AMENDMENT_DRAFT_FORM_ATTACHMENT,
  ADD_AMENDMENT_DRAFT_RATE,
  EDIT_AMENDMENT_DRAFT_RATE,
  DELETE_AMENDMENT_DRAFT_RATE,
  ADD_AMENDMENT_DRAFT_RATE_ATTACHMENT,
  DELETE_AMENDMENT_DRAFT_RATE_ATTACHMENT,
  ADD_AMENDMENT_DRAFT_SUPPORTING,
  EDIT_AMENDMENT_DRAFT_SUPPORTING,
  DELETE_AMENDMENT_DRAFT_SUPPORTING,
  ADD_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT,
  DELETE_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT,
  INVALIDATE_AMENDMENT_DRAFTS,
} from '../actions/amendmentDraftsActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AMENDMENT_DRAFTS: {
      return {
        isFetched: false,
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_AMENDMENT_DRAFTS_SUCCESS: {
      return {
        isFetched: true,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_AMENDMENT_DRAFTS_ERROR: {
      return {
        isFetched: true,
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    case DELETE_AMENDMENT_DRAFT: {
      return {
        isFetched: true,
        isFetching: false,
        data: state.data
          .filter(amendmentDraft => amendmentDraft._id !== action.payload),
        error: null,
      };
    }
    case ADD_AMENDMENT_DRAFT: {
      return {
        isFetched: true,
        isFetching: false,
        data: [...state.data, action.payload],
        error: null,
      };
    }
    case EDIT_AMENDMENT_DRAFT: {
      const amendmentDraft = action.payload;
      return {
        isFetched: true,
        isFetching: false,
        data: state.data.map(amendment => {
          if (amendment._id === amendmentDraft._id) {
            return amendmentDraft;
          }
          return amendment;
        }),
        error: null,
      };
    }
    case INVALIDATE_AMENDMENT_DRAFTS: {
      return {
        isFetched: false,
        isFetching: false,
        data: [],
        error: null,
      };
    }
    case ADD_AMENDMENT_DRAFT_FORM: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              forms: [...amendmentDraft.forms, data],
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case EDIT_AMENDMENT_DRAFT_FORM: {
      const {
        id,
        formId,
        data,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              forms: amendmentDraft.forms.map(form => {
                if (formId === form._id) {
                  return data;
                }
                return form;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case DELETE_AMENDMENT_DRAFT_FORM: {
      const {
        id,
        formId,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              forms: amendmentDraft.forms.filter(form => form._id !== formId),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case ADD_AMENDMENT_DRAFT_FORM_ATTACHMENT: {
      const { id, formId, attachment } = action.payload;
      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              forms: amendmentDraft.forms.map(form => {
                if (formId === form._id) {
                  return {
                    ...form,
                    attachments: [...form.attachments, attachment],
                  };
                }
                return form;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case DELETE_AMENDMENT_DRAFT_FORM_ATTACHMENT: {
      const { id, formId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              forms: amendmentDraft.forms.map(form => {
                if (formId === form._id) {
                  return {
                    ...form,
                    attachments: form.attachments
                      .filter(attachment => attachment._id !== attachmentId),
                  };
                }
                return form;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case ADD_AMENDMENT_DRAFT_RATE: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              rates: [...amendmentDraft.rates, data],
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case EDIT_AMENDMENT_DRAFT_RATE: {
      const {
        id,
        rateId,
        data,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              rates: amendmentDraft.rates.map(rate => {
                if (rateId === rate._id) {
                  return data;
                }
                return rate;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case DELETE_AMENDMENT_DRAFT_RATE: {
      const {
        id,
        rateId,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              rates: amendmentDraft.rates.filter(rate => rate._id !== rateId),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case ADD_AMENDMENT_DRAFT_RATE_ATTACHMENT: {
      const { id, rateId, attachment } = action.payload;
      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              rates: amendmentDraft.rates.map(rate => {
                if (rateId === rate._id) {
                  return {
                    ...rate,
                    attachments: [...rate.attachments, attachment],
                  };
                }
                return rate;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case DELETE_AMENDMENT_DRAFT_RATE_ATTACHMENT: {
      const { id, rateId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              rates: amendmentDraft.rates.map(rate => {
                if (rateId === rate._id) {
                  return {
                    ...rate,
                    attachments: rate.attachments
                      .filter(attachment => attachment._id !== attachmentId),
                  };
                }
                return rate;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case ADD_AMENDMENT_DRAFT_SUPPORTING: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              supportings: [...amendmentDraft.supportings, data],
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case EDIT_AMENDMENT_DRAFT_SUPPORTING: {
      const {
        id,
        supportingId,
        data,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              supportings: amendmentDraft.supportings.map(supporting => {
                if (supportingId === supporting._id) {
                  return data;
                }
                return supporting;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case DELETE_AMENDMENT_DRAFT_SUPPORTING: {
      const {
        id,
        supportingId,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              supportings: amendmentDraft.supportings
                .filter(supporting => supporting._id !== supportingId),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case ADD_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT: {
      const { id, supportingId, attachment } = action.payload;
      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              supportings: amendmentDraft.supportings.map(supporting => {
                if (supportingId === supporting._id) {
                  return {
                    ...supporting,
                    attachments: [...supporting.attachments, attachment],
                  };
                }
                return supporting;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    case DELETE_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT: {
      const { id, supportingId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(amendmentDraft => {
          if (amendmentDraft._id === id) {
            return {
              ...amendmentDraft,
              supportings: amendmentDraft.supportings.map(supporting => {
                if (supportingId === supporting._id) {
                  return {
                    ...supporting,
                    attachments: supporting.attachments
                      .filter(attachment => attachment._id !== attachmentId),
                  };
                }
                return supporting;
              }),
            };
          }
          return amendmentDraft;
        }),
      };
    }
    default:
      return { ...state };
  }
};
