import { useEffect, useState } from 'react';
import { useScrollPosition } from '../../helpers/hooks';
import EventEmitter from '../../helpers/eventEmitter';
import history from '../../helpers/history';

const locationsList = ['/products', '/project', '/projects'];

export default () => {
  const [locationKeys, setLocationKeys] = useState([]);
  const [positionRestoring, setScrollPosition] = useState(false);
  const getScrollPosition = () => parseInt(sessionStorage.getItem(window.location.pathname), 10) || 0;

  const scrollToPosition = (position, page) => {
    if (page === '/products') {
      setTimeout(() => {
        window.scrollTo(0, position);
      });
      return;
    }

    window.scrollTo(0, position);
  };

  const handleScroll = (data) => {
    const scrollPosition = getScrollPosition();
    if (data.page === window.location.pathname) {
      scrollToPosition(scrollPosition, data.page);

      setTimeout(() => {
        EventEmitter.unsubscribe('dataFetched', handleScroll);
        setScrollPosition(false);
      }, 500);
    } else {
      setScrollPosition(false);
    }
  };

  useEffect(() => history.listen(({ action, location }) => {
    if (action === 'PUSH') {
      // forward event
      setLocationKeys([location.key]);
      window.scrollTo(0, 0);
      EventEmitter.unsubscribe('dataFetched', handleScroll);
    } else if (action === 'POP') {
      if (locationKeys[1] === location.key) {
        // forward event
        setLocationKeys(([_, ...keys]) => keys);
        window.scrollTo(0, 0);
        EventEmitter.unsubscribe('dataFetched', handleScroll);
      } else {
        // backward event
        setScrollPosition(true);
        EventEmitter.subscribe('dataFetched', handleScroll);
        if (locationKeys.includes(location.key)) {
          return;
        }
        setLocationKeys((keys) => [location.key, ...keys]);
      }
    }
  }), [locationKeys]);

  useScrollPosition((pos) => {
    if (
      !positionRestoring
     && locationsList.some((loc) => window.location.pathname.includes(loc))
    ) {
      sessionStorage.setItem(window.location.pathname, pos.y);
    }
  }, 300);

  return null;
};
