import axios from 'axios';
import { setAuthToken } from './index';
import { GLOBAL_SEARCH_TYPE } from '../enums';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/search`;

export const LOAD_SEARCH = 'LOAD_SEARCH';
export const LOAD_SEARCH_SUCCESS = 'LOAD_SEARCH_SUCCESS';
export const LOAD_SEARCH_ERROR = 'LOAD_SEARCH_ERROR';

export const searchAttachments = ({
  text,
  type,
  limit,
  offset,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_SEARCH });
      const res = await axios.get(`${URL}`, {
        params: {
          text,
          type,
          limit: limit || 25,
          offset: offset || 0,
        },
      });
      dispatch({
        type: LOAD_SEARCH_SUCCESS,
        payload: {
          type: GLOBAL_SEARCH_TYPE.ATTACHMENT,
          data: res.data,
        },
      });
    } catch ({ response }) {
      dispatch({
        type: LOAD_SEARCH_ERROR,
      });
      throw response && response.data;
    }
  };
};

export const searchFilings = ({
  text,
  limit,
  offset,
  sortKey,
  sortDirection,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_SEARCH });
      const res = await axios.post(`${URL}/filings`, {
        ...text && { text },
        limit,
        offset,
        sortKey,
        sortDirection,
      });
      dispatch({
        type: LOAD_SEARCH_SUCCESS,
        payload: {
          type: GLOBAL_SEARCH_TYPE.FILING,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: LOAD_SEARCH_ERROR,
      });
      throw response.data;
    }
  };
};
