export default ['/',
  'login',
  'user/forgot-password',
  'forgot-password',
  'azure/auth',
  'saml/auth',
  'filing-drafts/new',
  'filing-draft/:filingDraftId',
  'organizer',
  'documentation',
];
