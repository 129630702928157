import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from './hooks';
import { currentUser, hasFilingDraftsFeature } from '../selectors/selectors';
import { usePermissions } from '../services/permissionsService';

export default function (Component) {
  const RequireFilingDraftsFeature = ({ hasFilingDrafts, user, navigate }) => {
    const { firstAccessibleUrl } = usePermissions();

    useEffect(() => {
      if (user !== undefined && !hasFilingDrafts) {
        navigate(firstAccessibleUrl);
      }
    }, [user, hasFilingDrafts, firstAccessibleUrl]);

    return (
      <Component />
    );
  };

  const mapStateToProps = (state) => ({
    hasFilingDrafts: hasFilingDraftsFeature(state),
    user: currentUser(state),
  });

  return connect(mapStateToProps)(withRouter(RequireFilingDraftsFeature));
}
