import axios from 'axios';
import { setAuthToken } from './index';

const LIBRARY_URL = `${process.env.REACT_APP_API_ENDPOINT}/library`;

export const GET_LIBRARY_FORM = 'GET_LIBRARY_FORM';
export const GET_LIBRARY_FORM_SUCCESS = 'GET_LIBRARY_FORM_SUCCESS';
export const GET_LIBRARY_FORM_ERROR = 'GET_LIBRARY_FORM_ERROR';

export const ADD_LIBRARY_FORM_ATTACHMENT = 'ADD_LIBRARY_FORM_ATTACHMENT';
export const EDIT_LIBRARY_FORM_ATTACHMENT = 'EDIT_LIBRARY_FORM_ATTACHMENT';
export const DELETE_LIBRARY_FORM_ATTACHMENT = 'DELETE_LIBRARY_FORM_ATTACHMENT';

export const ADD_LIBRARY_FORM = 'ADD_LIBRARY_FORM';
export const EDIT_LIBRARY_FORM = 'EDIT_LIBRARY_FORM';

export const ADD_LIBRARY_FORM_STATUS = 'ADD_LIBRARY_FORM_STATUS';
export const EDIT_LIBRARY_FORM_STATUS = 'EDIT_LIBRARY_FORM_STATUS';
export const CLONE_LIBRARY_FORM_STATUS = 'CLONE_LIBRARY_FORM_STATUS';

export const ADD_LIBRARY_FORM_CUSTOM_FIELD = 'ADD_LIBRARY_FORM_CUSTOM_FIELD';
export const EDIT_LIBRARY_FORM_CUSTOM_FIELD = 'EDIT_LIBRARY_FORM_CUSTOM_FIELD';
export const DELETE_LIBRARY_FORM_CUSTOM_FIELD = 'DELETE_LIBRARY_FORM_CUSTOM_FIELD';

export const getLibraryForm = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_LIBRARY_FORM });
      const res = await axios.get(`${LIBRARY_URL}/library-forms/${id}`);
      dispatch({
        type: GET_LIBRARY_FORM_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_LIBRARY_FORM_ERROR,
      });
      throw response.data;
    }
  };
};

export const getLibraryFormMap = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-forms/${id}/map`, {
        params: { archived: false }
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryFormAttachment = ({ formId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-forms/${formId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_LIBRARY_FORM_ATTACHMENT,
        payload: {
          formId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormAttachment = ({
  formId,
  attachmentId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-forms/${formId}/attachments/${attachmentId}`, data);
      dispatch({
        type: EDIT_LIBRARY_FORM_ATTACHMENT,
        payload: {
          formId,
          attachmentId,
          data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryForm = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms`, data);
      dispatch({
        type: ADD_LIBRARY_FORM,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormAttachment = ({ formId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms/${formId}/attachments`, attachment);
      dispatch({
        type: ADD_LIBRARY_FORM_ATTACHMENT,
        payload: {
          formId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryForm = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-forms/${id}`, data);
      dispatch({
        type: EDIT_LIBRARY_FORM,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormStatus = (id, statusId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-forms/${id}/statuses/${statusId}`, data);
      dispatch({
        type: EDIT_LIBRARY_FORM_STATUS,
        payload: {
          formId: id,
          formStatusId: statusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const cloneLibraryFormStatus = ({ formId, statusId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms/${formId}/statuses/${statusId}/clone`);
      dispatch({
        type: CLONE_LIBRARY_FORM_STATUS,
        payload: {
          formId,
          statusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormStatus = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms/${id}/statuses`, data);
      dispatch({
        type: ADD_LIBRARY_FORM_STATUS,
        payload: {
          formId: id,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryFormStatusCustomField = ({
  formId,
  formStatusId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-forms/${formId}/statuses/${formStatusId}/custom-fields`, data);
      dispatch({
        type: ADD_LIBRARY_FORM_CUSTOM_FIELD,
        payload: {
          formId,
          formStatusId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryFormStatusCustomField = ({
  formId,
  formStatusId,
  customFieldRecordId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-forms/${formId}/statuses/${formStatusId}/custom-fields/${customFieldRecordId}`, data);
      dispatch({
        type: EDIT_LIBRARY_FORM_CUSTOM_FIELD,
        payload: {
          formId,
          formStatusId,
          customFieldRecordId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryFormStatusCustomField = ({
  formId,
  formStatusId,
  customFieldRecordId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-forms/${formId}/statuses/${formStatusId}/custom-fields/${customFieldRecordId}`);
      dispatch({
        type: DELETE_LIBRARY_FORM_CUSTOM_FIELD,
        payload: {
          formId,
          formStatusId,
          customFieldRecordId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};
