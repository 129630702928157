import {
  GET_FILING_DRAFTS,
  GET_FILING_DRAFTS_CHUNK,
  GET_FILING_DRAFTS_SUCCESS,
  GET_FILING_DRAFTS_ERROR,
  ARCHIVE_FILING_DRAFT_SUCCESS,
  REPLACE_FILING_DRAFTS_SUCCESS,
} from '../actions/filingDraftsActions';

const initialState = {
  isFetching: false,
  isFetchingChunk: false,
  data: [],
  error: null,
  movedItemId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILING_DRAFTS: {
      return {
        isFetching: true,
        isFetchingChunk: false,
        data: [],
        error: null,
        movedItemId: null,
      };
    }
    case GET_FILING_DRAFTS_CHUNK: {
      return {
        ...state,
        isFetchingChunk: true,
      };
    }
    case GET_FILING_DRAFTS_SUCCESS: {
      const { firstLoad, list } = action.payload;
      const data = firstLoad ? list : [...state.data, ...list];
      return {
        isFetching: false,
        isFetchingChunk: false,
        data,
        error: null,
        movedItemId: null,
      };
    }
    case REPLACE_FILING_DRAFTS_SUCCESS: {
      const { list, offset } = action.payload;

      const data = [...state.data.slice(0, offset), ...list];

      return {
        ...state,
        data,
      };
    }
    case GET_FILING_DRAFTS_ERROR: {
      return {
        isFetching: false,
        isFetchingChunk: false,
        data: [],
        error: action.payload,
        movedItemId: null,
      };
    }
    case ARCHIVE_FILING_DRAFT_SUCCESS: {
      const { id } = action.payload;

      return {
        ...state,
        data: state.data.filter(item => item.id !== id),
      };
    }
    default: {
      return state;
    }
  }
}
