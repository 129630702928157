import axios from 'axios';
import { setAuthToken, URL } from './index';

export const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD';
export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export const EDIT_CUSTOM_FIELD = 'EDIT_CUSTOM_FIELD';

export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS';
export const GET_CUSTOM_FIELDS_SUCCESS = 'GET_CUSTOM_FIELDS_SUCCESS';
export const GET_CUSTOM_FIELDS_ERROR = 'GET_CUSTOM_FIELDS_ERROR';

export const createCustomField = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/custom-fields`, data);
      dispatch({ type: CREATE_CUSTOM_FIELD, payload: res.data });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getFilingFormCustomFields = () => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${URL}/custom-fields`, {
        params: {
          archived: false,
          type: 'FILING_FORM',
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getFilingRateCustomFields = () => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${URL}/custom-fields`, {
        params: {
          archived: false,
          type: 'FILING_RATE',
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getCustomFields = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_CUSTOM_FIELDS });
    try {
      const res = await axios.get(`${URL}/custom-fields`, {
        params: {
          archived: false,
        },
      });
      dispatch({
        type: GET_CUSTOM_FIELDS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_CUSTOM_FIELDS_ERROR,
        payload: response && response.data,
      });
      throw response.data;
    }
  };
};

export const deleteCustomField = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/custom-fields/${id}`);
      dispatch({ type: DELETE_CUSTOM_FIELD, payload: id });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editCustomField = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/custom-fields/${id}`, { ...data });
      dispatch({ type: EDIT_CUSTOM_FIELD, payload: res.data });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};
