import axios from 'axios';
import qs from 'query-string';
import {
  authenticateChat,
  closeConnection,
  initializeChatListeners,
  initializeSocket,
} from './chatActions';
import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  setAuthToken,
  URL,
} from './index';
import { getCompanies } from './companiesActions';
import { getUsers } from './usersActions';

export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const AUTHENTICATION = 'AUTHENTICATION';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_REMOVED = 'AUTHENTICATION_REMOVED';

export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const RESET_AUTHENTICATION_ERROR = 'RESET_AUTHENTICATION_ERROR';

const setUserDetails = (data) => {
  if (data && data.user) {
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('scope', data.scope);
    localStorage.setItem('issuedAt', data.issuedAt);
    localStorage.setItem('expiresIn', data.expiresIn);
  }
};

export const getUserAuthType = (username) => async (dispatch) => {
  try {
    dispatch({ type: RESET_AUTHENTICATION_ERROR });
    const res = await axios.get(`${URL}/users/account/auth-type`, {
      params: {
        username,
      },
    });
    return res.data;
  } catch ({ response }) {
    dispatch({
      type: AUTHENTICATION_ERROR,
      payload: response && response.data,
    });
    throw response && response.data;
  }
};

export const getCurrentUser = (onSuccess, onError) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_CURRENT_USER });
      const res = await axios.get(`${URL}/users/current/`, {
        params: {
          embeds: ['accountId', 'roles'],
        },
      });
      dispatch({
        type: GET_CURRENT_USER_SUCCESS,
        payload: res.data,
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      localStorage.clear();
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: error.response,
      });
      if (onError) {
        onError(error.response);
      }
    }
  };
};

export const resetError = () => (dispatch) => {
  dispatch({
    type: RESET_AUTHENTICATION_ERROR,
  });
};

export const dispatchError = (payload) => (dispatch) => {
  dispatch({
    payload,
    type: AUTHENTICATION_ERROR,
  });
};

export function signIn({ email, password }) {
  localStorage.clear();
  axios.defaults.headers.common.Authorization = '';
  delete axios.defaults.headers.common.Authorization;
  const signInConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      authorization: 'Basic NXJxaDFkcXZrN3JmdDdpYjZlaDBhbHY1bXUydjRmOkpKdW00NldvV2NNeW5vVTFDV2xraEw=',
    },
  };

  return async (dispatch) => {
    try {
      dispatch({ type: AUTHENTICATION });
      const res = await axios.post(
        `${URL}/auth/authentication/local`,
        qs.stringify({
          username: email,
          password,
        }),
        signInConfig,
      );

      setAuthToken(res.data.accessToken);
      setUserDetails(res.data);
      await dispatch(getCurrentUser());
      await dispatch(getUsers());
      dispatch(getCompanies());
      dispatch(initializeSocket());
      dispatch(initializeChatListeners());
      dispatch(authenticateChat());
      dispatch({
        type: AUTHENTICATION_SUCCESS,
        payload: {
          email,
        },
      });
    } catch ({ response }) {
      dispatch({
        type: AUTHENTICATION_ERROR,
        payload: (response && response.data) || {
          message: 'Server is not responding. This may be a temporary glitch or the server may be down',
        },
      });
      throw response.data;
    }
  };
}

export function signOutAction() {
  localStorage.clear();
  axios.defaults.headers.common.Authorization = '';
  delete axios.defaults.headers.common.Authorization;

  return async (dispatch) => {
    dispatch(closeConnection());
    dispatch({ type: AUTHENTICATION_REMOVED });
    dispatch({ type: RESET_CURRENT_USER });
  };
}
