import {
  GET_SERFF_COMPANIES,
  GET_SERFF_COMPANIES_SUCCESS,
  GET_SERFF_COMPANIES_ERROR,
} from '../actions/serffCompaniesActions';

const initialValue = {
  items: {},
  isFetching: false,
  error: null,
};
export default function (state = initialValue, action) {
  switch (action.type) {
    case GET_SERFF_COMPANIES: {
      return {
        items: {},
        isFetching: true,
        error: null,
      };
    }
    case GET_SERFF_COMPANIES_SUCCESS: {
      const companies = action.payload;

      if (!companies) {
        return state;
      }

      const serffCompanies = companies.reduce((acc, num) => {
        acc[num._id] = num;
        return acc;
      }, {});

      return {
        items: serffCompanies,
        isFetching: false,
        error: null,
      };
    }
    case GET_SERFF_COMPANIES_ERROR: {
      return {
        items: {},
        isFetching: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
