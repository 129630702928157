import {
  GET_SERFF_AUTHORS,
  GET_SERFF_AUTHORS_SUCCESS,
  GET_SERFF_AUTHORS_ERROR,
} from '../actions/serffAuthorsActions';

const initialValue = {
  items: {},
  isFetching: false,
  error: null,
};
export default function (state = initialValue, action) {
  switch (action.type) {
    case GET_SERFF_AUTHORS: {
      return {
        items: {},
        isFetching: true,
        error: null,
      };
    }
    case GET_SERFF_AUTHORS_SUCCESS: {
      const authors = action.payload;

      if (!authors) {
        return state;
      }

      const serffAuthors = authors.reduce((acc, num) => {
        acc[num._id] = num;
        return acc;
      }, {});

      return {
        items: serffAuthors,
        isFetching: false,
        error: null,
      };
    }
    case GET_SERFF_AUTHORS_ERROR: {
      return {
        items: {},
        isFetching: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
