import { Navigate } from 'react-router-dom';
import { usePermissions } from '../services/permissionsService';

const Redirect = () => {
  const { firstAccessibleUrl } = usePermissions();

  return (
    <Navigate
      from="/*"
      to={firstAccessibleUrl}
    />
  );
};

export default Redirect;
