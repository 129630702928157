import axios from 'axios';
import { setAuthToken, URL } from './index';

export const UPDATE_CURRENT_USER_ACCOUNT_FEATURES = 'UPDATE_CURRENT_USER_ACCOUNT_FEATURES';
export const UPDATE_CURRENT_USER_ACCOUNT = 'UPDATE_CURRENT_USER_ACCOUNT';
export const UPDATE_CURRENT_USER_ACCOUNT_SAML_CONFIG = 'UPDATE_CURRENT_USER_ACCOUNT_SAML_CONFIG';
export const UPDATE_CURRENT_USER_ACCOUNT_PROJECT_NUMBER_GENERATOR_SETTINGS = 'UPDATE_CURRENT_USER_ACCOUNT_PROJECT_NUMBER_GENERATOR_SETTINGS';

export function updateAccountFeatures(feature) {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/accounts/current/features`, feature);
      dispatch({
        type: UPDATE_CURRENT_USER_ACCOUNT_FEATURES,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const updateAccountProjectNumberGeneratorSettings = (feature) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/accounts/current/project-number-generator-settings`, feature);
      dispatch({
        type: UPDATE_CURRENT_USER_ACCOUNT_PROJECT_NUMBER_GENERATOR_SETTINGS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export function updateAccount(data) {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/accounts/current`, data);
      dispatch({
        type: UPDATE_CURRENT_USER_ACCOUNT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const updateSamlConfig = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/accounts/current/auth/saml`, data);
      dispatch({
        type: UPDATE_CURRENT_USER_ACCOUNT_SAML_CONFIG,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const uploadSamlXMLConfig = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/accounts/current/auth/saml/import`, data);
      dispatch({
        type: UPDATE_CURRENT_USER_ACCOUNT_SAML_CONFIG,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
