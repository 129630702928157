import './Loader.scss';

const Loader = ({ size = 50, text }) => (
  <div className="r-loader-wrapper">
    <svg className="r-circular-loader" viewBox="25 25 50 50" width={size} height={size}>
      <circle className="r-loader-path" cx="50" cy="50" r="20" fill="none" strokeWidth="4" />
    </svg>
    {
      text ? (
        <span>
          { text }
        </span>
      ) : null
    }
  </div>
);

export default Loader;
