import { Navigate } from 'react-router-dom';
import { usePermissions } from '../services/permissionsService';

const Home = ({ isAuthChecked }) => {
  const { firstAccessibleUrl } = usePermissions();

  return isAuthChecked
    ? <Navigate to={firstAccessibleUrl} replace />
    : <Navigate to="/login" replace />;
};

export default Home;
