import {
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  GET_USERS,
  ADD_USER_CUSTOM_ROLE,
  REMOVE_USER_CUSTOM_ROLE,
} from '../actions/usersActions';

export default function (state = [], action) {
  switch (action.type) {
    case GET_USERS:
      return action.payload;
    case CREATE_USER_SUCCESS: {
      const user = action.payload;
      return [
        ...state,
        user,
      ];
    }
    case EDIT_USER_SUCCESS: {
      const user = action.payload;
      return state
        .map((u) => (u.id === user.id ? user : u));
    }
    case DELETE_USER_SUCCESS: {
      const userId = action.payload;
      return state.filter(({ id }) => id !== userId);
    }
    case ADD_USER_CUSTOM_ROLE: {
      const { userId, roleId } = action.payload;

      return state.map(user => {
        if (user._id === userId) {
          return {
            ...user,
            roles: [...user.roles, roleId],
          };
        }
        return user;
      });
    }
    case REMOVE_USER_CUSTOM_ROLE: {
      const { userId, roleId } = action.payload;

      return state.map(user => {
        if (user._id === userId) {
          return {
            ...user,
            roles: user.roles.filter(id => id !== roleId),
          };
        }
        return user;
      });
    }
    default:
      return state;
  }
}
