import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_FORMS_DETAILS = 'GET_FORMS_DETAILS';
export const GET_FORMS_DETAILS_SUCCESS = 'GET_FORMS_DETAILS_SUCCESS';
export const GET_FORMS_DETAILS_ERROR = 'GET_FORMS_DETAILS_ERROR';

export const getFormDetails = (formNumber, projectId) => {
  setAuthToken(localStorage.getItem('token'));
  return async (dispatch) => {
    dispatch({ type: GET_FORMS_DETAILS });
    try {
      const res = await axios.post(`${URL}/forms-library/search`, {
        formNumber,
        projectId,
      });
      dispatch({
        type: GET_FORMS_DETAILS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_FORMS_DETAILS_ERROR,
        payload: response && response.data && response.data.message,
      });
    }
  };
};
