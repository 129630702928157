import { Manager } from 'socket.io-client';
import {
  addMessage,
  updateMessage,
  emitIsTyping,
  fetchUnreadMentions,
  markUnread,
  fetchMessages,
} from './chatApi';
import MentionNotification from '../components/MentionNotification';
import ReminderNotification from '../components/ReminderNotification';
import { parseMention } from '../components/Chat/ChatService';

let socket = null;
let manager = null;

export const AUTHENTICATE_CHAT = 'AUTHENTICATE_CHAT';
export const AUTHENTICATE_CHAT_SUCCESS = 'AUTHENTICATE_CHAT_SUCCESS';
export const AUTHENTICATE_CHAT_ERROR = 'AUTHENTICATE_CHAT_ERROR';

export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECT_ERROR = 'SOCKET_CONNECT_ERROR';
export const SOCKET_CONNECT_SUCCESS = 'SOCKET_CONNECT_SUCCESS';

export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';

export const SOCKET_RECONNECT = 'SOCKET_RECONNECT';
export const SOCKET_RECONNECT_SUCCESS = 'SOCKET_RECONNECT_SUCCESS';
export const SOCKET_RECONNECT_ERROR = 'SOCKET_RECONNECT_ERROR';

export const DISCONNECT_CHAT = 'DISCONNECT_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const GET_SUBSCRIPTION_MESSAGES = 'GET_SUBSCRIPTION_MESSAGES';
export const GET_SUBSCRIPTION_MESSAGES_SUCCESS = 'GET_SUBSCRIPTION_MESSAGES_SUCCESS';
export const GET_UNREAD_MESSAGES = 'GET_UNREAD_MESSAGES';

export const RECEIVE_TYPING = 'RECEIVE_TYPING';
export const TOGGLE_CHAT = 'TOGGLE_CHAT';

const initializeSocket = () => () => {
  manager = new Manager(`${process.env.REACT_APP_MESSAGING_WS_ENDPOINT}`, {
    path: '/v1/messaging-socket',
  });
  socket = manager.socket('/chat');
};

const authenticateChat = () => (dispatch) => {
  dispatch({
    type: AUTHENTICATE_CHAT,
  });
  socket.emit('authentication', {
    authorization: localStorage.getItem('token'),
  });
};

const closeConnection = () => (dispatch) => {
  socket.disconnect();
  dispatch({ type: DISCONNECT_CHAT });
};

const getUnreadMentions = () => async (dispatch) => {
  try {
    const res = await fetchUnreadMentions();
    dispatch({
      type: GET_UNREAD_MESSAGES,
      payload: res && res.data,
    });
  } catch ({ response }) {
    throw response && response.data;
  }
};

const triggerNotification = ({
  user,
  payload,
  dispatch,
  location,
  navigate,
}) => {
  if (!payload) {
    return;
  }

  const {
    mentions,
    // isEdited,
    content,
    from,
    meta,
    objectId,
    objectType,
    _id,
  } = payload;

  // if (isEdited) {
  //   return;
  // }

  if (!(
    user
      && user.data
      && user.data._id
      && mentions
      && mentions.length
      && mentions.find(mention => mention.user === user.data._id)
  )) {
    return;
  }

  getUnreadMentions()(dispatch);

  if (objectType === 'OBJECTION' && meta && meta.filingId && location.pathname.includes(`/objections/dashboard/${meta.filingId}/${objectId}`)) {
    return;
  }

  if (objectType === 'FILING' && location.pathname.includes(`/filing/${objectId}`)) {
    return;
  }

  if (objectType === 'FILING_FORM' && location.pathname.includes('/filing')) {
    return;
  }

  if (objectType === 'FILING_RATE' && location.pathname.includes('/filing')) {
    return;
  }

  if (objectType === 'PROJECT' && location.pathname.includes('/project')) {
    return;
  }

  if (objectType === 'FILING_DRAFT' && location.pathname.includes('/filing-draft')) {
    return;
  }

  const { firstName, lastName } = from;

  getUnreadMentions()(dispatch);
  MentionNotification({
    key: _id,
    mention: `${firstName} ${lastName}`,
    message: parseMention(content, user.data._id),
    onClick: () => {
      if (!meta) {
        return;
      }

      const { filingId, projectId, filingDraftId } = meta;

      if (objectId && filingId && objectType === 'OBJECTION') {
        navigate(`/objections/dashboard/${filingId}/${objectId}`);
      }

      if (objectId && projectId && objectType === 'PROJECT') {
        navigate(`/project/${projectId}`);
      }

      if (objectId && filingDraftId && objectType === 'FILING_DRAFT') {
        navigate(`/filing-draft/${filingDraftId}`);
      }

      if (objectId && filingId && (objectType === 'FILING' || objectType === 'FILING_FORM' || objectType === 'FILING_RATE')) {
        navigate(`/filing/${filingId}`);
      }
    },
  });
};

const initializeChatListeners = (navigate, location) => (dispatch, getState) => {
  socket.on('reminder', (data) => {
    if (data) {
      ReminderNotification({
        key: data._id,
        data,
        onClick: (link) => navigate(link),
      });
    }
  });

  socket.on('authenticated', () => {
    dispatch({ type: AUTHENTICATE_CHAT_SUCCESS });
  });

  socket.on('unauthorized', (error) => {
    const payload = {
      error: error.message,
    };
    dispatch({ type: AUTHENTICATE_CHAT_ERROR, payload });
  });

  socket.on('message-edit', (payload) => {
    const { user } = getState();
    dispatch({ type: EDIT_MESSAGE, payload });
    triggerNotification({
      user,
      payload,
      dispatch,
      location,
      navigate,
    });
  });

  socket.on('message', (payload) => {
    const { user } = getState();
    dispatch({ type: RECEIVE_MESSAGE, payload });
    triggerNotification({
      user,
      payload,
      dispatch,
      location,
      navigate,
    });
  });

  socket.on('typing', (payload) => {
    dispatch({ type: RECEIVE_TYPING, payload });
  });

  socket.on('connect', () => {
    dispatch({ type: SOCKET_CONNECT_SUCCESS });
  });

  socket.on('connect_error', (error) => {
    dispatch({
      type: SOCKET_CONNECT_ERROR,
      payload: error,
    });
  });

  socket.on('disconnect', () => {
    dispatch({ type: SOCKET_DISCONNECT });
  });

  manager.on('reconnect', () => {
    dispatch({ type: SOCKET_RECONNECT_SUCCESS });
    dispatch({ type: SOCKET_CONNECT });
    authenticateChat()(dispatch);
  });

  manager.on('reconnect_attempt', () => {
    dispatch({
      type: SOCKET_RECONNECT,
    });
  });

  manager.on('reconnect_error', (error) => {
    dispatch({
      type: SOCKET_RECONNECT_ERROR,
      payload: error,
    });
  });

  manager.on('reconnect_failed', (error) => {
    dispatch({
      type: SOCKET_RECONNECT_ERROR,
      payload: error,
    });
  });
};

const triggerIsTyping = ({ objectId, objectType, typing }) => async () => {
  try {
    await emitIsTyping({ objectId, objectType, typing });
  } catch ({ response }) {
    throw response && response.data;
  }
};

const markMessageRead = (objectId, objectType) => async () => {
  try {
    await markUnread(objectId, objectType);
  } catch ({ response }) {
    throw response && response.data;
  }
};

const getMessages = ({
  objectId,
  objectType,
  read,
}) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBSCRIPTION_MESSAGES,
      payload: {
        objectId: objectId || 'all',
        objectType,
      },
    });
    const res = await fetchMessages({ objectId, objectType, read });
    dispatch({
      type: GET_SUBSCRIPTION_MESSAGES_SUCCESS,
      payload: {
        objectId: objectId || 'all',
        objectType,
        messages: res.data,
      },
    });
    return res.data;
  } catch ({ response }) {
    throw response && response.data;
  }
};

const sendMessage = ({
  objectId,
  objectType,
  text,
  attachments,
  mentions,
  meta,
}) => async (dispatch) => {
  dispatch({ type: SEND_MESSAGE });
  try {
    await addMessage({
      objectId,
      objectType,
      content: text,
      attachments,
      mentions,
      meta,
    });
  } catch ({ response }) {
    dispatch({ type: SEND_MESSAGE_ERROR });
    throw response && response.data;
  }
};

const editMessage = ({
  id,
  text,
  mentions,
}) => async (dispatch) => {
  try {
    dispatch({ type: SEND_MESSAGE });
    const res = await updateMessage({
      id,
      content: text,
      mentions,
    });
    dispatch({
      type: EDIT_MESSAGE,
      payload: res.data,
    });
  } catch ({ response }) {
    dispatch({ type: SEND_MESSAGE_ERROR });
    throw response && response.data;
  }
};

const toggleChat = (open) => (dispatch) => {
  dispatch({
    type: TOGGLE_CHAT,
    payload: open,
  });
};

export {
  authenticateChat,
  initializeChatListeners,
  sendMessage,
  editMessage,
  closeConnection,
  initializeSocket,
  triggerIsTyping,
  getUnreadMentions,
  markMessageRead,
  getMessages,
  toggleChat,
};
