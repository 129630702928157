import {
  GET_INTEGRATIONS,
  GET_INTEGRATIONS_SUCCESS,
  GET_INTEGRATIONS_ERROR,
  ADD_INTEGRATION_SUCCESS,
  EDIT_INTEGRATION_SUCCESS,
  DELETE_INTEGRATION_SUCCESS,
  EDIT_TEAMS_CHANNEL,
} from '../actions/IntegrationsActions';

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATIONS: {
      return {
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_INTEGRATIONS_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_INTEGRATIONS_ERROR: {
      return {
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    case ADD_INTEGRATION_SUCCESS: {
      return {
        isFetching: false,
        data: [...state.data, action.payload],
        error: null,
      };
    }
    case EDIT_INTEGRATION_SUCCESS: {
      const editedIntegration = action.payload;
      return {
        isFetching: false,
        data: state.data.map(integration => {
          if (editedIntegration && integration._id === editedIntegration._id) {
            return editedIntegration;
          }
          return integration;
        }),
        error: null,
      };
    }
    case DELETE_INTEGRATION_SUCCESS: {
      const id = action.payload;

      return {
        isFetching: false,
        data: state.data.filter(integration => integration._id !== id),
        error: null,
      };
    }
    case EDIT_TEAMS_CHANNEL: {
      const {
        integrationId,
        channelId,
        data,
      } = action.payload;

      return {
        isFetching: false,
        data: state.data.map(integration => {
          if (integration._id === integrationId) {
            return {
              ...integration,
              teams: {
                ...integration.teams,
                channels: integration.teams.channels.map(channel => {
                  if (channel._id === channelId) {
                    return data;
                  }
                  return channel;
                }),
              },
            };
          }
          return integration;
        }),
        error: null,
      };
    }
    default:
      return state;
  }
}
