import {
  GET_RATES_RULES_DETAILS,
  GET_RATES_RULES_DETAILS_SUCCESS,
  GET_RATES_RULES_DETAILS_ERROR,
} from '../actions/ratesRulesDetailsActions';

const initialState = {
  isFetching: false,
  data: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RATES_RULES_DETAILS: {
      return {
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_RATES_RULES_DETAILS_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_RATES_RULES_DETAILS_ERROR: {
      return {
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    default:
      return { ...state };
  }
};
