import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_RATES_RULES_DETAILS = 'GET_RATES_RULES_DETAILS';
export const GET_RATES_RULES_DETAILS_SUCCESS = 'GET_RATES_RULES_DETAILS_SUCCESS';
export const GET_RATES_RULES_DETAILS_ERROR = 'GET_RATES_RULES_DETAILS_ERROR';

export const getRatesRulesDetails = (ruleNumber, projectId) => {
  setAuthToken(localStorage.getItem('token'));
  return async (dispatch) => {
    dispatch({ type: GET_RATES_RULES_DETAILS });
    try {
      const res = await axios.post(`${URL}/rates-library/search`, {
        ruleNumber,
        projectId,
      });
      dispatch({
        type: GET_RATES_RULES_DETAILS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_RATES_RULES_DETAILS_ERROR,
        payload: response && response.data && response.data.message,
      });
    }
  };
};
