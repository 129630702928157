import {
  AUTHENTICATION,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_REMOVED,
  AUTHENTICATION_ERROR,
  RESET_AUTHENTICATION_ERROR,
} from '../actions/authActions';

const initialState = {
  authenticated: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION: {
      return {
        error: null,
        authenticated: false,
      };
    }
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authenticated: true,
      };
    case AUTHENTICATION_REMOVED:
      return {
        ...state,
        authenticated: false,
      };
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_AUTHENTICATION_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
}
