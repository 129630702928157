import { omit } from 'lodash';

import {
  GET_COMPANIES,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  DELETE_COMPANY,
  CREATE_COMPANY,
  EDIT_COMPANY,
} from '../actions/companiesActions';

const initialState = {
  isFetching: false,
  data: {},
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES: {
      return {
        isFetching: true,
        data: {},
        error: null,
      };
    }
    case GET_COMPANIES_SUCCESS: {
      const companyList = action.payload || [];
      const companies = companyList.reduce((acc, num) => {
        acc[num._id] = num;
        return acc;
      }, {});

      return {
        isFetching: false,
        data: companies,
        error: null,
      };
    }
    case GET_COMPANIES_ERROR: {
      const error = action.payload;
      return {
        isFetching: false,
        data: [],
        error: error && error.message,
      };
    }
    case CREATE_COMPANY: {
      const company = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [company._id]: company,
        },
      };
    }
    case DELETE_COMPANY: {
      const companyId = action.payload;

      return {
        ...state,
        data: omit(state.data, companyId),
      };
    }
    case EDIT_COMPANY: {
      const company = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [company._id]: company,
        },
      };
    }
    default: {
      return state;
    }
  }
};
