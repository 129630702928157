import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
} from '../actions/rolesActions';

const initialState = {
  isFetching: false,
  error: '',
  data: [],
  count: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ROLES: {
      return {
        isFetching: true,
        error: '',
        data: [],
        count: 0,
      };
    }
    case GET_ROLES_SUCCESS: {
      return {
        isFetching: false,
        error: '',
        data: action.payload,
        count: 0,
      };
    }
    case GET_ROLES_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
