import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/serff-spi/amendment-drafts`;

export const GET_AMENDMENT_DRAFTS = 'GET_AMENDMENT_DRAFTS';
export const GET_AMENDMENT_DRAFTS_SUCCESS = 'GET_AMENDMENT_DRAFTS_SUCCESS';
export const GET_AMENDMENT_DRAFTS_ERROR = 'GET_AMENDMENT_DRAFTS_ERROR';
export const INVALIDATE_AMENDMENT_DRAFTS = 'INVALIDATE_AMENDMENT_DRAFTS';

export const ADD_AMENDMENT_DRAFT = 'ADD_AMENDMENT_DRAFT';
export const EDIT_AMENDMENT_DRAFT = 'EDIT_AMENDMENT_DRAFT';
export const DELETE_AMENDMENT_DRAFT = 'DELETE_AMENDMENT_DRAFT';

export const ADD_AMENDMENT_DRAFT_FORM = 'ADD_AMENDMENT_DRAFT_FORM';
export const EDIT_AMENDMENT_DRAFT_FORM = 'EDIT_AMENDMENT_DRAFT_FORM';
export const DELETE_AMENDMENT_DRAFT_FORM = 'DELETE_AMENDMENT_DRAFT_FORM';
export const ADD_AMENDMENT_DRAFT_FORM_ATTACHMENT = 'ADD_AMENDMENT_DRAFT_FORM_ATTACHMENT';
export const DELETE_AMENDMENT_DRAFT_FORM_ATTACHMENT = 'DELETE_AMENDMENT_DRAFT_FORM_ATTACHMENT';

export const ADD_AMENDMENT_DRAFT_RATE = 'ADD_AMENDMENT_DRAFT_RATE';
export const EDIT_AMENDMENT_DRAFT_RATE = 'EDIT_AMENDMENT_DRAFT_RATE';
export const DELETE_AMENDMENT_DRAFT_RATE = 'DELETE_AMENDMENT_DRAFT_RATE';
export const ADD_AMENDMENT_DRAFT_RATE_ATTACHMENT = 'ADD_AMENDMENT_DRAFT_RATE_ATTACHMENT';
export const DELETE_AMENDMENT_DRAFT_RATE_ATTACHMENT = 'DELETE_AMENDMENT_DRAFT_RATE_ATTACHMENT';

export const ADD_AMENDMENT_DRAFT_SUPPORTING = 'ADD_AMENDMENT_DRAFT_SUPPORTING';
export const EDIT_AMENDMENT_DRAFT_SUPPORTING = 'EDIT_AMENDMENT_DRAFT_SUPPORTING';
export const DELETE_AMENDMENT_DRAFT_SUPPORTING = 'DELETE_AMENDMENT_DRAFT_SUPPORTING';
export const ADD_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT = 'ADD_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT';
export const DELETE_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT = 'DELETE_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT';

export const invalidateAmendmentDrafts = () => (dispatch) => {
  dispatch({ type: INVALIDATE_AMENDMENT_DRAFTS });
};

export const getAmendmentDrafts = ({
  filingId,
  limit,
  offset,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const params = {
    filing: filingId,
    limit,
    offset,
  };

  return async (dispatch) => {
    try {
      dispatch({ type: GET_AMENDMENT_DRAFTS });
      const res = await axios.get(`${URL}`, { params });
      dispatch({
        type: GET_AMENDMENT_DRAFTS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_AMENDMENT_DRAFTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addAmendmentDraft = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}`, data);
      dispatch({
        type: ADD_AMENDMENT_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editAmendmentDraft = (amendmentDraftId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${amendmentDraftId}`, data);
      dispatch({
        type: EDIT_AMENDMENT_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${URL}/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const submitAmendmentDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/${id}/submission`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addAmendmentDraftForm = ({ id, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/forms`, data);
      dispatch({
        type: ADD_AMENDMENT_DRAFT_FORM,
        payload: { id, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editAmendmentDraftForm = ({
  id,
  formId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/forms/${formId}`, data);
      dispatch({
        type: EDIT_AMENDMENT_DRAFT_FORM,
        payload: {
          id,
          formId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraftForm = ({ id, formId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/forms/${formId}`);
      dispatch({
        type: DELETE_AMENDMENT_DRAFT_FORM,
        payload: {
          id,
          formId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addAmendmentDraftFormAttachment = ({
  id,
  formId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/forms/${formId}/attachments`, attachment);
      dispatch({
        type: ADD_AMENDMENT_DRAFT_FORM_ATTACHMENT,
        payload: {
          id,
          formId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraftFormAttachment = ({
  id,
  formId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/forms/${formId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_AMENDMENT_DRAFT_FORM_ATTACHMENT,
        payload: {
          id,
          formId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addAmendmentDraftRate = ({ id, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/rates`, data);
      dispatch({
        type: ADD_AMENDMENT_DRAFT_RATE,
        payload: { id, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editAmendmentDraftRate = ({
  id,
  rateId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/rates/${rateId}`, data);
      dispatch({
        type: EDIT_AMENDMENT_DRAFT_RATE,
        payload: {
          id,
          rateId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraftRate = ({ id, rateId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/rates/${rateId}`);
      dispatch({
        type: DELETE_AMENDMENT_DRAFT_RATE,
        payload: {
          id,
          rateId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addAmendmentDraftRateAttachment = ({
  id,
  rateId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/rates/${rateId}/attachments`, attachment);
      dispatch({
        type: ADD_AMENDMENT_DRAFT_RATE_ATTACHMENT,
        payload: {
          id,
          rateId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraftRateAttachment = ({
  id,
  rateId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/rates/${rateId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_AMENDMENT_DRAFT_RATE_ATTACHMENT,
        payload: {
          id,
          rateId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addAmendmentDraftSupporting = ({ id, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/supportings`, data);
      dispatch({
        type: ADD_AMENDMENT_DRAFT_SUPPORTING,
        payload: { id, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editAmendmentDraftSupporting = ({
  id,
  supportingId,
  data,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/supportings/${supportingId}`, data);
      dispatch({
        type: EDIT_AMENDMENT_DRAFT_SUPPORTING,
        payload: {
          id,
          supportingId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraftSupporting = ({ id, supportingId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/supportings/${supportingId}`);
      dispatch({
        type: DELETE_AMENDMENT_DRAFT_SUPPORTING,
        payload: {
          id,
          supportingId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addAmendmentDraftSupportingAttachment = ({
  id,
  supportingId,
  attachment,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${id}/supportings/${supportingId}/attachments`, attachment);
      dispatch({
        type: ADD_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT,
        payload: {
          id,
          supportingId,
          attachment: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteAmendmentDraftSupportingAttachment = ({
  id,
  supportingId,
  attachmentId,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}/supportings/${supportingId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_AMENDMENT_DRAFT_SUPPORTING_ATTACHMENT,
        payload: {
          id,
          supportingId,
          attachmentId,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
