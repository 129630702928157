import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from './hooks';
import { currentUser, hasRampDriveFeature } from '../selectors/selectors';
import { usePermissions } from '../services/permissionsService';

export default function (Component) {
  const RequireRampDriveFeature = ({ hasRampDrive, user, navigate }) => {
    const { firstAccessibleUrl } = usePermissions();

    useEffect(() => {
      if (user !== undefined && !hasRampDrive) {
        navigate(firstAccessibleUrl);
      }
    }, [user, hasRampDrive, firstAccessibleUrl]);

    return (
      <Component />
    );
  };

  const mapStateToProps = (state) => ({
    hasRampDrive: hasRampDriveFeature(state),
    user: currentUser(state),
  });

  return connect(mapStateToProps)(withRouter(RequireRampDriveFeature));
}
