import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_INSTANCES = 'GET_INSTANCES';
export const GET_INSTANCES_SUCCESS = 'GET_INSTANCES_SUCCESS';
export const GET_INSTANCES_ERROR = 'GET_INSTANCES_ERROR';

export const CREATE_INSTANCE = 'CREATE_INSTANCE';
export const EDIT_INSTANCE = 'EDIT_INSTANCE';

export function createInstance({
  username,
  password,
  name,
  host,
  protocol,
  type,
}) {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/serff-instances`, {
        username,
        password,
        name,
        host,
        protocol,
        type,
      });
      dispatch({
        type: CREATE_INSTANCE,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const editInstance = ({ instanceId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/serff-instances/${instanceId}`, data);
      dispatch({
        type: EDIT_INSTANCE,
        payload: {
          instanceId,
          data: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export function getInstances(archived = false) {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_INSTANCES });
      const res = await axios.get(`${URL}/serff-instances`, {
        params: {
          archived,
        },
      });
      dispatch({
        type: GET_INSTANCES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_INSTANCES_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
}
