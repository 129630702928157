import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_REMINDERS = 'GET_REMINDERS';
export const GET_REMINDERS_SUCCESS = 'GET_REMINDERS_SUCCESS';
export const GET_REMINDERS_ERROR = 'GET_REMINDERS_ERROR';

export const ADD_REMINDER = 'ADD_REMINDER';
export const EDIT_REMINDER = 'EDIT_REMINDER';
export const DELETE_REMINDER = 'DELETE_REMINDER';

export const getReminders = (params) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_REMINDERS });
    try {
      const res = await axios.get(`${URL}/reminders`, { params });
      dispatch({
        type: GET_REMINDERS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_REMINDERS_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const addReminder = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/reminders`, data);
      dispatch({
        type: ADD_REMINDER,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editReminder = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/reminders/${id}`, data);
      dispatch({
        type: EDIT_REMINDER,
        payload: {
          id,
          data: res.data,
        },
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteReminder = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/reminders/${id}`);
      dispatch({
        type: DELETE_REMINDER,
        payload: id,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addReminderAttachment = ({ id, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/reminders/${id}/attachments`, attachment);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteReminderAttachment = ({ id, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.delete(`${URL}/reminders/${id}/attachments/${attachmentId}`);
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
