import axios from 'axios';
import { setAuthToken, URL } from './index';

const LIBRARY_URL = `${process.env.REACT_APP_API_ENDPOINT}/library`;

export const GET_RATES_LIBRARY = 'GET_RATES_LIBRARY';
export const GET_RATES_LIBRARY_SUCCESS = 'GET_RATES_LIBRARY_SUCCESS';
export const GET_RATES_LIBRARY_ERROR = 'GET_RATES_LIBRARY_ERROR';

export const HIDE_RATE = 'HIDE_RATE';
export const UNHIDE_RATE = 'UNHIDE_RATE';

export const GET_LIBRARY_RATES = 'GET_LIBRARY_RATES';
export const GET_LIBRARY_RATES_SUCCESS = 'GET_LIBRARY_RATES_SUCCESS';
export const GET_LIBRARY_RATES_ERROR = 'GET_LIBRARY_RATES_ERROR';

export const GET_FILING_LIBRARY_RATE_STATUSES = 'GET_FILING_LIBRARY_RATE_STATUSES';

export const getRatesLibrary = (params) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_RATES_LIBRARY });
      const res = await axios.get(`${URL}/rates-library`, { params });
      dispatch({
        type: GET_RATES_LIBRARY_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_RATES_LIBRARY_ERROR,
        payload: response && response.data,
      });
      throw response.data;
    }
  };
};

export const updateRatesLibrary = (payload) => (dispatch) => {
  dispatch({
    type: GET_RATES_LIBRARY_SUCCESS,
    payload,
  });
};

export const hideRate = ({ ruleNumber, state }) => {
  setAuthToken(localStorage.getItem('token'));

  let postData;
  if (state) {
    postData = {
      state,
    };
  }
  return async (dispatch) => {
    try {
      await axios.put(
        `${URL}/rates-library/${encodeURIComponent(ruleNumber)}/hide`,
        postData,
      );
      dispatch({
        type: HIDE_RATE,
        payload: ruleNumber,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const unHideRate = ({ ruleNumber, state }) => {
  setAuthToken(localStorage.getItem('token'));

  let postData;
  if (state) {
    postData = {
      state,
    };
  }
  return async (dispatch) => {
    try {
      await axios.put(
        `${URL}/rates-library/${encodeURIComponent(ruleNumber)}/unhide`,
        postData,
      );
      dispatch({
        type: UNHIDE_RATE,
        payload: ruleNumber,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const getLibraryRates = (params, dispatchChanges) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      if (dispatchChanges) {
        dispatch({ type: GET_LIBRARY_RATES });
      }
      const res = await axios.post(`${LIBRARY_URL}/library-rates/search`, params);
      if (dispatchChanges) {
        dispatch({
          type: GET_LIBRARY_RATES_SUCCESS,
          payload: res.data,
        });
      }
      return res.data;
    } catch ({ response }) {
      if (dispatchChanges) {
        dispatch({
          type: GET_LIBRARY_RATES_ERROR,
          payload: response && response.data,
        });
      }
      throw response.data;
    }
  };
};

export const getFilingLibraryRateStatuses = ({ rateId, filingId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.get(`${LIBRARY_URL}/library-rates/${rateId}/statuses`, {
        params: {
          filing: filingId,
        },
      });
      dispatch({
        type: GET_FILING_LIBRARY_RATE_STATUSES,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatusBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/statuses/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editLibraryRateStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${LIBRARY_URL}/library-rates/statuses/custom-fields/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addLibraryRateStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/statuses/custom-fields/bulk`, payload);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteLibraryRateStatusCustomFieldsBulk = (payload) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rates/statuses/custom-fields/bulk`, {
        data: payload,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const scanLibraryRates = () => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/scan`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const addRateStatusReplaces = ({ rateId, statusId, data }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${LIBRARY_URL}/library-rates/${rateId}/statuses/${statusId}/replaces`, data);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const deleteRateStatusReplaces = ({ rateId, statusId, id }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${LIBRARY_URL}/library-rates/${rateId}/statuses/${statusId}/replaces/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};
