import {
  GET_NOTE_DRAFTS,
  GET_NOTE_DRAFTS_SUCCESS,
  GET_NOTE_DRAFTS_ERROR,
  ADD_NOTE_DRAFT,
  EDIT_NOTE_DRAFT,
  DELETE_NOTE_DRAFT,
  ADD_NOTE_DRAFT_ATTACHMENT,
  DELETE_NOTE_DRAFT_ATTACHMENT,
  INVALIDATE_NOTE_DRAFTS,
} from '../actions/noteDraftsActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTE_DRAFTS: {
      return {
        isFetched: false,
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_NOTE_DRAFTS_SUCCESS: {
      return {
        isFetched: true,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_NOTE_DRAFTS_ERROR: {
      return {
        isFetched: true,
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    case DELETE_NOTE_DRAFT: {
      return {
        isFetched: true,
        isFetching: false,
        data: state.data
          .filter(noteDraft => noteDraft._id !== action.payload),
        error: null,
      };
    }
    case ADD_NOTE_DRAFT: {
      return {
        isFetched: true,
        isFetching: false,
        data: [...state.data, action.payload],
        error: null,
      };
    }
    case EDIT_NOTE_DRAFT: {
      const noteDraft = action.payload;
      return {
        isFetched: true,
        isFetching: false,
        data: state.data.map(note => {
          if (note._id === noteDraft._id) {
            return noteDraft;
          }
          return note;
        }),
        error: null,
      };
    }
    case ADD_NOTE_DRAFT_ATTACHMENT: {
      const { noteDraftId, attachment } = action.payload;

      return {
        ...state,
        data: state.data.map(noteDraft => {
          if (noteDraft._id === noteDraftId) {
            return {
              ...noteDraft,
              attachments: [...noteDraft.attachments, attachment],
            };
          }
          return noteDraft;
        }),
      };
    }
    case DELETE_NOTE_DRAFT_ATTACHMENT: {
      const { noteDraftId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(noteDraft => {
          if (noteDraft._id === noteDraftId) {
            return {
              ...noteDraft,
              attachments: noteDraft.attachments
                .filter(attachment => attachment._id !== attachmentId),
            };
          }
          return noteDraft;
        }),
      };
    }
    case INVALIDATE_NOTE_DRAFTS: {
      return {
        isFetched: false,
        isFetching: false,
        data: [],
        error: null,
      };
    }
    default:
      return { ...state };
  }
};
