import {
  GET_RESPONSE_DRAFTS,
  GET_RESPONSE_DRAFTS_SUCCESS,
  GET_RESPONSE_DRAFTS_ERROR,
  UPSERT_RESPONSE_DRAFT,
  CREATE_RESPONSE_ITEM,
  EDIT_RESPONSE_ITEM,
  ADD_RESPONSE_DRAFT_FORM,
  EDIT_RESPONSE_DRAFT_FORM,
  DELETE_RESPONSE_DRAFT_FORM,
  ADD_RESPONSE_DRAFT_FORM_ATTACHMENT,
  DELETE_RESPONSE_DRAFT_FORM_ATTACHMENT,
  ADD_RESPONSE_DRAFT_RATE,
  EDIT_RESPONSE_DRAFT_RATE,
  DELETE_RESPONSE_DRAFT_RATE,
  ADD_RESPONSE_DRAFT_RATE_ATTACHMENT,
  DELETE_RESPONSE_DRAFT_RATE_ATTACHMENT,
  ADD_RESPONSE_DRAFT_SUPPORTING,
  EDIT_RESPONSE_DRAFT_SUPPORTING,
  DELETE_RESPONSE_DRAFT_SUPPORTING,
  ADD_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT,
  DELETE_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT,
  INVALIDATE_RESPONSE_DRAFTS,
} from '../actions/responseDraftsActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESPONSE_DRAFTS: {
      return {
        isFetched: false,
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_RESPONSE_DRAFTS_SUCCESS: {
      return {
        isFetched: true,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_RESPONSE_DRAFTS_ERROR: {
      return {
        isFetched: false,
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    case INVALIDATE_RESPONSE_DRAFTS: {
      return {
        isFetched: false,
        isFetching: false,
        data: [],
        error: null,
      };
    }
    case UPSERT_RESPONSE_DRAFT: {
      const { id } = action.payload;
      const isResponseDraftNew = !state.data.find(responseDraft => responseDraft.id === id);

      let responseDrafts = [];
      if (isResponseDraftNew) {
        responseDrafts = [...state.data, action.payload];
      } else {
        responseDrafts = state.data.map(response => {
          if (response.id === id) {
            return action.payload;
          }
          return response;
        });
      }

      return {
        isFetched: true,
        isFetching: false,
        data: responseDrafts,
        error: null,
      };
    }
    case CREATE_RESPONSE_ITEM: {
      const { responseDraftId, data } = action.payload;
      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === responseDraftId) {
            return {
              ...responseDraft,
              responses: [...responseDraft.responses, data],
            };
          }
          return responseDraft;
        }),
      };
    }
    case EDIT_RESPONSE_ITEM: {
      const { responseDraftId, data } = action.payload;
      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === responseDraftId) {
            return {
              ...responseDraft,
              responses: responseDraft.responses.map(response => {
                if (data._id === response._id) {
                  return data;
                }
                return response;
              }),
            };
          }
          return responseDraft;
        }),
      };
    }
    case ADD_RESPONSE_DRAFT_FORM: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                forms: [...responseDraft.changes.forms, data],
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case EDIT_RESPONSE_DRAFT_FORM: {
      const {
        id,
        formId,
        data,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                forms: responseDraft.changes.forms.map(form => {
                  if (formId === form._id) {
                    return data;
                  }
                  return form;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case DELETE_RESPONSE_DRAFT_FORM: {
      const {
        id,
        formId,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                forms: responseDraft.changes.forms.filter(form => form._id !== formId),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case ADD_RESPONSE_DRAFT_FORM_ATTACHMENT: {
      const { id, formId, attachment } = action.payload;
      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                forms: responseDraft.changes.forms.map(form => {
                  if (formId === form._id) {
                    return {
                      ...form,
                      attachments: [...form.attachments, attachment],
                    };
                  }
                  return form;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case DELETE_RESPONSE_DRAFT_FORM_ATTACHMENT: {
      const { id, formId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                forms: responseDraft.changes.forms.map(form => {
                  if (formId === form._id) {
                    return {
                      ...form,
                      attachments: form.attachments
                        .filter(attachment => attachment._id !== attachmentId),
                    };
                  }
                  return form;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case ADD_RESPONSE_DRAFT_RATE: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                rates: [...responseDraft.changes.rates, data],
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case EDIT_RESPONSE_DRAFT_RATE: {
      const {
        id,
        rateId,
        data,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                rates: responseDraft.changes.rates.map(rate => {
                  if (rateId === rate._id) {
                    return data;
                  }
                  return rate;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case DELETE_RESPONSE_DRAFT_RATE: {
      const {
        id,
        rateId,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                rates: responseDraft.changes.rates.filter(rate => rate._id !== rateId),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case ADD_RESPONSE_DRAFT_RATE_ATTACHMENT: {
      const { id, rateId, attachment } = action.payload;
      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                rates: responseDraft.changes.rates.map(rate => {
                  if (rateId === rate._id) {
                    return {
                      ...rate,
                      attachments: [...rate.attachments, attachment],
                    };
                  }
                  return rate;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case DELETE_RESPONSE_DRAFT_RATE_ATTACHMENT: {
      const { id, rateId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                rates: responseDraft.changes.rates.map(rate => {
                  if (rateId === rate._id) {
                    return {
                      ...rate,
                      attachments: rate.attachments
                        .filter(attachment => attachment._id !== attachmentId),
                    };
                  }
                  return rate;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case ADD_RESPONSE_DRAFT_SUPPORTING: {
      const { id, data } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                supportings: [...responseDraft.changes.supportings, data],
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case EDIT_RESPONSE_DRAFT_SUPPORTING: {
      const {
        id,
        supportingId,
        data,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                supportings: responseDraft.changes.supportings.map(supporting => {
                  if (supportingId === supporting._id) {
                    return data;
                  }
                  return supporting;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case DELETE_RESPONSE_DRAFT_SUPPORTING: {
      const {
        id,
        supportingId,
      } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                supportings: responseDraft.changes.supportings
                  .filter(supporting => supporting._id !== supportingId),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case ADD_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT: {
      const { id, supportingId, attachment } = action.payload;
      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                supportings: responseDraft.changes.supportings.map(supporting => {
                  if (supportingId === supporting._id) {
                    return {
                      ...supporting,
                      attachments: [...supporting.attachments, attachment],
                    };
                  }
                  return supporting;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    case DELETE_RESPONSE_DRAFT_SUPPORTING_ATTACHMENT: {
      const { id, supportingId, attachmentId } = action.payload;

      return {
        ...state,
        data: state.data.map(responseDraft => {
          if (responseDraft._id === id) {
            return {
              ...responseDraft,
              changes: {
                ...responseDraft.changes,
                supportings: responseDraft.changes.supportings.map(supporting => {
                  if (supportingId === supporting._id) {
                    return {
                      ...supporting,
                      attachments: supporting.attachments
                        .filter(attachment => attachment._id !== attachmentId),
                    };
                  }
                  return supporting;
                }),
              },
            };
          }
          return responseDraft;
        }),
      };
    }
    default:
      return { ...state };
  }
};
