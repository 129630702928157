import {
  GET_PROJECT,
  GET_PROJECT_SUCCESS,
  INVALIDATE_PROJECT,
  UPSERT_PROJECT_CUSTOM_FIELD,
  DELETE_PROJECT_CUSTOM_FIELD,
  ADD_PROJECT_ATTACHMENTS,
  DELETE_PROJECT_ATTACHMENTS,
} from '../actions/projectsActions';

const initialState = {
  isFetching: false,
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT: {
      return {
        isFetching: true,
        data: {},
      };
    }
    case GET_PROJECT_SUCCESS:
      return {
        isFetching: false,
        data: action.payload,
      };
    case INVALIDATE_PROJECT: {
      return initialState;
    }
    case UPSERT_PROJECT_CUSTOM_FIELD: {
      const customField = action.payload;
      const customFields = (state.data && state.data.customFields) || [];

      const list = customFields.find(field => field._id === customField._id)
        ? customFields.map(field => (field._id === customField._id ? customField : field))
        : [...customFields, action.payload];

      return {
        ...state,
        data: {
          ...state.data,
          customFields: list,
        },
      };
    }
    case DELETE_PROJECT_CUSTOM_FIELD: {
      const customField = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          customFields: state.data.customFields
            .filter(field => customField.customFieldValueId !== field._id),
        },
      };
    }
    case ADD_PROJECT_ATTACHMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          attachments: [
            ...state.data.attachments,
            action.payload,
          ],
        },
      };
    }
    case DELETE_PROJECT_ATTACHMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          attachments: state.data.attachments
            .filter(attachment => attachment._id !== action.payload),
        },
      };
    }
    default:
      return state;
  }
}
