import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/serff-spi/post-submission-drafts`;

export const GET_POST_SUBMISSION_DRAFTS = 'GET_POST_SUBMISSION_DRAFTS';
export const GET_POST_SUBMISSION_DRAFTS_SUCCESS = 'GET_POST_SUBMISSION_DRAFTS_SUCCESS';
export const GET_POST_SUBMISSION_DRAFTS_ERROR = 'GET_POST_SUBMISSION_DRAFTS_ERROR';
export const INVALIDATE_POST_SUBMISSION_DRAFTS = 'INVALIDATE_POST_SUBMISSION_DRAFTS';

export const EDIT_POST_SUBMISSION_DRAFT_GENERAL_INFORMATION = 'EDIT_POST_SUBMISSION_DRAFT_GENERAL_INFORMATION';
export const EDIT_POST_SUBMISSION_DRAFT_RATE_DATA = 'EDIT_POST_SUBMISSION_DRAFT_RATE_DATA';
export const EDIT_POST_SUBMISSION_DRAFT_STATE_SPECIFICS = 'EDIT_POST_SUBMISSION_DRAFT_STATE_SPECIFICS';

export const ADD_POST_SUBMISSION_DRAFT = 'ADD_POST_SUBMISSION_DRAFT';
export const EDIT_POST_SUBMISSION_DRAFT = 'EDIT_POST_SUBMISSION_DRAFT';
export const DELETE_POST_SUBMISSION_DRAFT = 'DELETE_POST_SUBMISSION_DRAFT';

export const invalidatePostSubmissionDrafts = () => (dispatch) => {
  dispatch({ type: INVALIDATE_POST_SUBMISSION_DRAFTS });
};

export const getPostSubmissionDrafts = ({
  filingId,
  limit,
  offset,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const params = {
    filing: filingId,
    limit,
    offset,
  };

  return async (dispatch) => {
    try {
      dispatch({ type: GET_POST_SUBMISSION_DRAFTS });
      const res = await axios.get(`${URL}`, { params });
      dispatch({
        type: GET_POST_SUBMISSION_DRAFTS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_POST_SUBMISSION_DRAFTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addPostSubmissionDraft = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}`, data);
      dispatch({
        type: ADD_POST_SUBMISSION_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editPostSubmissionDraft = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}`, data);
      dispatch({
        type: EDIT_POST_SUBMISSION_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deletePostSubmissionDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.delete(`${URL}/${id}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const submitPostSubmissionDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/${id}/submission`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editPostSubmissionGeneralInformation = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/general-information`, data);
      dispatch({
        type: EDIT_POST_SUBMISSION_DRAFT_GENERAL_INFORMATION,
        payload: {
          id,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editPostSubmissionRateData = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/rate-data`, data);
      dispatch({
        type: EDIT_POST_SUBMISSION_DRAFT_RATE_DATA,
        payload: {
          id,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editPostSubmissionStateSpecifics = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${id}/state-specifics`, data);
      dispatch({
        type: EDIT_POST_SUBMISSION_DRAFT_STATE_SPECIFICS,
        payload: {
          id,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
