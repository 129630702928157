import axios from 'axios';
import {
  URL,
  setAuthToken,
} from './index';

export const GET_WORKSPACES = 'GET_WORKSPACES';
export const GET_WORKSPACES_SUCCESS = 'GET_WORKSPACES_SUCCESS';
export const GET_WORKSPACES_ERROR = 'GET_WORKSPACES_ERROR';

export const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS';
export const DELETE_WORKSPACE_SUCCESS = 'DELETE_WORKSPACE_SUCCESS';
export const EDIT_WORKSPACE_SUCCESS = 'EDIT_WORKSPACE_SUCCESS';
export const REORDER_WORKSPACE_SUCCESS = 'REORDER_WORKSPACE_SUCCESS';
export const MOVE_PRODUCTS_SUCCESS = 'MOVE_PRODUCTS_SUCCESS';
export const REORDER_PRODUCTS_SUCCESS = 'REORDER_PRODUCTS_SUCCESS';
export const DELETE_PRODUCT_FROM_WORKSPACE = 'DELETE_PRODUCT_FROM_WORKSPACE';

const setToken = () => {
  setAuthToken(localStorage.getItem('token'));
};

export const getWorkspaces = () => {
  setToken();
  return async (dispatch) => {
    try {
      dispatch({ type: GET_WORKSPACES });
      const res = await axios.get(`${URL}/workspaces?limit=1000`);
      dispatch({
        type: GET_WORKSPACES_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({ type: GET_WORKSPACES_ERROR });
      throw response && response.data;
    }
  };
};

export const createWorkspace = (data) => {
  setToken();
  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/workspaces`, data);
      dispatch({
        type: CREATE_WORKSPACE_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editWorkspace = (id, data) => {
  setToken();
  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/workspaces/${id}`, data);
      dispatch({
        type: EDIT_WORKSPACE_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteWorkspace = (id) => {
  setToken();
  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/workspaces/${id}`);
      dispatch({
        type: DELETE_WORKSPACE_SUCCESS,
        payload: id,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const reorderProducts = ({ workspaceId, productId, order }) => {
  setToken();
  return async (dispatch) => {
    try {
      dispatch({
        type: REORDER_PRODUCTS_SUCCESS,
        payload: { workspaceId, productId, order },
      });
      await axios.patch(`${URL}/workspaces/${workspaceId}/products/${productId}`, { order });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const moveProduct = (data) => {
  setToken();
  return async (dispatch) => {
    const {
      oldWorkspaceId,
      newWorkspaceId,
      productId,
      order,
      dispatchUpdate,
    } = data;
    try {
      if (dispatchUpdate) {
        dispatch({
          type: MOVE_PRODUCTS_SUCCESS,
          payload: data,
        });
      }

      if (oldWorkspaceId) {
        await axios.delete(`${URL}/workspaces/${oldWorkspaceId}/products/${productId}`);
      }

      if (newWorkspaceId) {
        await axios.post(`${URL}/workspaces/${newWorkspaceId}/products`, { productId, order });
      }
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
