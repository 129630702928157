import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_ERROR,
} from '../actions/accountsActions';

const initialState = {
  isFetching: false,
  data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS: {
      return {
        isFetching: true,
        data: [],
      };
    }
    case GET_ACCOUNTS_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
      };
    }
    case GET_ACCOUNTS_ERROR: {
      return {
        isFetching: false,
        data: [],
      };
    }
    default:
      return state;
  }
}
