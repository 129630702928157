import axios from 'axios';
import {
  setAuthToken,
  URL,
} from './index';

export const GET_USERS = 'GET_USERS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const ADD_USER_CUSTOM_ROLE = 'ADD_USER_CUSTOM_ROLE';
export const REMOVE_USER_CUSTOM_ROLE = 'REMOVE_USER_CUSTOM_ROLE';

export const switchAccount = ({ accountId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.put(`${URL}/users/current/account/`, {
        accountId,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getUsers = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.get(`${URL}/users?limit=1000`);
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const createUser = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/users`, data);
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editUser = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/users/${id}`, data);
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const changeUserPassword = (id, password) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.put(`${URL}/users/${id}/password`, { password });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
