import {
  GET_LATEST_UPDATES,
  GET_LATEST_UPDATES_SUCCESS,
  GET_LATEST_UPDATES_ERROR,

  GET_PINNED_PROJECT,
  GET_PINNED_PROJECT_SUCCESS,
  GET_PINNED_PROJECT_ERROR,

  GET_UPCOMING_DEADLINES,
  GET_UPCOMING_DEADLINES_SUCCESS,
  GET_UPCOMING_DEADLINES_ERROR,
} from '../actions/dashboardActions';

const initialState = {
  latestUpdates: {
    isLoading: false,
    error: null,
    data: [],
  },
  pinnedProject: {
    isLoading: false,
    error: null,
    data: null,
  },
  upcomingDeadlines: {
    isLoading: false,
    error: null,
    data: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LATEST_UPDATES: {
      return {
        ...state,
        latestUpdates: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_LATEST_UPDATES_SUCCESS: {
      return {
        ...state,
        latestUpdates: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_LATEST_UPDATES_ERROR: {
      const { message } = action.payload;
      return {
        ...state,
        latestUpdates: {
          isLoading: false,
          error: message,
          data: [],
        },
      };
    }
    case GET_PINNED_PROJECT: {
      return {
        ...state,
        pinnedProject: {
          isLoading: true,
          error: null,
          data: null,
        },
      };
    }
    case GET_PINNED_PROJECT_SUCCESS: {
      return {
        ...state,
        pinnedProject: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_PINNED_PROJECT_ERROR: {
      const { message } = action.payload;
      return {
        ...state,
        pinnedProject: {
          isLoading: false,
          error: message,
          data: null,
        },
      };
    }
    case GET_UPCOMING_DEADLINES: {
      return {
        ...state,
        upcomingDeadlines: {
          isLoading: true,
          error: null,
          data: [],
        },
      };
    }
    case GET_UPCOMING_DEADLINES_SUCCESS: {
      return {
        ...state,
        upcomingDeadlines: {
          isLoading: false,
          error: null,
          data: action.payload,
        },
      };
    }
    case GET_UPCOMING_DEADLINES_ERROR: {
      const error = action.payload;
      return {
        ...state,
        upcomingDeadlines: {
          isLoading: false,
          error: error && error.message,
          data: [],
        },
      };
    }
    default:
      return { ...state };
  }
}
