import axios from 'axios';
import { setAuthToken } from './index';
import {
  SYNC_SUPPORTING_DOCUMENTS,
  SYNC_SUPPORTING_DOCUMENTS_ERROR,
  SYNC_SUPPORTING_DOCUMENTS_SUCCESS,
} from './syncActions';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/serff-spi/rules`;

export const GET_STATE_SPECIFICS_RULES = 'GET_STATE_SPECIFICS_RULES';
export const GET_STATE_SPECIFICS_RULES_SUCCESS = 'GET_STATE_SPECIFICS_RULES_SUCCESS';
export const GET_STATE_SPECIFICS_RULES_ERROR = 'GET_STATE_SPECIFICS_RULES_ERROR';

export const GET_SUPPORTINGS_RULES = 'GET_SUPPORTINGS_RULES';
export const GET_SUPPORTINGS_RULES_SUCCESS = 'GET_SUPPORTINGS_RULES_SUCCESS';
export const GET_SUPPORTINGS_RULES_ERROR = 'GET_SUPPORTINGS_RULES_ERROR';

export const GET_STATES_RULES = 'GET_STATES_RULES';
export const GET_STATES_RULES_SUCCESS = 'GET_STATES_RULES_SUCCESS';
export const GET_STATES_RULES_ERROR = 'GET_STATES_RULES_ERROR';

export const GET_TYPE_OF_INSURANCES_RULES = 'GET_TYPE_OF_INSURANCES_RULES';
export const GET_TYPE_OF_INSURANCES_RULES_SUCCESS = 'GET_TYPE_OF_INSURANCES_RULES_SUCCESS';
export const GET_TYPE_OF_INSURANCES_RULES_ERROR = 'GET_TYPE_OF_INSURANCES_RULES_ERROR';

export const GET_SUB_TYPE_OF_INSURANCES_RULES = 'GET_SUB_TYPE_OF_INSURANCES_RULES';
export const GET_SUB_TYPE_OF_INSURANCES_RULES_SUCCESS = 'GET_SUB_TYPE_OF_INSURANCES_RULES_SUCCESS';
export const GET_SUB_TYPE_OF_INSURANCES_RULES_ERROR = 'GET_SUB_TYPE_OF_INSURANCES_RULES_ERROR';

export const GET_FILING_TYPES_RULES = 'GET_FILING_TYPES_RULES';
export const GET_FILING_TYPES_RULES_SUCCESS = 'GET_FILING_TYPES_RULES_SUCCESS';
export const GET_FILING_TYPES_RULES_ERROR = 'GET_FILING_TYPES_RULES_ERROR';

export const GET_TYPE_OF_INSURANCES_RULES_DISTINCT = 'GET_TYPE_OF_INSURANCES_RULES_DISTINCT';
export const GET_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS = 'GET_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS';
export const GET_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR = 'GET_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR';

export const GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT = 'GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT';
export const GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS = 'GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS';
export const GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR = 'GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR';

export const GET_FILING_TYPES_RULES_DISTINCT = 'GET_FILING_TYPES_RULES_DISTINCT';
export const GET_FILING_TYPES_RULES_DISTINCT_SUCCESS = 'GET_FILING_TYPES_RULES_DISTINCT_SUCCESS';
export const GET_FILING_TYPES_RULES_DISTINCT_ERROR = 'GET_FILING_TYPES_RULES_DISTINCT_ERROR';

export const INVALIDATE_RULES = 'INVALIDATE_RULES';

export const getStateSpecificsRules = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_STATE_SPECIFICS_RULES,
      });
      const res = await axios.post(`${URL}/state-specifics/search`, data);
      dispatch({
        type: GET_STATE_SPECIFICS_RULES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_STATE_SPECIFICS_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const syncStateSpecificsRules = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.put(`${URL}/state-specifics/sync`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getSupportingsRules = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_SUPPORTINGS_RULES,
      });
      const res = await axios.post(`${URL}/supportings/search`, data);
      dispatch({
        type: GET_SUPPORTINGS_RULES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_SUPPORTINGS_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const syncSupportingsRules = (params) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: SYNC_SUPPORTING_DOCUMENTS,
        payload: params,
      });
      const res = await axios.put(`${URL}/supportings/sync`, params);
      dispatch({
        type: SYNC_SUPPORTING_DOCUMENTS_SUCCESS,
        payload: params,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: SYNC_SUPPORTING_DOCUMENTS_ERROR,
        payload: {
          params,
          error: (response?.data?.message) || 'Sync failed',
        },
      });
      throw (response?.data?.message) || 'Sync failed';
    }
  };
};

export const getStateRules = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_STATES_RULES,
      });
      const res = await axios.get(`${URL}/states`);
      dispatch({
        type: GET_STATES_RULES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_STATES_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const syncStateRules = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_STATES_RULES,
      });
      const res = await axios.put(`${URL}/states/sync`);
      dispatch({
        type: GET_STATES_RULES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_STATES_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const getTypeOfInsurancesRules = (state, archived = false) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES,
      });
      const res = await axios.get(`${URL}/type-of-insurances?state=${state}&archived=${archived}`);
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES_SUCCESS,
        payload: { state, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const syncTypeOfInsurancesRules = (state) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/type-of-insurances/sync`, {
        state,
        archived: false,
      });
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES_SUCCESS,
        payload: { state, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getSubTypeOfInsurancesRules = (typeOfInsurance, archived = false) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES,
      });
      const res = await axios.get(`${URL}/sub-type-of-insurances?typeOfInsuranceId=${typeOfInsurance}&archived=${archived}`, {});
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES_SUCCESS,
        payload: { typeOfInsurance, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const syncSubTypeOfInsurancesRules = (typeOfInsuranceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/sub-type-of-insurances/sync`, {
        typeOfInsuranceId,
        archived: false,
      });
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES_SUCCESS,
        payload: {
          typeOfInsurance: typeOfInsuranceId,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getFilingTypesRules = (subTypeOfInsurance, archived = false) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_FILING_TYPES_RULES,
      });
      const res = await axios.get(`${URL}/filing-types?subTypeOfInsuranceId=${subTypeOfInsurance}&archived=${archived}`, {});
      dispatch({
        type: GET_FILING_TYPES_RULES_SUCCESS,
        payload: { subTypeOfInsurance, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_FILING_TYPES_RULES_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const syncFilingTypesRules = (subTypeOfInsuranceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.put(`${URL}/filing-types/sync`, {
        subTypeOfInsuranceId,
        archived: false,
      });
      dispatch({
        type: GET_FILING_TYPES_RULES_SUCCESS,
        payload: { subTypeOfInsurance: subTypeOfInsuranceId, data: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getTypeOfInsurancesRulesDistinct = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES_DISTINCT,
      });
      const res = await axios.get(`${URL}/type-of-insurances/distinct`);
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const getSubTypeOfInsurancesRulesDistinct = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT,
      });
      const res = await axios.get(`${URL}/sub-type-of-insurances/distinct`);
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_SUB_TYPE_OF_INSURANCES_RULES_DISTINCT_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const getFilingTypeRulesDistinct = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_FILING_TYPES_RULES_DISTINCT,
      });
      const res = await axios.get(`${URL}/filing-types/distinct`);
      dispatch({
        type: GET_FILING_TYPES_RULES_DISTINCT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_FILING_TYPES_RULES_DISTINCT_ERROR,
        payload: response && response.data && response.data.message,
      });
      throw response && response.data;
    }
  };
};

export const invalidateRules = () => ({
  type: INVALIDATE_RULES,
});
