export const getFilingDraftAuthors = (filingDraft, selectedFiling) => {
  if (selectedFiling && selectedFiling.authors && selectedFiling.authors.length) {
    return selectedFiling.authors;
  }

  if (filingDraft && filingDraft.authors && filingDraft.authors.length) {
    return filingDraft.authors;
  }

  return [];
};

export const getFilingDraftContact = (filingDraft, selectedFiling) => {
  if (selectedFiling && selectedFiling.contact) {
    return selectedFiling.contact;
  }

  if (filingDraft && filingDraft.contact) {
    return filingDraft.contact;
  }

  return null;
};

export const getFilingDraftItemSerffCompanies = (serffCompanies, selectedFiling) => {
  const filingDraftItemCompanyIds = (selectedFiling && selectedFiling.serffCompanies) || [];
  return filingDraftItemCompanyIds.reduce((acc, num) => {
    const company = serffCompanies.find(serffCompany => serffCompany.value === num);
    if (company) {
      acc.push(company);
    }
    return acc;
  }, []);
};
