import { clone, escapeRegExp } from 'lodash';

export const parseMention = (text, currentUserId) => {
  let displayText = clone(text);
  const regex = /@\{\{[^\}]+\}\}/gi;
  const tags = text.match(regex) || [];
  tags.forEach(tag => {
    const data = tag.slice(3, -2).split('||');
    const [, user, name] = data;
    const classes = `chat-mentions-mention ${user === currentUserId ? 'chat-mentions-mention__highlighted' : ''}`;
    displayText = displayText
      .replace(
        new RegExp(escapeRegExp(tag), 'gi'),
        `<strong class="${classes}">@${name}</strong>`,
      );
  });
  return displayText;
};

export const getUserIdFromMentions = (text) => {
  const regex = /@\{\{[^\}]+\}\}/gi;
  const tags = text.match(regex) || [];
  const mentionIds = [];
  tags.forEach(tag => {
    const data = tag.slice(3, -2).split('||');
    const [, user] = data;
    mentionIds.push(user);
  });
  return mentionIds;
};
