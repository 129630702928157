import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'normalize.css';
import './index.scss';

const AppContainer = <BrowserRouter><App /></BrowserRouter>;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(AppContainer);

serviceWorker.unregister();
