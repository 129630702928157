import {
  GET_LIBRARY_RATE,
  GET_LIBRARY_RATE_SUCCESS,
  GET_LIBRARY_RATE_ERROR,
  ADD_LIBRARY_RATE_ATTACHMENT,
  DELETE_LIBRARY_RATE_ATTACHMENT,
  EDIT_LIBRARY_RATE_ATTACHMENT,
  ADD_LIBRARY_RATE_STATUS,
  EDIT_LIBRARY_RATE_STATUS,
  CLONE_LIBRARY_RATE_STATUS,
  ADD_LIBRARY_RATE_CUSTOM_FIELD,
  EDIT_LIBRARY_RATE_CUSTOM_FIELD,
  DELETE_LIBRARY_RATE_CUSTOM_FIELD,
} from '../actions/libraryRateActions';

const initialState = {
  isFetching: false,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIBRARY_RATE: {
      return {
        isFetching: true,
        data: null,
      };
    }
    case GET_LIBRARY_RATE_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
      };
    }
    case GET_LIBRARY_RATE_ERROR: {
      return {
        isFetching: false,
        data: null,
      };
    }
    case ADD_LIBRARY_RATE_STATUS: {
      if (!state.data) {
        return state;
      }

      const { data } = action.payload;

      return {
        isFetching: false,
        data: {
          ...state.data,
          statuses: [...state.data.statuses, data],
        },
      };
    }
    case EDIT_LIBRARY_RATE_STATUS: {
      if (!state.data) {
        return state;
      }

      const { data, rateStatusId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          statuses: state.data.statuses.map(rateStatus => {
            if (rateStatus._id === rateStatusId) {
              return data;
            }
            return rateStatus;
          }),
        },
      };
    }
    case CLONE_LIBRARY_RATE_STATUS: {
      if (!state.data) {
        return state;
      }

      const { data } = action.payload;

      return {
        data: {
          ...state.data,
          statuses: [...state.data.statuses, data],
        },
      };
    }
    case ADD_LIBRARY_RATE_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      const { attachment } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          attachments: [...state.data.attachments, attachment],
        },
      };
    }
    case DELETE_LIBRARY_RATE_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      const { attachmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          attachments: state.data.attachments
            .filter(attachment => attachment._id !== attachmentId),
        },
      };
    }
    case EDIT_LIBRARY_RATE_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      const { attachmentId, data } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          attachments: state.data.attachments.map(attachment => {
            if (attachment._id === attachmentId) {
              return {
                ...attachment,
                ...data,
              };
            }
            return attachment;
          }),
        },
      };
    }
    case ADD_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.data) {
        return state;
      }

      const {
        rateStatusId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          statuses: state.data.statuses.map(rateStatus => {
            if (rateStatus._id === rateStatusId) {
              return {
                ...rateStatus,
                customFields: [...rateStatus.customFields, data],
              };
            }
            return rateStatus;
          }),
        },
      };
    }
    case EDIT_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.data) {
        return state;
      }

      const {
        rateStatusId,
        customFieldRecordId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          statuses: state.data.statuses.map(rateStatus => {
            if (rateStatus._id === rateStatusId) {
              return {
                ...rateStatus,
                customFields: rateStatus
                  .customFields
                  .map(customField => {
                    if (customField._id === customFieldRecordId) {
                      return data;
                    }
                    return customField;
                  }),
              };
            }
            return rateStatus;
          }),
        },
      };
    }
    case DELETE_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.data) {
        return state;
      }

      const {
        rateStatusId,
        customFieldRecordId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          statuses: state.data.statuses.map(rateStatus => {
            if (rateStatus._id === rateStatusId) {
              return {
                ...rateStatus,
                customFields: rateStatus
                  .customFields
                  .filter(customField => customField._id !== customFieldRecordId),
              };
            }
            return rateStatus;
          }),
        },
      };
    }
    default: {
      return state;
    }
  }
};
