import {
  SYNC_SUPPORTING_DOCUMENTS,
  SYNC_SUPPORTING_DOCUMENTS_ERROR,
  SYNC_SUPPORTING_DOCUMENTS_SUCCESS,
} from '../actions/syncActions';

export default (state = {}, action) => {
  switch (action.type) {
    case SYNC_SUPPORTING_DOCUMENTS: {
      const { subTypeOfInsuranceId, filingTypeId } = action.payload;
      return {
        ...state,
        [`${subTypeOfInsuranceId}__${filingTypeId}`]: {
          syncing: true,
          error: '',
        },
      };
    }
    case SYNC_SUPPORTING_DOCUMENTS_SUCCESS: {
      const { subTypeOfInsuranceId, filingTypeId } = action.payload;
      return {
        ...state,
        [`${subTypeOfInsuranceId}__${filingTypeId}`]: {
          syncing: false,
          error: '',
        },
      };
    }
    case SYNC_SUPPORTING_DOCUMENTS_ERROR: {
      const { error, params } = action.payload;
      const { subTypeOfInsuranceId, filingTypeId } = params;
      return {
        ...state,
        [`${subTypeOfInsuranceId}__${filingTypeId}`]: {
          syncing: false,
          error,
        },
      };
    }
    default:
      return state;
  }
};
