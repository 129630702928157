import {
  GET_MOZART_PROJECTS,
  GET_MOZART_PROJECTS_SUCCESS,
  GET_MOZART_PROJECTS_ERROR,
  GET_MOZART_FORMS_PROJECT,
  GET_MOZART_FORMS_PROJECT_SUCCESS,
  GET_MOZART_FORMS_PROJECT_ERROR,
  INVALIDATE_MOZART_FORMS,
} from '../actions/mozartActions';

const initialState = {
  mozartProjects: {
    isLoading: false,
    data: null,
    error: '',
  },
  projectDataMap: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MOZART_PROJECTS: {
      return {
        ...state,
        mozartProjects: {
          isLoading: true,
          data: null,
          error: '',
        },
      };
    }
    case GET_MOZART_PROJECTS_SUCCESS: {
      return {
        ...state,
        mozartProjects: {
          isLoading: false,
          data: action.payload,
          error: '',
        },
      };
    }
    case GET_MOZART_PROJECTS_ERROR: {
      return {
        ...state,
        mozartProjects: {
          isLoading: false,
          data: null,
          error: action.payload,
        },
      };
    }
    case GET_MOZART_FORMS_PROJECT: {
      return {
        ...state,
        projectDataMap: {
          ...state.projectDataMap,
          [action.payload]: {
            data: {},
            projectLoading: true,
            projectError: '',
          },
        },
      };
    }
    case GET_MOZART_FORMS_PROJECT_SUCCESS: {
      return {
        ...state,
        projectDataMap: {
          ...state.projectDataMap,
          [action.payload.id]: {
            data: action.payload.data,
            projectLoading: false,
            projectError: '',
          },
        },
      };
    }
    case GET_MOZART_FORMS_PROJECT_ERROR: {
      return {
        ...state,
        projectDataMap: {
          ...state.projectDataMap,
          [action.payload.id]: {
            data: {},
            projectLoading: false,
            projectError: action.payload,
          },
        },
      };
    }
    case INVALIDATE_MOZART_FORMS: {
      return initialState;
    }
    default:
      return state;
  }
}
