import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/serff-spi/note-drafts`;

export const GET_NOTE_DRAFTS = 'GET_NOTE_DRAFTS';
export const GET_NOTE_DRAFTS_SUCCESS = 'GET_NOTE_DRAFTS_SUCCESS';
export const GET_NOTE_DRAFTS_ERROR = 'GET_NOTE_DRAFTS_ERROR';
export const INVALIDATE_NOTE_DRAFTS = 'INVALIDATE_NOTE_DRAFTS';

export const ADD_NOTE_DRAFT = 'ADD_NOTE_DRAFT';
export const EDIT_NOTE_DRAFT = 'EDIT_NOTE_DRAFT';
export const DELETE_NOTE_DRAFT = 'DELETE_NOTE_DRAFT';

export const ADD_NOTE_DRAFT_ATTACHMENT = 'ADD_NOTE_DRAFT_ATTACHMENT';
export const DELETE_NOTE_DRAFT_ATTACHMENT = 'DELETE_NOTE_DRAFT_ATTACHMENT';

export const invalidateNoteDrafts = () => (dispatch) => {
  dispatch({ type: INVALIDATE_NOTE_DRAFTS });
};

export const getNoteDrafts = ({
  filingId,
  limit,
  offset,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const params = {
    filing: filingId,
    limit,
    offset,
  };

  return async (dispatch) => {
    try {
      dispatch({ type: GET_NOTE_DRAFTS });
      const res = await axios.get(`${URL}`, { params });
      dispatch({
        type: GET_NOTE_DRAFTS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_NOTE_DRAFTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const addNoteDraft = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}`, data);
      dispatch({
        type: ADD_NOTE_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editNoteDraft = (noteDraftId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/${noteDraftId}`, data);
      dispatch({
        type: EDIT_NOTE_DRAFT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteNoteDraft = (id) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${id}`);
      dispatch({
        type: DELETE_NOTE_DRAFT,
        payload: id,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const submitNoteDraft = (id) => async () => {
  try {
    const res = await axios.post(`${URL}/${id}/submission`);
    return res.data;
  } catch ({ response }) {
    throw response && response.data;
  }
};

export const addNoteDraftAttachment = ({ noteDraftId, attachment }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/${noteDraftId}/attachments`, attachment);
      dispatch({
        type: ADD_NOTE_DRAFT_ATTACHMENT,
        payload: { noteDraftId, attachment: res.data },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const deleteNoteDraftAttachment = ({ noteDraftId, attachmentId }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.delete(`${URL}/${noteDraftId}/attachments/${attachmentId}`);
      dispatch({
        type: DELETE_NOTE_DRAFT_ATTACHMENT,
        payload: { noteDraftId, attachmentId },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
