import {
  ADD_ATTACHMENT_COMPARISON_QUEUE,
  REMOVE_ATTACHMENT_COMPARISON_QUEUE,
  TOGGLE_COMPARISON_MANAGER,
  RESET_ATTACHMENT_COMPARISON_QUEUE,
} from '../actions/attachmentComparisonActions';

const initialState = {
  open: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ATTACHMENT_COMPARISON_QUEUE: {
      const attachment = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [attachment.key]: attachment,
        },
      };
    }
    case REMOVE_ATTACHMENT_COMPARISON_QUEUE: {
      const key = action.payload;

      return {
        ...state,
        data: Object.keys(state.data).reduce((acc, num) => {
          if (num !== key) {
            acc[num] = state.data[num];
          }
          return acc;
        }, {}),
      };
    }
    case TOGGLE_COMPARISON_MANAGER: {
      return {
        ...state,
        open: action.payload,
      };
    }
    case RESET_ATTACHMENT_COMPARISON_QUEUE: {
      return {
        ...state,
        open: false,
        data: {},
      };
    }
    default:
      return { ...state };
  }
};
