import axios from 'axios';
import { setAuthToken } from './index';

const URL = `${process.env.REACT_APP_API_ENDPOINT}/ai`;

export const GET_CHECKLIST_ITEMS = 'GET_CHECKLIST_ITEMS';
export const GET_CHECKLIST_ITEMS_SUCCESS = 'GET_CHECKLIST_ITEMS_SUCCESS';
export const GET_CHECKLIST_ITEMS_ERROR = 'GET_CHECKLIST_ITEMS_ERROR';

export const CREATE_CHECKLIST_ITEM = 'CREATE_CHECKLIST_ITEM';
export const EDIT_CHECKLIST_ITEM = 'EDIT_CHECKLIST_ITEM';

export const getChecklistItems = (filters) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CHECKLIST_ITEMS,
      });
      const res = await axios.get(`${URL}/checklist-items`, {
        params: filters,
      });
      dispatch({
        type: GET_CHECKLIST_ITEMS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_CHECKLIST_ITEMS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const createChecklistItem = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/checklist-items`, data);
      dispatch({
        type: CREATE_CHECKLIST_ITEM,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const editChecklistItem = (id, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/checklist-items/${id}`, data);
      dispatch({
        type: EDIT_CHECKLIST_ITEM,
        payload: {
          id,
          data: res.data,
        },
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
