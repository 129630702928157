import {
  GET_POST_SUBMISSION_DRAFTS,
  GET_POST_SUBMISSION_DRAFTS_SUCCESS,
  GET_POST_SUBMISSION_DRAFTS_ERROR,
  DELETE_POST_SUBMISSION_DRAFT,
  ADD_POST_SUBMISSION_DRAFT,
  EDIT_POST_SUBMISSION_DRAFT,
  INVALIDATE_POST_SUBMISSION_DRAFTS,
  EDIT_POST_SUBMISSION_DRAFT_GENERAL_INFORMATION,
  EDIT_POST_SUBMISSION_DRAFT_RATE_DATA,
  EDIT_POST_SUBMISSION_DRAFT_STATE_SPECIFICS,
} from '../actions/postSubmissionDraftsActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  data: [],
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_SUBMISSION_DRAFTS: {
      return {
        isFetched: false,
        isFetching: true,
        data: [],
        error: null,
      };
    }
    case GET_POST_SUBMISSION_DRAFTS_SUCCESS: {
      return {
        isFetched: true,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_POST_SUBMISSION_DRAFTS_ERROR: {
      return {
        isFetched: true,
        isFetching: false,
        data: [],
        error: action.payload,
      };
    }
    case DELETE_POST_SUBMISSION_DRAFT: {
      return {
        isFetched: true,
        isFetching: false,
        data: state.data
          .filter(postSubmissionDraft => postSubmissionDraft._id !== action.payload),
        error: null,
      };
    }
    case ADD_POST_SUBMISSION_DRAFT: {
      return {
        isFetched: true,
        isFetching: false,
        data: [...state.data, action.payload],
        error: null,
      };
    }
    case EDIT_POST_SUBMISSION_DRAFT: {
      const postSubmissionDraft = action.payload;
      return {
        isFetched: true,
        isFetching: false,
        data: state.data.map(postSubmissionUpdate => {
          if (postSubmissionUpdate._id === postSubmissionDraft._id) {
            return postSubmissionDraft;
          }
          return postSubmissionUpdate;
        }),
        error: null,
      };
    }
    case INVALIDATE_POST_SUBMISSION_DRAFTS: {
      return {
        isFetched: false,
        isFetching: false,
        data: [],
        error: null,
      };
    }
    case EDIT_POST_SUBMISSION_DRAFT_GENERAL_INFORMATION: {
      const { id, data } = action.payload;
      return {
        isFetched: true,
        isFetching: true,
        data: state.data.map(postSubmissionDraft => {
          if (postSubmissionDraft._id === id) {
            return {
              ...postSubmissionDraft,
              generalInformation: data,
            };
          }
          return postSubmissionDraft;
        }),
        error: null,
      };
    }
    case EDIT_POST_SUBMISSION_DRAFT_RATE_DATA: {
      const { id, data } = action.payload;
      return {
        isFetched: true,
        isFetching: true,
        data: state.data.map(postSubmissionDraft => {
          if (postSubmissionDraft._id === id) {
            return {
              ...postSubmissionDraft,
              rateData: data,
            };
          }
          return postSubmissionDraft;
        }),
        error: null,
      };
    }
    case EDIT_POST_SUBMISSION_DRAFT_STATE_SPECIFICS: {
      const { id, data } = action.payload;
      return {
        isFetched: true,
        isFetching: true,
        data: state.data.map(postSubmissionDraft => {
          if (postSubmissionDraft._id === id) {
            return {
              ...postSubmissionDraft,
              stateSpecifics: data,
            };
          }
          return postSubmissionDraft;
        }),
        error: null,
      };
    }
    default:
      return { ...state };
  }
};
