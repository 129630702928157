import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SOURCE } from '../enums';
import Loader from '../general/Loader';
import { getFiling } from '../actions/filingActions';
import { getResponseDrafts, upsertResponseDraft } from '../actions/responseDraftsActions';
import { getProducts } from '../actions/productActions';
import { getProjects } from '../actions/projectsActions';
import { currentAccount, currentUser } from '../selectors/selectors';
import { getFilingContactAndAuthors, isAuthorOrContact } from '../services/serffService';

export default function (Component) {
  const ObjectionLetterMiddleware = ({
    user,
    users,
    params,
    filing,
    responseDrafts,
    fetchFiling,
    fetchResponseDrafts,
    fetchProjects,
    fetchProducts,
    createResponseDraft,
  }) => {
    const { filingId, objectionId } = params;
    const [objectionLetter, setObjectionLetter] = useState(null);
    const [responseDraft, setResponseDraft] = useState(null);

    const {
      isFetching: filingIsFetching,
      data: filingData,
    } = filing;

    const {
      authors,
      contact,
    } = getFilingContactAndAuthors(filingData, users);

    const isAuthor = filingData
    && filingData.source === SOURCE.RAMP
      ? true
      : isAuthorOrContact(authors, contact, user);

    const {
      isFetching: responseDraftsIsFetching,
      isFetched: responseDraftsIsFetched,
      data: responseDraftsData,
    } = responseDrafts;

    useEffect(() => {
      fetchProjects();
      fetchProducts();
    }, [])

    useEffect(() => {
      fetchFiling(filingId);
    }, [filingId]);

    useEffect(() => {
      if (!filingData) {
        return;
      }

      setObjectionLetter(filingData.objections
        .find(objection => objection._id === objectionId));
    }, [filingData]);

    useEffect(() => {
      if (objectionLetter) {
        fetchResponseDrafts({
          filingId,
          objectionResourceId: objectionLetter.resourceId,
        });
      }
    }, [filingId, objectionLetter]);

    useEffect(() => {
      const draft = responseDraftsData
        .find(r => r.objectionResourceId === objectionLetter?.resourceId && r.filing === filingId);

      if (draft) {
        setResponseDraft(draft);
      }
    }, [
      filingId,
      objectionLetter,
      responseDraftsData,
    ]);

    useEffect(() => {
      if (
        responseDraftsIsFetched
        && !responseDraft
        && !objectionLetter.hasResponse
      ) {
        createResponseDraft({
          filing: filingId,
          objectionResourceId: objectionLetter.resourceId,
        });
      }
    }, [
      responseDraftsIsFetched,
      responseDraft,
      objectionLetter,
      filingId,
    ]);

    if (filingIsFetching || responseDraftsIsFetching) {
      return (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </div>
      )
    } else if (!!(
      filing.data
      && objectionLetter
    )) {
      return (
        <Component
          responseDraft={responseDraft}
          isAuthor={isAuthor}
          objectionLetter={objectionLetter}
          filing={filing.data}
        />
      );
    }

    return null;
  };

  const mapStateToProps = (state) => ({
    user: currentUser(state),
    users: state.users,
    account: currentAccount(state),
    filing: state.filing,
    responseDrafts: state.responseDrafts,
  });

  return connect(mapStateToProps, {
    fetchProducts: getProducts,
    fetchProjects: getProjects,
    fetchFiling: getFiling,
    fetchResponseDrafts: getResponseDrafts,
    createResponseDraft: upsertResponseDraft,
  })(ObjectionLetterMiddleware);
}
