import {
  GET_REMINDERS,
  GET_REMINDERS_SUCCESS,
  GET_REMINDERS_ERROR,
  ADD_REMINDER,
  EDIT_REMINDER,
  DELETE_REMINDER,
} from '../actions/remindersActions';

const initialState = {
  isFetching: false,
  error: null,
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REMINDERS: {
      return {
        isFetching: true,
        error: null,
        data: [],
      };
    }
    case GET_REMINDERS_ERROR: {
      return {
        isFetching: false,
        error: action.payload,
        data: [],
      };
    }
    case GET_REMINDERS_SUCCESS: {
      return {
        isFetching: false,
        error: null,
        data: action.payload,
      };
    }
    case ADD_REMINDER: {
      return {
        isFetching: false,
        error: null,
        data: [...state.data, action.payload],
      };
    }
    case EDIT_REMINDER: {
      const { id, data } = action.payload;
      return {
        isFetching: false,
        error: null,
        data: state.data.map(reminder => {
          if (reminder._id === id) {
            return data;
          }
          return reminder;
        }),
      };
    }
    case DELETE_REMINDER: {
      return {
        isFetching: false,
        error: null,
        data: state.data
          .filter(reminder => reminder._id !== action.payload),
      };
    }
    default:
      return state;
  }
};
