import { notification } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import MentionAudio from '../../assets/mention.mp3';
import './MentionNotification.scss';

const MentionNotification = ({
  mention,
  message,
  onClick,
  key,
}) => {
  const audio = new Audio(MentionAudio);
  audio.play();
  return notification.open({
    className: 'mention-notification',
    message: (
      <>
        <span className="mentioned-user">{ mention }</span>
        {' '}
        mentioned you
      </>
    ),
    description: (
      <div className="mention-notification-content">
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    ),
    icon: <MessageOutlined />,
    duration: 7,
    key,
    onClick: () => {
      onClick();
      notification.close(key);
    },
  });
};

export default MentionNotification;
