import axios from 'axios';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const EDIT_CURRENT_USER_PREFERENCES = 'EDIT_CURRENT_USER_PREFERENCES';

export const URL = process.env.REACT_APP_API_ENDPOINT;

export const setAuthToken = (token) => {
  axios.defaults.headers.common.Authorization = '';
  delete axios.defaults.headers.common.Authorization;

  if (token) {
    localStorage.setItem('token', token);
    axios.defaults.headers.common.Authorization = token;
  }
};

export function syncFilings(data) {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.post(`${URL}/sync/filings`, data);
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export function syncAccount() {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.post(`${URL}/sync/accounts`);
      document.location.reload();
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export function syncProject(projectId) {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      await axios.post(`${URL}/sync/projects/${projectId}`, {
        projectId,
      });
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export function sendForgotPassword(username) {
  return async () => axios.post(`${URL}/users/forgot-password`, {
    username,
  });
}

export const resetPassword = ({ password, key }) => axios.post(`${URL}/users/reset-password`, {
  password,
  key,
});

export function getUserScope() {
  return localStorage.getItem('scope');
}

export function editCurrentUser(data) {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/users/current/`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const editUserPreferences = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/users/current/preferences/ui`, data);
      dispatch({
        type: EDIT_CURRENT_USER_PREFERENCES,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export function changeCurrentUserPassword({ currentPassword, newPassword }) {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.put(`${URL}/users/current/password`, { currentPassword, newPassword });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export function cloneState({ projectId, stateId, states }) {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = axios.post(`${URL}/clone/projects/${projectId}/states/${stateId}`, { states });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
}

export const scrapeFilings = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/serff-filings/scrape`, data);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};
