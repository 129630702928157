import {
  GET_WORKSPACES,
  GET_WORKSPACES_SUCCESS,
  GET_WORKSPACES_ERROR,
  CREATE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_SUCCESS,
  EDIT_WORKSPACE_SUCCESS,
  REORDER_WORKSPACE_SUCCESS,
  MOVE_PRODUCTS_SUCCESS, REORDER_PRODUCTS_SUCCESS,
  DELETE_PRODUCT_FROM_WORKSPACE,
} from '../actions/workspacesActions';

const initialState = {
  isFetching: false,
  isFetched: false,
  error: '',
  data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WORKSPACES: {
      return {
        isFetching: true,
        isFetched: false,
        data: [],
        error: '',
      };
    }
    case GET_WORKSPACES_SUCCESS: {
      return {
        isFetching: false,
        isFetched: true,
        data: action.payload,
        error: '',
      };
    }
    case GET_WORKSPACES_ERROR: {
      return {
        isFetching: false,
        isFetched: true,
        data: [],
        error: action.payload,
      };
    }
    case CREATE_WORKSPACE_SUCCESS: {
      return {
        isFetching: false,
        isFetched: true,
        data: [...state.data, action.payload],
        error: '',
      };
    }
    case DELETE_WORKSPACE_SUCCESS: {
      return {
        isFetching: false,
        isFetched: true,
        data: state.data
          .filter(workspace => workspace._id !== action.payload),
        error: '',
      };
    }
    case EDIT_WORKSPACE_SUCCESS: {
      const editedWorkspace = action.payload;
      return {
        isFetching: false,
        isFetched: true,
        data: state.data.map(workspace => {
          if (editedWorkspace._id === workspace._id) {
            return editedWorkspace;
          }
          return workspace;
        }),
        error: '',
      };
    }
    case REORDER_WORKSPACE_SUCCESS: {
      return {
        isFetching: false,
        isFetched: true,
        data: action.payload,
        error: '',
      };
    }
    case REORDER_PRODUCTS_SUCCESS: {
      const {
        workspaceId,
        productId,
        order,
      } = action.payload;

      return {
        isFetching: false,
        isFetched: true,
        data: state.data.map((workspace) => {
          if (workspace._id === workspaceId) {
            return {
              ...workspace,
              products: workspace.products.map(product => {
                if (product.productId === productId) {
                  return { productId, order };
                }
                return product;
              }),
            };
          }
          return workspace;
        }),
        error: '',
      };
    }
    case MOVE_PRODUCTS_SUCCESS: {
      const {
        oldWorkspaceId,
        newWorkspaceId,
        productId,
        order,
      } = action.payload;

      return {
        isFetching: false,
        isFetched: true,
        data: state.data.map((workspace) => {
          if (workspace._id === oldWorkspaceId) {
            return {
              ...workspace,
              products: workspace.products
                .filter(product => product.productId !== productId),
            };
          }
          if (workspace._id === newWorkspaceId) {
            return {
              ...workspace,
              products: [...workspace.products, { productId, order }],
            };
          }
          return workspace;
        }),
        error: '',
      };
    }
    case DELETE_PRODUCT_FROM_WORKSPACE: {
      const { productId, workspaceId } = action.payload;
      return {
        ...state,
        data: state.data.map(workspace => {
          if (workspace._id === workspaceId) {
            return {
              ...workspace,
              products: workspace.products.filter(product => product.productId !== productId),
            };
          }
          return workspace;
        }),
      };
    }
    default:
      return { ...state };
  }
}
