import axios from 'axios';
import { setAuthToken, URL } from './index';
// import { DELETE_PRODUCT_FROM_WORKSPACE } from './workspacesActions';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';

export const REORDER_PRODUCTS = 'REORDER_PRODUCTS';

export const getProducts = () => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCTS });
      const res = await axios.get(`${URL}/products`, {
        params: {
          limit: 25000,
          archived: false,
        },
      });
      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_PRODUCTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};

export const getProduct = (productId) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.get(`${URL}/products/${productId}`);
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addProduct = ({ name, color, initials, order }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/products`, {
        name,
        color,
        initials,
        order,
      });
      dispatch({
        type: CREATE_PRODUCT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const editProduct = ({
  productId,
  name,
  color,
  initials,
  order,
  archived,
}) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/products/${productId}`, {
        name,
        color,
        initials,
        order,
        archived,
      });
      dispatch({
        type: EDIT_PRODUCT_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};

export const archiveProduct = ({ productId, archived }) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.patch(`${URL}/products/${productId}`, {
        archived,
      });
      dispatch({
        type: DELETE_PRODUCT_SUCCESS,
        payload: { productId },
      });
    } catch ({ response }) {
      throw response.data;
    }
  };
};
