import axios from 'axios';
import { setAuthToken, URL } from './index';

export const GET_SERFF_CONTACTS = 'GET_SERFF_CONTACTS';
export const GET_SERFF_CONTACTS_SUCCESS = 'GET_SERFF_CONTACTS_SUCCESS';
export const GET_SERFF_CONTACTS_ERROR = 'GET_SERFF_CONTACTS_ERROR';

export const SYNC_SERFF_CONTACTS = 'SYNC_SERFF_CONTACTS';

export const syncSerffContacts = (serffInstanceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/serff-contacts/sync`, { serffInstanceId });
      return res.data;
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const getSerffInstanceContacts = async (serffInstanceId) => {
  try {
    const res = await axios.get(`${URL}/serff-contacts`, {
      params: {
        limit: 1000,
        serffInstanceId,
      },
    });
    return res.data;
  } catch ({ response }) {
    throw response && response.data;
  }
};

export const getSerffInstanceAuthors = async (serffInstanceId) => {
  try {
    const res = await axios.get(`${URL}/serff-authors`, {
      params: {
        limit: 1000,
        serffInstanceId,
      },
    });
    return res.data;
  } catch ({ response }) {
    throw response && response.data;
  }
};

export const getSerffContacts = (serffInstanceId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      dispatch({ type: GET_SERFF_CONTACTS });
      const res = await axios.get(`${URL}/serff-contacts`, {
        params: {
          limit: 1000,
          serffInstanceId,
        },
      });
      dispatch({
        type: GET_SERFF_CONTACTS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: GET_SERFF_CONTACTS_ERROR,
        payload: response && response.data,
      });
      throw response && response.data;
    }
  };
};
