export const getFilingContactAndAuthors = (filing, users = []) => {
  if (!filing) {
    return {
      authors: [],
      contact: null,
    };
  }

  const activeUsers = users.filter(user => !user.archived);

  const authors = filing.authors
    ? filing.authors.reduce((acc, num) => {
      const user = activeUsers.find(u => num.name === `${u.firstName} ${u.lastName}` && u.serffAuthors?.length);
      if (user) {
        acc.push(user);
      }
      return acc;
    }, [])
    : [];

  const contact = filing.contact
    ? activeUsers.find(u => u.firstName === filing.contact.firstName
      && u.lastName === filing.contact.lastName && u.serffContacts?.length)
    : null;

  return {
    authors,
    contact,
  };
};

export const isAuthorOrContact = (authors = [], contact, currentUser) => {
  if (!currentUser) {
    return false;
  }

  const { _id } = currentUser;
  const isAuthor = !!authors.find(author => author._id === _id);
  const isContact = contact?._id === _id;
  return isAuthor || isContact;
};
